import { IAnalyticsService } from '../types/IAnalyticsService'

export class GoogleAnalyticsService implements IAnalyticsService {
  _gtag = (window as any).gtag
  trackEvent(eventName: string, params: any): void {
    if (this._gtag) {
      const dictionary = this.convertObjectToDictionary(params)
      if (dictionary) {
        this._gtag('event', eventName, dictionary)
      }
    }
  }

  private convertObjectToDictionary(obj: any): { [key: string]: string } {
    let dictionary: { [key: string]: string } = {}
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        dictionary[key] = String(obj[key])
      }
    }
    return dictionary
  }
}
