import { atom } from 'recoil'

export const webSocketConnectionIdState = atom<string | null>({
  key: 'webSocketConnectionIdState',
  default: null,
})

export const webSocketLastMessageState = atom<any | null>({
  key: 'webSocketLastMessageState',
  default: null,
})
