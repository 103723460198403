import { FC, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { DefaultNavigationPath } from '../constants'
import { ServiceContext } from '../providers/ServicesProvider'
import { AuthenticationResult } from '../types/authentication/AuthenticationResult'
import { authenticatedUserState } from '../state/AuthenticationState'
import { useGlobalIsLoading } from '../hooks/useIsLoading'

const LoggedOutRoute: FC = () => {
  const { authenticationService, navigationService } =
    useContext(ServiceContext)
  const { setIsLoading } = useGlobalIsLoading()
  const authenticatedUser = useRecoilValue(authenticatedUserState)

  useEffect(() => {
    const getCurrentUser = async () => {
      return await authenticationService.getCurrentUserAsync()
    }
    setIsLoading(true)
    getCurrentUser().then((authenticationResult: AuthenticationResult) => {
      setIsLoading(false)
      // Navigate to the default if the user has hit the login page, while already being logged in
      if (authenticationResult.user) {
        navigationService.navigate(DefaultNavigationPath)
      }
    })
  }, [authenticationService, navigationService, setIsLoading])

  return <>{!authenticatedUser && <Outlet />}</>
}

export default LoggedOutRoute
