import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { locale } from '../../locales'
import { ServiceContext } from '../../providers/ServicesProvider'

import theme from '../../styles/theme'
import { ProductItemWithAdjustmentAndInvoiceDetails } from '../../types/entities/Credit'
import {
  addOdsCodeColumn,
  getDateOnlyIsoString,
  getDateOnlyShortMonthString,
  isoDateToEpoch,
  packSizeConverter,
  getLocaleDateMedWithWeekDay,
} from '../../utils/Helpers'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { ClientSelection } from '../../types/entities/ClientPermission'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import ModalContainer from '../../components/Interactions/ModalContainer'
import { CreditForActionForm } from './components/CreditForActionForm'
import NotesIcon from '@mui/icons-material/Notes'
import { DatePresetsLocalStorageKeys, RecoilPageIds } from '../../constants'

import DateRangePickerPopover from '../../components/Forms/DateRangePickers'
import MultiSelection from '../../components/Forms/MultiSelection'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import Button from '@mui/material/Button/Button'
import ConfirmDialog from '../../components/Interactions/ConfirmDialog'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import {
  PageAction,
  PageReducer,
  PageState,
  createRecoilPageState,
  usePageState,
} from '../PageState'
import SearchBox from '../../components/Forms/SearchBox'
import { textFiltering } from '../../utils/FilteringUtls'
import { sortArrayByPropertyKey } from '../../utils/SortingUtils'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'

const translation = locale.translation.GoodsInPage.TabCreditsForAction

const creditStateFilterSelectionTypes = {
  actioned: 'ACTIONED',
  received: 'RECEIVED',
  unactioned: 'UNACTIONED',
  rejected: 'REJECTED',
}

// Page-specific action types
type SetSelectedCreditStatesAction = {
  type: 'setSelectedCreditStates'
  payload: string[]
}

type CreditsPageAction =
  | PageAction<ProductItemWithAdjustmentAndInvoiceDetails>
  | SetSelectedCreditStatesAction

// Page-specific state
interface CreditsPageState
  extends PageState<ProductItemWithAdjustmentAndInvoiceDetails> {
  selectedCreditStates: string[]
}

// Page specific reducer
class CreditsPageReducer extends PageReducer<ProductItemWithAdjustmentAndInvoiceDetails> {
  reducer(
    state: CreditsPageState,
    action: CreditsPageAction
  ): CreditsPageState {
    state = {
      ...(super.reducer(
        state,
        action as PageAction<ProductItemWithAdjustmentAndInvoiceDetails>
      ) as CreditsPageState),
    }
    switch (action.type) {
      case 'setSelectedCreditStates':
        return { ...state, selectedCreditStates: action.payload }
    }

    return state
  }
}

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState } =
  createRecoilPageState<ProductItemWithAdjustmentAndInvoiceDetails>(
    RecoilPageIds.TabCreditsForAction
  )
const pageReducer = new CreditsPageReducer()

const filterAndSortCredits = (
  items: ProductItemWithAdjustmentAndInvoiceDetails[],
  filterState?: unknown
) => {
  const s = filterState as CreditsPageState
  items = items.filter(
    (i) =>
      textFiltering(s!.searchText, i.invoiceNumber ?? '') ||
      textFiltering(s!.searchText, i.productName ?? '')
  )

  const returnActioned = s.selectedCreditStates.includes(
    creditStateFilterSelectionTypes.actioned
  )
  const returnReceived = s.selectedCreditStates.includes(
    creditStateFilterSelectionTypes.received
  )
  const returnRejected = s.selectedCreditStates.includes(
    creditStateFilterSelectionTypes.rejected
  )
  const returnUnactioned = s.selectedCreditStates.includes(
    creditStateFilterSelectionTypes.unactioned
  )

  items = items.filter((item) => {
    if (
      returnUnactioned &&
      !item.actioned &&
      !item.received &&
      !item.rejected
    ) {
      return true
    }

    if (returnActioned && item.actioned && !item.received && !item.rejected) {
      return true
    }

    if (returnReceived && item.received) {
      return true
    }

    if (returnRejected && item.rejected) {
      return true
    }

    return false
  })

  if (s.sorting) {
    sortArrayByPropertyKey(
      items,
      {
        sortingType: s.sorting.sortingType,
        sortingPropertyKey: s.sorting.dataKey,
      },
      {
        invoiceDate: (propertyValue, rowValue) =>
          isoDateToEpoch(propertyValue as string | undefined),
      }
    )
  }
  return items
}

const getColumnDefinitions = (
  selectedClient: ClientSelection | null,
  odsToPharmacyMapping: { [key: string]: string } | null
) => {
  const result: VirtuosoColumn<ProductItemWithAdjustmentAndInvoiceDetails>[] = [
    {
      dataKey: 'invoiceDate',
      label: translation.TableColumnTitles[1],
      sortable: true,
      valueTransformer: (rowData) =>
        getLocaleDateMedWithWeekDay(rowData.invoiceDate),
    },
    {
      dataKey: 'invoiceNumber',
      label: translation.TableColumnTitles[2],
      sortable: true,
    },
    {
      dataKey: 'supplierDisplayName',
      label: translation.TableColumnTitles[3],
      sortable: true,
    },
    {
      dataKey: 'adjustmentReasonCode',
      label: translation.TableColumnTitles[4],
      valueTransformer: (rowData) =>
        rowData.adjustmentReasonCode
          ? locale.translation.CreditReasons[rowData.adjustmentReasonCode]
          : null,
      sortable: true,
    },
    {
      dataKey: 'productName',
      label: translation.TableColumnTitles[6],
      sortable: true,
      widthCss: '30%',
    },
    {
      dataKey: 'productPackSize',
      label: translation.TableColumnTitles[7],
      valueTransformer: (rowData) =>
        packSizeConverter(rowData.productPackSize ?? null),
    },
    {
      dataKey: 'totalCreditValue',
      label: translation.TableColumnTitles[10],
      currency: true,
      sortable: true,
    },
    {
      dataKey: 'adjustmentNumberOfPacks',
      label: translation.TableColumnTitles[9],
    },
    {
      dataKey: 'actioned',
      label: translation.TableColumnTitles[11],
      widthCss: '80px',
      customCellNode: (v, rowData) => (
        <ActionedCheckbox isChecked={rowData.actioned} />
      ),
    },
    {
      dataKey: 'received',
      label: translation.TableColumnTitles[13],
      widthCss: '80px',
      customCellNode: (v, rowData) => (
        <ActionedCheckbox isChecked={rowData.received} />
      ),
    },
    {
      dataKey: 'rejected',
      label: translation.TableColumnTitles[14],
      widthCss: '80px',
      customCellNode: (v, rowData) => (
        <ActionedCheckbox isChecked={rowData.rejected} />
      ),
    },
    {
      dataKey: 'hasNotes',
      label: translation.TableColumnTitles[15],
      widthCss: '80px',
      customCellNode: (v, rowData) => (
        <NotesIconComponent hasNotes={rowData.hasNotes} />
      ),
    },
  ]

  return addOdsCodeColumn<ProductItemWithAdjustmentAndInvoiceDetails>(
    selectedClient?.clientType ?? null,
    odsToPharmacyMapping,
    result,
    0,
    true
  )
}

const ActionedCheckbox: FC<{
  isChecked?: boolean
}> = ({ isChecked }) => {
  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Checkbox sx={{ padding: 0 }} checked={isChecked} disabled={true} />
      </Box>
    </>
  )
}

const NotesIconComponent: FC<{
  hasNotes: boolean
}> = ({ hasNotes }) => {
  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        {hasNotes === false ? '' : <NotesIcon color="disabled" />}
      </Box>
    </>
  )
}

const TabCreditsForAction: FC = () => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const [selectedCredit, setSelectedCredit] =
    useState<ProductItemWithAdjustmentAndInvoiceDetails | null>(null)

  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false)
  const { setIsLoading } = useGlobalIsLoading()
  const [exportErrors, setExporErrors] = useState<string | null>(null)

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    odsToPharmacyNameMappings,
    virtuosoTableHelpers,
  } = usePageState<ProductItemWithAdjustmentAndInvoiceDetails>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    (item) => item.adjustmentId,
    {
      dataKey: 'invoiceDate',
      sortingType: 'DESC',
    },
    DatePresetsLocalStorageKeys.CREDITS_DATE_RANGE_PRESET_KEY,
    {
      selectedCreditStates: Object.values(creditStateFilterSelectionTypes),
    },
    filterAndSortCredits
  )

  // Type conversion to page specific state
  const state = genericPageState as CreditsPageState
  const dispatch = genericDispatch as React.Dispatch<CreditsPageAction>

  const columnDefinitions = useMemo(() => {
    return getColumnDefinitions(state.selectedClient, odsToPharmacyNameMappings)
  }, [odsToPharmacyNameMappings, state.selectedClient])

  useEffect(() => {
    const getCredits = async () => {
      return await httpService.postAsync<
        ProductItemWithAdjustmentAndInvoiceDetails[]
      >(
        PlatformApiPaths.GetCredits(state.selectedClient!),
        {
          invoiceDateFrom: getDateOnlyIsoString(state.dates[0]),
          invoiceDateTo: getDateOnlyIsoString(state.dates[1]),
          supplierIds: state.selectedSupplierIds,
        },
        'StockBaseUrl'
      )
    }
    if (shouldRunLoadingEffect) {
      setIsLoading(true)
      dispatch({ type: 'setError', payload: null })
      getCredits().then((response) => {
        setIsLoading(false)
        if (response?.data && !response.hasErrors) {
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages: false },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
    }
  }, [
    dispatch,
    httpService,
    setIsLoading,
    shouldRunLoadingEffect,
    state.dates,
    state.selectedClient,
    state.selectedSupplierIds,
  ])

  useEffect(() => {
    dispatch({ type: 'filterAndSortItems' })
  }, [dispatch, state.searchText, state.sorting, state.selectedCreditStates])

  const onModalClosedCallback = useCallback(
    async (adjustmentId: string | null, status: string, notes: string) => {
      const existingItem = state.items?.find(
        (i) => i.adjustmentId === adjustmentId
      )
      if (existingItem) {
        const updatedItem = {
          ...existingItem,
          actioned:
            status === creditStateFilterSelectionTypes.actioned ||
            status === creditStateFilterSelectionTypes.received ||
            status === creditStateFilterSelectionTypes.rejected,
          received: status === creditStateFilterSelectionTypes.received,
          rejected: status === creditStateFilterSelectionTypes.rejected,
          hasNotes: Boolean(notes),
        }
        dispatch({
          type: 'replaceItems',
          payload: [updatedItem],
        })
      }
      setModalOpen(false)
    },
    [dispatch, state.items]
  )

  const handleConfirmFileExport = async () => {
    const getCreditsExport = async () => {
      const response = await httpService.postAsync<Blob>(
        PlatformApiPaths.GetCreditsCSV(state.selectedClient!),
        {
          invoiceDateFrom: getDateOnlyIsoString(state.dates[0]),
          invoiceDateTo: getDateOnlyIsoString(state.dates[1]),
          supplierIds: state.selectedSupplierIds,
          searchField: state.searchText,
          filterOptions: state.selectedCreditStates,
        },
        'StockBaseUrl',
        undefined,
        false
      )
      return response
    }
    if (state.selectedClient) {
      setIsDownloading(true)
      dispatch({ type: 'setError', payload: null })
      const response = await getCreditsExport()
      if (!response?.hasErrors) {
        const blob = await response?.rawResponse?.blob()
        if (blob) {
          window.open(window.URL.createObjectURL(blob), '_blank')
          setExportModalOpen(false)
          setIsDownloading(false)
        }
      } else {
        setExporErrors(GetErrorMessage(response.statusCode))
        setIsDownloading(false)
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
          }}
        >
          <SearchBox
            placeholder={
              locale.translation.GoodsInPage.DeliveriesSearch
                .SearchByInvoiceNumber
            }
            searchText={state.searchText}
            onTextUpdated={(text) => {
              dispatch({
                type: 'setSearchField',
                payload: { text, resetPaging: false },
              })
            }}
            testId={'credits-search-field'}
          />
          <MultiSelection
            id={'actioneOrReceived'}
            onSelectionApplied={(s) => {
              dispatch({
                type: 'setSelectedCreditStates',
                payload: s,
              })
            }}
            options={translation.CreditsFilter.ActionedOptions}
            selectedOptionKeys={state.selectedCreditStates}
            title={
              state.selectedCreditStates.length === 1
                ? translation.CreditsFilter.ActionedOptions[
                    state.selectedCreditStates[0]
                  ]
                : state.selectedCreditStates.length ===
                  Object.keys(translation.CreditsFilter.ActionedOptions).length
                ? translation.CreditsFilter.All
                : translation.CreditsFilter.MultiSelection
            }
            isDirty={
              state.selectedCreditStates.length !==
              Object.entries(translation.CreditsFilter.ActionedOptions).length
            }
          />
          <MultiSelection
            id={'multi-supplier'}
            onSelectionApplied={(s) => {
              dispatch({
                type: 'setSelectedSupplierIds',
                payload: s,
              })
            }}
            options={state.availableSuppliers}
            selectedOptionKeys={state.selectedSupplierIds}
            title={
              state.selectedSupplierIds.length > 0 &&
              state.selectedSupplierIds.length ===
                Object.entries(state.availableSuppliers).length
                ? locale.translation.MultiSupplierSelection.AllSuppliers
                : locale.translation.MultiSupplierSelection.SpecificSuppliers
            }
            isDirty={
              state.selectedSupplierIds.length > 0 &&
              state.selectedSupplierIds.length !==
                Object.entries(state.availableSuppliers).length
            }
          />
          <DateRangePickerPopover
            dateFrom={state.dates[0]}
            dateTo={state.dates[1]}
            localStorageKey={
              DatePresetsLocalStorageKeys.CREDITS_DATE_RANGE_PRESET_KEY
            }
            onDatesSelected={(from, to) => {
              dispatch({ type: 'setDates', payload: [from, to] })
            }}
            allowNullDates={true}
          />
          <Button
            data-testid="export-to-csv-button"
            variant="outlined"
            disabled={(state.filteredItems?.length ?? 0) === 0}
            onClick={() => {
              setExportModalOpen(true)
            }}
          >
            {isDownloading ? (
              <CircularProgress size={20} />
            ) : (
              <FileDownloadIcon />
            )}
          </Button>
        </Box>
      </Box>
      {!state.error && state.filteredItems && (
        <VirtuosoMuiTable
          rows={state.filteredItems ?? state.items}
          columns={columnDefinitions}
          onRowClick={(rowData) => {
            setSelectedCredit(
              rowData as ProductItemWithAdjustmentAndInvoiceDetails
            )
            setModalOpen(true)
          }}
          sorting={state.sorting}
          onSortingChanged={(sorting) =>
            virtuosoTableHelpers.handleSortingChanged(sorting, false)
          }
          initialTableState={virtuosoTableState.virtuosoState}
          onTableStateChanged={virtuosoTableHelpers.handleTableStateChanged}
        />
      )}
      {modalOpen && (
        <ModalContainer
          centerContent={true}
          open={modalOpen}
          onClickedClose={() => {
            setModalOpen(false)
          }}
          sx={{
            flexGrow: 0,
            minWidth: '500px',
            maxWidth: '900px',
          }}
        >
          <CreditForActionForm
            selectedProductDetails={selectedCredit!}
            onSubmitCallback={onModalClosedCallback}
          />
        </ModalContainer>
      )}
      {exportModalOpen && (
        <ConfirmDialog
          title={translation.CreditsExportModal.Title}
          onCancel={() => {
            setExportModalOpen(false)
            setExporErrors(null)
          }}
          onOkWithLoading={() => handleConfirmFileExport()}
          isLoading={state.error ? false : isDownloading}
          cancelText={translation.CreditsExportModal.CancelText}
          canCancel={!isDownloading}
          okText={translation.CreditsExportModal.ConfirmText}
          text={translation.CreditsExportModal.Text(
            getDateOnlyShortMonthString(state.dates[0]),
            getDateOnlyShortMonthString(state.dates[1]),
            state.selectedSupplierIds.length ===
              Object.keys(state.availableSuppliers).length
              ? translation.CreditsExportModal.AllSuppliersText
              : state.selectedSupplierIds.length === 1
              ? state.availableSuppliers[state.selectedSupplierIds[0]]
              : translation.CreditsExportModal.MultipleSuppliersText,
            state.filteredItems!.length
          )}
        >
          {exportErrors && (
            <Alert variant="filled" severity="error">
              {exportErrors}
            </Alert>
          )}
        </ConfirmDialog>
      )}
      {state.error && (
        <Alert variant="filled" severity="error">
          {state.error}
        </Alert>
      )}
    </Box>
  )
}
export default TabCreditsForAction
