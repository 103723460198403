import React, {
  ForwardRefRenderFunction,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import Image from './components/Image'
import ZoomImage from './components/ZoomImage'
import FullscreenPortal from './components/FullscreenPortal'

interface InnerImageZoomProps {
  moveType?: string
  zoomType?: string
  src: string
  alt: string
  sources?: any[]
  width?: number
  height?: number
  hasSpacer?: boolean
  imgAttributes?: any
  zoomSrc?: string
  zoomScale?: number
  zoomPreload?: boolean
  fadeDuration?: number
  fullscreenOnMobile?: boolean
  mobileBreakpoint?: number
  hideCloseButton?: boolean
  hideHint?: boolean
  className?: string
  afterZoomIn?: () => void
  afterZoomOut?: () => void
  onLoaded?: () => void
}

export interface InnerImageZoomHandle {
  requestZoomOut: () => void
}

const InnerImageZoom: ForwardRefRenderFunction<
  InnerImageZoomHandle,
  InnerImageZoomProps
> = (
  {
    moveType = 'pan',
    zoomType = 'click',
    src,
    sources,
    width,
    height,
    hasSpacer,
    imgAttributes = {},
    zoomSrc,
    zoomScale = 1,
    zoomPreload,
    fadeDuration = 150,
    fullscreenOnMobile,
    mobileBreakpoint = 640,
    hideCloseButton,
    hideHint,
    className,
    afterZoomIn,
    afterZoomOut,
    alt,
    onLoaded
  },
  forwardedRef
) => {
  const img = useRef<any>(null)
  const zoomImg = useRef<any>(null)
  const imgProps = useRef<any>({})
  const [isActive, setIsActive] = useState(zoomPreload)
  const [isTouch, setIsTouch] = useState(false)
  const [isZoomed, setIsZoomed] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isValidDrag, setIsValidDrag] = useState(false)
  const [isFading, setIsFading] = useState(false)
  const [currentMoveType, setCurrentMoveType] = useState(moveType)
  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)

  useImperativeHandle(forwardedRef, () => ({
    requestZoomOut: () => {
      zoomOut()
    },
  }))

  const handleMouseEnter = (e: any) => {
    setIsActive(true)
    setIsFading(false)
    zoomType === 'hover' && !isZoomed && handleClick(e)
  }

  const handleTouchStart = () => {
    setIsTouch(true)
    setIsFullscreen(
      getFullscreenStatus(Boolean(fullscreenOnMobile), mobileBreakpoint)
    )
    setCurrentMoveType('drag')
  }

  const handleClick = (e: any) => {
    if (isZoomed) {
      if (isTouch) {
        hideCloseButton && handleClose(e)
      } else {
        !isValidDrag && zoomOut()
      }

      return
    }

    isTouch && setIsActive(true)

    if (zoomImg.current) {
      handleLoad({ target: zoomImg.current })
      zoomIn(e.pageX, e.pageY)
    } else {
      imgProps.current.onLoadCallback = zoomIn.bind(this, e.pageX, e.pageY)
    }
  }

  const handleLoad = (e: any) => {
    const scaledDimensions = getScaledDimensions(e.target, zoomScale)

    zoomImg.current = e.target
    zoomImg.current.setAttribute('width', scaledDimensions.width)
    zoomImg.current.setAttribute('height', scaledDimensions.height)

    imgProps.current.scaledDimensions = scaledDimensions
    imgProps.current.bounds = getBounds(img.current, false)
    imgProps.current.ratios = getRatios(
      imgProps.current.bounds,
      scaledDimensions
    )

    if (imgProps.current.onLoadCallback) {
      imgProps.current.onLoadCallback()
      imgProps.current.onLoadCallback = undefined
    }
  }

  const handleMouseMove = (e: any) => {
    let left = e.pageX - imgProps.current.offsets.x
    let top = e.pageY - imgProps.current.offsets.y

    left = Math.max(Math.min(left, imgProps.current.bounds.width), 0)
    top = Math.max(Math.min(top, imgProps.current.bounds.height), 0)

    setLeft(left * -imgProps.current.ratios.x)
    setTop(top * -imgProps.current.ratios.y)
  }

  const handleDragStart = (e: any) => {
    const pageX =
      typeof e.pageX === 'number' ? e.pageX : e.changedTouches[0].pageX
    const pageY =
      typeof e.pageY === 'number' ? e.pageY : e.changedTouches[0].pageY
    imgProps.current.offsets = getOffsets(
      pageX,
      pageY,
      zoomImg.current?.offsetLeft,
      zoomImg.current?.offsetTop
    )

    setIsDragging(true)

    if (!isTouch) {
      imgProps.current.eventPosition = {
        x: e.pageX,
        y: e.pageY,
      }
    }
  }

  const handleDragMove = useCallback((e: any) => {
    e.stopPropagation()
    const pageX =
      typeof e.pageX === 'number' ? e.pageX : e.changedTouches[0].pageX
    const pageY =
      typeof e.pageY === 'number' ? e.pageY : e.changedTouches[0].pageY
    let left = pageX - imgProps.current.offsets.x
    let top = pageY - imgProps.current.offsets.y

    left = Math.max(
      Math.min(left, 0),
      (imgProps.current.scaledDimensions.width -
        imgProps.current.bounds.width) *
        -1
    )
    top = Math.max(
      Math.min(top, 0),
      (imgProps.current.scaledDimensions.height -
        imgProps.current.bounds.height) *
        -1
    )

    setLeft(left)
    setTop(top)
  }, [])

  const handleDragEnd = (e: any) => {
    setIsDragging(false)

    if (!isTouch) {
      const moveX = Math.abs(e.pageX - imgProps.current.eventPosition.x)
      const moveY = Math.abs(e.pageY - imgProps.current.eventPosition.y)
      setIsValidDrag(moveX > 5 || moveY > 5)
    }
  }

  const handleMouseLeave = (e: any) => {
    currentMoveType === 'drag' && isZoomed ? handleDragEnd(e) : handleClose(e)
  }

  const handleClose = (e: any) => {
    if (!(!isTouch && e.target.classList.contains('iiz__close'))) {
      if (!isZoomed || isFullscreen || !fadeDuration) {
        handleFadeOut({})
      } else {
        setIsFading(true)
      }
    }

    zoomOut()
  }

  const handleFadeOut = (e: any) => {
    if (e.propertyName === 'opacity' && img.current.contains(e.target)) {
      if ((zoomPreload && isTouch) || !zoomPreload) {
        zoomImg.current = null
        imgProps.current = getDefaults()
        setIsActive(false)
      }

      setIsTouch(false)
      setIsFullscreen(false)
      setCurrentMoveType(moveType)
      setIsFading(false)
    }
  }

  const initialMove = (pageX: number, pageY: number) => {
    imgProps.current.offsets = getOffsets(
      window.pageXOffset,
      window.pageYOffset,
      -imgProps.current.bounds.left,
      -imgProps.current.bounds.top
    )
    handleMouseMove({ pageX, pageY })
  }

  const initialDrag = (pageX: number, pageY: number) => {
    let initialPageX =
      (pageX - (window.pageXOffset + imgProps.current.bounds.left)) *
      -imgProps.current.ratios.x
    let initialPageY =
      (pageY - (window.pageYOffset + imgProps.current.bounds.top)) *
      -imgProps.current.ratios.y

    initialPageX =
      initialPageX +
      (isFullscreen
        ? (window.innerWidth - imgProps.current.bounds.width) / 2
        : 0)
    initialPageY =
      initialPageY +
      (isFullscreen
        ? (window.innerHeight - imgProps.current.bounds.height) / 2
        : 0)
    imgProps.current.bounds = getBounds(img.current, isFullscreen)
    imgProps.current.offsets = getOffsets(0, 0, 0, 0)

    handleDragMove({
      changedTouches: [
        {
          pageX: initialPageX,
          pageY: initialPageY,
        },
      ],
      preventDefault: () => {},
      stopPropagation: () => {},
    })
  }

  const zoomIn = (pageX: number, pageY: number) => {
    setIsZoomed(true)
    currentMoveType === 'drag'
      ? initialDrag(pageX, pageY)
      : initialMove(pageX, pageY)
    afterZoomIn && afterZoomIn()
  }

  const zoomOut = () => {
    setIsZoomed(false)
    afterZoomOut && afterZoomOut()
  }

  const getDefaults = () => {
    return {
      onLoadCallback: null,
      bounds: {},
      offsets: {},
      ratios: {},
      eventPosition: {},
      scaledDimensions: {},
    }
  }

  const getBounds = (img: any, isFullscreen: boolean) => {
    if (isFullscreen) {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
        left: 0,
        top: 0,
      }
    }

    return img.getBoundingClientRect()
  }

  const getOffsets = (
    pageX: number,
    pageY: number,
    left: number,
    top: number
  ) => {
    return {
      x: pageX - left,
      y: pageY - top,
    }
  }

  const getRatios = (bounds: any, dimensions: any) => {
    return {
      x: (dimensions.width - bounds.width) / bounds.width,
      y: (dimensions.height - bounds.height) / bounds.height,
    }
  }

  const getFullscreenStatus = (
    fullscreenOnMobile: boolean,
    mobileBreakpoint: any
  ) => {
    return (
      fullscreenOnMobile &&
      window.matchMedia &&
      window.matchMedia(`(max-width: ${mobileBreakpoint}px)`).matches
    )
  }

  const getScaledDimensions = (zoomImg: any, zoomScale: any) => {
    return {
      width: zoomImg.naturalWidth * zoomScale,
      height: zoomImg.naturalHeight * zoomScale,
    }
  }

  const zoomImageProps = {
    src: zoomSrc || src,
    fadeDuration: isFullscreen ? 0 : fadeDuration,
    top,
    left,
    isZoomed,
    onLoad: handleLoad,
    onDragStart: currentMoveType === 'drag' ? handleDragStart : undefined,
    onDragEnd: currentMoveType === 'drag' ? handleDragEnd : undefined,
    onClose:
      !hideCloseButton && currentMoveType === 'drag' ? handleClose : undefined,
    onFadeOut: isFading ? handleFadeOut : undefined,
  }

  useEffect(() => {
    imgProps.current = getDefaults()
  }, [])

  useEffect(() => {
    getFullscreenStatus(Boolean(fullscreenOnMobile), mobileBreakpoint) &&
      setIsActive(false)
  }, [fullscreenOnMobile, mobileBreakpoint])

  useEffect(() => {
    if (!zoomImg.current) {
      return
    }

    const eventType = isTouch ? 'touchmove' : 'mousemove'

    if (isDragging) {
      zoomImg.current.addEventListener(eventType, handleDragMove, {
        passive: true,
      })
    } else {
      zoomImg.current.removeEventListener(eventType, handleDragMove)
    }
  }, [isDragging, isTouch, handleDragMove])

  return (
    <figure
      className={`iiz ${currentMoveType === 'drag' ? 'iiz--drag' : ''} ${
        className ? className : ''
      }`}
      style={{ width: width, userSelect: 'none' }}
      ref={img}
      onTouchStart={isZoomed ? undefined : handleTouchStart}
      onClick={handleClick}
      onMouseEnter={isTouch ? undefined : handleMouseEnter}
      onMouseMove={
        currentMoveType === 'drag' || !isZoomed ? undefined : handleMouseMove
      }
      onMouseLeave={isTouch ? undefined : handleMouseLeave}
    >
      <Image
        src={src}
        sources={sources}
        width={width}
        height={height}
        hasSpacer={hasSpacer}
        imgAttributes={imgAttributes}
        fadeDuration={fadeDuration}
        isZoomed={isZoomed}
        alt={alt}
        onLoaded={onLoaded}
      />

      {isActive && (
        <Fragment>
          {isFullscreen ? (
            <FullscreenPortal>
              <ZoomImage {...zoomImageProps} />
            </FullscreenPortal>
          ) : (
            <ZoomImage {...zoomImageProps} />
          )}
        </Fragment>
      )}

      {!hideHint && !isZoomed && <span className="iiz__btn iiz__hint"></span>}
    </figure>
  )
}

export default React.forwardRef(InnerImageZoom)
