import { FC } from 'react'
import Box from '@mui/material/Box'
import theme from '../styles/theme'
import Logo from '../components/Logo'
import { locale } from '../locales'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const NotFoundPage: FC = () => {
  return (
    <Box data-testid="header" sx={{ width: '100%' }}>
      <Box
        sx={{
          height: '72px',
          paddingX: { xs: theme.spacing(1), lg: theme.spacing(2) },
          paddingTop: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                width: '260px',
                display: 'flex',
                alignItems: 'center',
              }}
              data-testid="logo-container"
            >
              <Logo></Logo>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h5">
          {locale.translation.NotFoundPage.Text}
        </Typography>
        <Typography variant="h5">
          {locale.translation.NotFoundPage.Please}
          <Link href="/auth/login">{locale.translation.NotFoundPage.Here}</Link>
          {locale.translation.NotFoundPage.ToGoBack}
        </Typography>
      </Box>
    </Box>
  )
}

export default NotFoundPage
