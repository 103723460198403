import ButtonBase from '@mui/material/ButtonBase'
import { SnackbarKey, closeSnackbar } from 'notistack'
import { FC } from 'react'
import CloseIcon from '@mui/icons-material/Close'

export const SnackbarDismissButton: FC<{ snackBarId: SnackbarKey }> = ({
  snackBarId,
}) => {
  return (
    <ButtonBase onClick={() => closeSnackbar(snackBarId)}>
      <CloseIcon />
    </ButtonBase>
  )
}
