import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from 'react'

import Box from '@mui/material/Box'
import InvoiceImage, {
  InvoiceImageHandle,
  InvoicePageImageState,
} from './InvoiceImage'
import AutoSizingBox from '../../../components/Util/AutoSizingBox'
import theme from '../../../styles/theme'
import { useStateUpdatedCallback } from '../../../hooks/useStateUpdatedCallback'

export interface InvoiceImagesPaneState {
  bookInId: string | null
  invoicePageImageStates: InvoicePageImageState[]
  selectedPage: { bookInId: string; pageNumber: number } | null
  scrollTop?: number
}

type UpdateInvoicePageImageStateAction = {
  type: 'UPDATE_INVOICE_PAGE_IMAGE_STATE'
  payload: InvoicePageImageState
}
type SelectPageAction = {
  type: 'SELECT_PAGE'
  payload: { bookInId: string; pageNumber: number }
}
type SetScrollTopAction = {
  type: 'SET_SCROLL_TOP'
  payload: number
}

const reducer = (
  state: InvoiceImagesPaneState,
  action:
    | UpdateInvoicePageImageStateAction
    | SelectPageAction
    | SetScrollTopAction
) => {
  switch (action.type) {
    case 'UPDATE_INVOICE_PAGE_IMAGE_STATE':
      return {
        ...state,
        invoicePageImageStates: state.invoicePageImageStates.map((p) =>
          p.bookInId === action.payload.bookInId &&
          p.pageNumber === action.payload.pageNumber
            ? action.payload
            : p
        ),
      }
    case 'SELECT_PAGE':
      return {
        ...state,
        selectedPage: action.payload,
      }
    case 'SET_SCROLL_TOP':
      return {
        ...state,
        scrollTop: action.payload,
      }
  }
}

export interface InvoiceImagesPaneHandle {
  selectPage: (bookInId: string, pageNumber: number) => void
  getCurrentScrollTop: () => number
}

const InvoiceImagesPane: ForwardRefRenderFunction<
  InvoiceImagesPaneHandle,
  {
    initialState: InvoiceImagesPaneState
    onStateUpdated: (state: InvoiceImagesPaneState) => void
    onImageLoaded: (
      bookInId: string,
      pageNumber: number,
      base64: string
    ) => void
  }
> = ({ initialState, onStateUpdated, onImageLoaded }, forwardedRef) => {
  const scrollingContainerRef = useRef<HTMLDivElement>(null)
  const pageContainerRefs = useRef<
    { bookInId: string; pageNumber: number; element: HTMLDivElement | null }[]
  >(
    initialState.invoicePageImageStates.map((p) => ({
      bookInId: p.bookInId!,
      pageNumber: p.pageNumber!,
      element: null,
    }))
  )
  const [state, dispatch] = useReducer(reducer, initialState)
  const currentScrollTop = useRef<number>(0)
  useStateUpdatedCallback(state, onStateUpdated)

  const invoiceImageRefs = useRef<InvoiceImageHandle[] | null>(
    Array(initialState.invoicePageImageStates.length).fill(null)
  )

  useImperativeHandle(forwardedRef, () => ({
    selectPage: (bookInId: string, pageNumber: number) => {
      invoiceImageRefs.current?.forEach((r) => {
        r.zoomInOut(false)
      })
      handleSelectPage(bookInId, pageNumber, true)
    },
    getCurrentScrollTop: () => currentScrollTop.current,
  }))

  const handleSelectPage = (
    bookInId: string,
    pageNumber: number,
    scrollToTop: boolean
  ) => {
    dispatch({ type: 'SELECT_PAGE', payload: { bookInId, pageNumber } })
    if (scrollToTop) {
      const pageElement = pageContainerRefs.current.find(
        (r) => r.bookInId === bookInId && r.pageNumber === pageNumber
      )?.element
      if (pageElement) {
        pageElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }

  const handleImageStateUpdated = useCallback(
    (state: InvoicePageImageState) => {
      dispatch({ type: 'UPDATE_INVOICE_PAGE_IMAGE_STATE', payload: state })
    },
    []
  )

  const handleScrollStateUpdated = useCallback(() => {
    currentScrollTop.current = scrollingContainerRef.current?.scrollTop ?? 0
  }, [])

  useEffect(() => {
    if (scrollingContainerRef.current) {
      scrollingContainerRef.current.scrollTop = initialState.scrollTop ?? 0
    }
  }, [initialState.scrollTop])

  useEffect(() => {
    const scrollingContainer = scrollingContainerRef.current
    if (scrollingContainer) {
      scrollingContainer?.addEventListener('scroll', handleScrollStateUpdated)
    }
    return () => {
      scrollingContainer?.removeEventListener(
        'scroll',
        handleScrollStateUpdated
      )
    }
  }, [handleScrollStateUpdated])

  return (
    <Box
      data-testid="invoice-images-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
      }}
    >
      <AutoSizingBox ref={scrollingContainerRef}>
        {state.invoicePageImageStates &&
          state.invoicePageImageStates
            .filter((p) => p.fileKey)
            .map((p, i) => (
              <Box
                ref={(el) =>
                  (pageContainerRefs.current[i].element = el as HTMLDivElement)
                }
                sx={{
                  border: `2px dashed ${
                    p.base64 &&
                    p.bookInId === state.selectedPage?.bookInId &&
                    p.pageNumber === state.selectedPage?.pageNumber
                      ? theme.palette.grey[400]
                      : 'transparent'
                  }`,
                  marginRight: theme.spacing(1),
                }}
                key={`${p.bookInId}-${p.pageNumber}`}
              >
                <InvoiceImage
                  ref={(handle) => {
                    if (invoiceImageRefs?.current && handle) {
                      invoiceImageRefs.current[i] = handle
                    }
                  }}
                  key={`${p.bookInId}-${p.pageNumber}`}
                  initialState={p}
                  onStateUpdated={handleImageStateUpdated}
                  onImageLoaded={onImageLoaded}
                />
              </Box>
            ))}
      </AutoSizingBox>
    </Box>
  )
}

export default forwardRef(InvoiceImagesPane)
