import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'
import LinearProgress from '@mui/material/LinearProgress'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { mobileNavigationOpenState } from '../../state/UIStates'
import theme from '../../styles/theme'
import Logo from '../Logo'
import { stockrxUserState } from '../../state/AuthenticationState'
import UserPopover from './UserPoppover'
import ClientSelect from './ClientSelect'
import Typography from '@mui/material/Typography'
import { usePageTitle } from '../../hooks/usePageTitle'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'

const Header: FC<PropsWithChildren> = () => {
  const loggedInUser = useRecoilValue(stockrxUserState)
  const setMobileNavigationOpen = useSetRecoilState(mobileNavigationOpenState)
  const { pageTitleText, pageSubTitleText } = usePageTitle('')
  const [currentPageTitle, setCurrentPageTitle] = useState<string>('')
  const [currentPageSubTitle, setCurrentPageSubTitle] = useState<string>('')
  const { isLoading } = useGlobalIsLoading()

  useEffect(() => {
    setCurrentPageTitle(pageTitleText)
    setCurrentPageSubTitle(pageSubTitleText || '')
  }, [pageTitleText, pageSubTitleText])
  return (
    <Box data-testid="header">
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <LinearProgress />
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          height: '72px',
          paddingX: { xs: theme.spacing(1), lg: theme.spacing(2) },
          paddingTop: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {loggedInUser?.authId && (
              <Button
                sx={{
                  padding: 0,
                  minWidth: 0,
                  fontSize: '2rem',
                  marginRight: theme.spacing(1),
                  marginTop: '2px',
                }}
                variant="text"
                onClick={() => setMobileNavigationOpen(true)}
                data-testid="hamburger-menu"
              >
                <MenuIcon fontSize="inherit" />
              </Button>
            )}
            {!loggedInUser?.authId && (
              <Box
                sx={{
                  width: '260px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                data-testid="logo-container"
              >
                <Logo></Logo>
              </Box>
            )}
          </Box>

          {loggedInUser?.authId && (
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Box
                sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
                data-testid="page-title-container"
              >
                <Typography variant={'h4'}>{currentPageTitle}</Typography>
                <Typography
                  variant={'h6'}
                  sx={{ flex: 1, textAlign: 'center' }}
                >
                  {currentPageSubTitle}
                </Typography>
              </Box>
              <ClientSelect />
              <UserPopover />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Header
