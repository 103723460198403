import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, {
  FC,
  forwardRef,
  PropsWithChildren,
  useRef,
  useState,
} from 'react'
import {
  StateSnapshot,
  TableComponents,
  TableVirtuoso,
  TableVirtuosoHandle,
} from 'react-virtuoso'
import { toCurrencyString } from '../../utils/Helpers'
import theme from '../../styles/theme'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InfoTooltip from '../Interactions/InfoTooltip'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import { SortingType } from '../../utils/SortingUtils'
import { locale } from '../../locales'
import { SxProps, Theme } from '@mui/material/styles'

// TODO: remove these imports from the app
// import { ArrowUpward } from '@mui/icons-material'

const moveToNextSortingState = (
  currentSortingState: SortingType
): SortingType => {
  switch (currentSortingState) {
    case 'NONE':
      return 'ASC'
    case 'ASC':
      return 'DESC'
    case 'DESC':
      return 'NONE'
  }
}

export interface VirtuosoColumnSorting {
  dataKey?: string | number
  sortingType: SortingType
}

export interface VirtuosoColumn<T> {
  dataKey: string | number
  label: string
  widthCss?: string

  currency?: boolean
  infoTooltip?: string

  customCellNode?: (cellValue: unknown, rowData: T) => React.ReactNode
  calculatedCellSx?: (rowData: T) => SxProps<Theme>
  valueTransformer?: (rowData: T) => string | number | null

  sortable?: boolean
}

interface VirtualizedTableMuiProps<T> {
  columns: VirtuosoColumn<T>[]
  rows: T[]
  sorting?: VirtuosoColumnSorting | null
  noRowsMessage?: string
  initialTableState?: StateSnapshot
  onRowClick?: (row: T) => void
  onSortingChanged?: (sorting: VirtuosoColumnSorting | null) => void
  onEndReached?: (index: number) => void
  calculateRowSx?: (row: T) => SxProps<Theme>
  calculateRowTitle?: (row: T) => string | null
  onTableStateChanged?: (state: StateSnapshot) => void
}

const VirtuosoMuiTable = <T,>(
  props: PropsWithChildren<VirtualizedTableMuiProps<T>>
) => {
  const onSortingChanged = props.onSortingChanged ?? (() => {})
  const [tableState] = useState(props.initialTableState)

  const ref = useRef<TableVirtuosoHandle>(null)

  return (
    <Paper variant="outlined" elevation={0} sx={{ flex: 1, width: '100%' }}>
      <TableVirtuoso
        ref={ref}
        restoreStateFrom={tableState}
        data={props.rows.map((r) => {
          return {
            ...r,
            onRowClick: props.onRowClick,
            calculateRowSx: props.calculateRowSx,
            calculateRowTitle: props.calculateRowTitle,
          }
        })}
        components={{
          ...VirtuosoTableComponents,
          EmptyPlaceholder: () => (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4} sx={{ border: 0 }}>
                  <Box
                    sx={{ padding: theme.spacing(2) }}
                    data-testid="no-data-container"
                  >
                    <Typography variant="body1">
                      {props.noRowsMessage ?? locale.translation.Common.NoData}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ),
        }}
        fixedHeaderContent={() =>
          fixedHeaderContent(
            props.columns,
            props.sorting ?? null,
            onSortingChanged
          )
        }
        itemContent={(index, row) => rowContent(index, row as T, props.columns)}
        endReached={props.onEndReached}
        rangeChanged={() => {
          if (ref.current) {
            ref.current.getState((s) => props.onTableStateChanged?.(s))
          }
        }}
        overscan={{ main: 10, reverse: 10 }}
      />
    </Paper>
  )
}

const VirtuosoTableComponents: TableComponents<unknown> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{
        borderCollapse: 'separate',
        tableLayout: 'fixed',
        height: 'fit-content',
      }}
    />
  ),
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: ({ item: _item, ...props }) => {
    const { onRowClick, calculateRowTitle, calculateRowSx } = _item as {
      onRowClick: (row: unknown) => void
      calculateRowTitle?: (row: unknown) => string | null
      calculateRowSx?: (row: unknown) => SxProps<Theme>
    }
    const isClickable = !!onRowClick
    const sx = isClickable
      ? {
          '&: hover': {
            backgroundColor: theme.palette.grey[100],
            cursor: 'pointer',
          },
        }
      : {
          '&: hover': {
            backgroundColor: theme.palette.grey[100],
          },
        }

    return (
      <TableRow
        sx={{ height: '100%', ...sx, ...calculateRowSx?.(_item) }}
        onClick={() => {
          if (isClickable) {
            onRowClick(_item)
          }
        }}
        title={calculateRowTitle?.(_item) ?? undefined}
        {...props}
      />
    )
  },
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
}

const fixedHeaderContent = <T,>(
  columns: VirtuosoColumn<T>[],
  sorting: VirtuosoColumnSorting | null,
  onSortingChanged: (sorting: VirtuosoColumnSorting | null) => void
) => {
  return (
    <HeaderRow
      columns={columns as VirtuosoColumn<unknown>[]}
      sorting={sorting}
      onSortingChanged={onSortingChanged}
    />
  )
}

const HeaderRow: FC<{
  columns: VirtuosoColumn<unknown>[]
  sorting: VirtuosoColumnSorting | null
  onSortingChanged: (sorting: VirtuosoColumnSorting | null) => void
}> = (props) => {
  return (
    <TableRow
      sx={{
        backgroundColor: theme.palette.grey[100],
        height: '80px',
      }}
    >
      {props.columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="center"
          sx={{
            width: column.widthCss ?? 'auto',
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            '&:last-child': {
              borderRight: 'none',
            },
          }}
        >
          <HeaderCell
            column={column}
            sorting={getColumnSorting(column.dataKey, props.sorting)}
            onClicked={(column) => {
              const nextSorting = handleHeaderClick(column, props.sorting)
              props.onSortingChanged(nextSorting)
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

const HeaderCell: FC<{
  column: VirtuosoColumn<unknown>
  sorting: VirtuosoColumnSorting | null
  onClicked: (column: VirtuosoColumn<unknown>) => void
}> = ({ column, sorting, onClicked }) => {
  const isSorting =
    sorting?.dataKey === column.dataKey &&
    sorting.sortingType &&
    sorting.sortingType !== 'NONE'
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(0),
      }}
    >
      <ButtonBase
        disabled={!column.sortable}
        onClick={() => {
          onClicked(column)
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="button"
          sx={{
            fontWeight: isSorting ? 600 : 400,
            color: theme.palette.common.black,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          {column.label}
        </Typography>
        {sorting?.sortingType === 'ASC' && (
          <ArrowUpward fontSize="inherit" sx={{ fontSize: '1rem' }} />
        )}
        {sorting?.sortingType === 'DESC' && (
          <ArrowDownwardIcon fontSize="inherit" sx={{ fontSize: '1rem' }} />
        )}
      </ButtonBase>
      {column.infoTooltip && <InfoTooltip text={column.infoTooltip} />}
    </Box>
  )
}

const handleHeaderClick = <T,>(
  clickedColumn: VirtuosoColumn<T>,
  sorting: VirtuosoColumnSorting | null
): VirtuosoColumnSorting | null => {
  if (clickedColumn.sortable && sorting) {
    const currentSortingType =
      clickedColumn.dataKey === sorting.dataKey ? sorting.sortingType : 'NONE'
    return {
      dataKey: clickedColumn.dataKey,
      sortingType: moveToNextSortingState(currentSortingType),
    }
  }
  return null
}

const rowContent = <T,>(
  _index: number,
  row: T,
  columns: VirtuosoColumn<T>[]
) => {
  return (
    <>
      {columns.map((column) => {
        return (
          <TableCell
            key={column.dataKey}
            align="center"
            sx={{
              borderRight: `1px solid ${theme.palette.grey[300]}`,
              '&:last-child': {
                borderRight: 'none',
              },
              fontWeight: 'inherit',
              ...column.calculatedCellSx?.(row),
            }}
          >
            {renderValue(column, row)}
          </TableCell>
        )
      })}
    </>
  )
}

const renderValue = <T,>(column: VirtuosoColumn<T>, row: T) => {
  const value = (row as Record<string | number, React.ReactNode>)[
    column.dataKey
  ]
  if (column.customCellNode) {
    return column.customCellNode(value, row)
  } else if (column.valueTransformer) {
    return column.valueTransformer(row) ?? '-'
  } else {
    if (column.currency) {
      return toCurrencyString(value as number) || '-'
    }
    return value ?? '-'
  }
}

const getColumnSorting = (
  columnKey: string | number,
  columnsSorting?: VirtuosoColumnSorting | null
): VirtuosoColumnSorting => {
  if (columnsSorting?.dataKey === columnKey) {
    return columnsSorting
  }
  return {
    dataKey: columnKey,
    sortingType: 'NONE',
  }
}

export default VirtuosoMuiTable
