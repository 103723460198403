import { DateTime } from 'luxon'
import { locale } from '../locales'
import { VirtuosoColumn } from '../components/Data/VirtuosoMuiTable'
import { ClientTypes } from '../types/entities/ClientPermission'
import { SupplierUniquePerId } from '../types/entities/Supplier'

export const getLocaleDateTimeMedWithWeekDay = (
  isoDateString?: string | null
) => {
  if (!isoDateString) {
    return null
  }
  const dateTime = DateTime.fromISO(isoDateString)
  return `${dateTime.toFormat('ccc')} ${dateTime.toLocaleString(
    DateTime.DATETIME_MED
  )}`
}

export const getLocaleDateTimeMedWithoutWeekDay = (
  isoDateString?: string | null
) => {
  if (!isoDateString) {
    return null
  }
  const dateTime = DateTime.fromISO(isoDateString)
  return `${dateTime.toLocaleString(DateTime.DATETIME_MED)}`
}

export const getLocaleDateMedWithWeekDay = (isoDateString?: string | null) => {
  if (!isoDateString) {
    return null
  }
  const dateTime = DateTime.fromISO(isoDateString)
  return ` ${dateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`
}

export const getLocaleDateMedWithWeekDayAndHour = (
  isoDateString?: string | null
) => {
  if (!isoDateString) {
    return null
  }
  const dateTime = DateTime.fromISO(isoDateString)
  return ` ${dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}`
}

export const getLocaleDateMedWithWeekDayFromEpochSeconds = (
  epochSeconds?: number
) => {
  if (!epochSeconds) {
    return null
  }
  const dateTime = DateTime.fromSeconds(epochSeconds)
  return ` ${dateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`
}

export const getLocaleDateMedWithWeekDayFromEpochMilliSeconds = (
  epochMilliSeconds?: number
) => {
  if (!epochMilliSeconds) {
    return null
  }
  const dateTime = DateTime.fromMillis(epochMilliSeconds)
  return ` ${dateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`
}

export const getLocaleDateMedWithoutWeekDay = (
  isoDateString?: string | null
) => {
  if (!isoDateString) {
    return null
  }
  const dateTime = DateTime.fromISO(isoDateString)
  return ` ${dateTime.toLocaleString(DateTime.DATE_MED)}`
}

export const getLocaleDateMedWithoutWeekDayFromEpochSeconds = (
  epochSeconds?: number
) => {
  if (!epochSeconds) {
    return null
  }
  const dateTime = DateTime.fromSeconds(epochSeconds)
  return ` ${dateTime.toLocaleString(DateTime.DATE_MED)}`
}

export const getLocaleDateMonthYear = (isoDateString?: string | null) => {
  if (!isoDateString) {
    return null
  }
  const dateTime = DateTime.fromISO(isoDateString)
  return `${dateTime.toFormat('LLLL yyyy')}`
}

export const getDateOnlyShortMonthString = (dateTime?: DateTime | null) => {
  if (!dateTime) {
    return null
  }
  return `${dateTime.toFormat('dd LLL yyyy')}`
}

export const getDateOnlyIsoString = (dateTime?: DateTime | null) => {
  if (!dateTime) {
    return null
  }
  return `${dateTime.toFormat('yyyy-MM-dd')}`
}

export const isoDateToEpoch = (isoDateString?: string) => {
  if (!isoDateString) {
    return 0
  }
  return DateTime.fromISO(isoDateString).toSeconds()
}

export const hoursDistanceFromNow = (isoDateString?: string | null) => {
  if (!isoDateString) {
    return null
  }
  return DateTime.fromISO(isoDateString).diffNow('hours')
}

export const toCurrencyString = (value: number | null | undefined): string => {
  return (
    value?.toLocaleString(locale.code, {
      style: 'currency',
      currency: locale.currency,
    }) ?? ''
  )
}

// convert hex to rgba
export const hexToRgba = (hex: string, opacity: number) => {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

// packSize converter
export const packSizeConverter = (
  packSize: number | null,
  unitOfMeasure?: string | null,
  subPackDescription?: string | null
): string | number => {
  if (packSize === -1) {
    return locale.translation.Common.Specials
  } else if (packSize === 0) {
    return locale.translation.Common.Any
  }

  const result = `${packSize || ''} ${unitOfMeasure || ''}`.trim()
  if (subPackDescription) {
    return `${result} - ${subPackDescription}`
  }

  return result
}

export const addOdsCodeColumn = <T extends { odsCode?: string | null }>(
  selectedClientType: string | null,
  odsToPharmacyMapping: { [key: string]: string } | null,
  result: VirtuosoColumn<T>[],
  columnIndex: number,
  sortable: boolean = false
) => {
  if (
    selectedClientType === ClientTypes.PharmacyGroup ||
    selectedClientType === ClientTypes.Company
  ) {
    const odsCodeColumn = {
      label: locale.translation.Common.Pharmacy,
      dataKey: 'odsCode',
      sortable: sortable,
      valueTransformer: (rowData: T) => {
        if (!odsToPharmacyMapping) {
          return rowData.odsCode
        }
        const pharmacyName = odsToPharmacyMapping[rowData.odsCode!]
        if (!pharmacyName) {
          return rowData.odsCode
        }
        return ` ${pharmacyName} [${rowData.odsCode}]`
      },
    } as VirtuosoColumn<T>
    result.splice(columnIndex, 0, odsCodeColumn)
  }
  return result
}

export const supplierArrayContainsAllSelectedItemIds = (
  suppliersArray: SupplierUniquePerId[],
  selectedItemIds: string[]
) => {
  return selectedItemIds.every((selectedItemId) =>
    suppliersArray.some((supplier) => supplier.supplierId === selectedItemId)
  )
}
