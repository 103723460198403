import { enGb } from './en-gb'

export interface ILocale {
  translation: any
  code: string
  currency: string
  currencySymbol: string
  decimalSeparator: string
  thousandSeparator: string
}

export const locale: ILocale = {
  translation: enGb,
  code: 'en-GB',
  currency: 'GBP',
  currencySymbol: '£',
  decimalSeparator: '.',
  thousandSeparator: ',',
}
