import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { ProductItemWithAdjustmentAndInvoiceDetails } from '../../../types/entities/Credit'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import {
  AdjustmentStatuses,
  PackSize,
  ErrorMatchingCreditForAction,
} from '../../../constants'
import theme from '../../../styles/theme'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import {
  getLocaleDateMedWithoutWeekDay,
  getLocaleDateTimeMedWithoutWeekDay,
  toCurrencyString,
} from '../../../utils/Helpers'
import { SxProps, Theme } from '@mui/material/styles'
import { AdjustmentSummaryWithImage } from '../entities/BookIn'

interface AdjustmentMenuProps {
  selectedProductDetails: ProductItemWithAdjustmentAndInvoiceDetails
  onSubmitCallback: (
    adjustmentId: string,
    status: string,
    notes: string
  ) => void
}
const translation =
  locale.translation.GoodsInPage.TabCreditsForAction.CreditForActionForm
const reasons = {
  ...locale.translation.DisposalReasons,
  ...locale.translation.CreditReasons,
}

interface FormValues {
  isReceived: boolean
  isActioned: boolean
  isRejected: boolean
  notes: string | null
}

export const CreditForActionForm: FC<AdjustmentMenuProps> = ({
  selectedProductDetails,
  onSubmitCallback,
}) => {
  const { platformHttpService, notificationService } =
    useContext(ServiceContext)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [photo, getPhoto] = useState<string>('')
  const [formHasChanged, setformHasChanged] = useState(false)

  const handleSubmit = async (formValues: FormValues) => {
    const status =
      formValues.isReceived === true
        ? AdjustmentStatuses.Received
        : formValues.isRejected === true
        ? AdjustmentStatuses.Rejected
        : formValues.isActioned === true
        ? AdjustmentStatuses.Actioned
        : AdjustmentStatuses.Completed

    setLoading(true)

    // Update adjustment status
    if (
      formik.values.isActioned !== selectedProductDetails.actioned ||
      formValues.isReceived !== selectedProductDetails.received ||
      formValues.isRejected !== selectedProductDetails.rejected
    ) {
      const responseActioned = await platformHttpService.postAsync<string>(
        PlatformApiPaths.SetAdjustmentActioned(selectedProductDetails.odsCode!),
        {
          AdjustmentId: selectedProductDetails.adjustmentId,
          ActionedStatus: status,
        },
        'StockBaseUrl'
      )
      if (responseActioned.hasErrors) {
        if (
          responseActioned.errors[0].message.includes(
            ErrorMatchingCreditForAction.adjustmentError
          )
        ) {
          setError(
            GetErrorMessage(
              400,
              translation.Notifications.ErrorAlertIfStatusNotValid
            )
          )
          setLoading(false)
        } else {
          setError(GetErrorMessage(responseActioned.statusCode))
          setLoading(false)
        }
        return
      }
    }

    if (!selectedProductDetails.hasNotes && !Boolean(formValues.notes)) {
      // Notes haven't changed so go back
      setLoading(false)
      onSubmitCallback(
        selectedProductDetails.adjustmentId!,
        status,
        formValues?.notes ?? ''
      )
    } else {
      // Update notes
      const responseNotes = await platformHttpService.postAsync<string>(
        PlatformApiPaths.SetAdjustmentNotes(selectedProductDetails.odsCode!),
        {
          AdjustmentId: selectedProductDetails.adjustmentId,
          Notes: formValues.notes,
        },
        'StockBaseUrl'
      )
      setLoading(false)

      if (!responseNotes.hasErrors) {
        notificationService.showNotification(
          translation.Notifications.NotificationCreditUpdatedsuccessfully,
          'success',
          5000
        )
        onSubmitCallback(
          selectedProductDetails.adjustmentId!,
          status,
          formValues?.notes ?? ''
        )
      } else {
        if (
          responseNotes.errors[0].message.includes(
            ErrorMatchingCreditForAction.enrichLimitError
          )
        ) {
          setError(
            GetErrorMessage(
              400,
              translation.Notifications.ErrorAlertUserEnrichesMaximumCharacters
            )
          )
          setLoading(false)
        } else {
          setError(GetErrorMessage(responseNotes.statusCode))
          setLoading(false)
        }
      }
    }
  }

  const getInitialValues = useCallback(
    (notes?: string) => {
      return {
        isReceived: selectedProductDetails.received!,
        isActioned: selectedProductDetails.actioned!,
        isRejected: selectedProductDetails.rejected!,
        notes: notes ?? '',
      }
    },
    [selectedProductDetails]
  )

  // Set up formik
  const {
    setFieldValue: setFormikFieldValue,
    validateField: validateFormikField,
    setFieldError: setFormikFieldError,
    isValid: formikIsValid,
    resetForm,
    ...formik
  } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: yup.object({
      isReceived: yup.bool(),
      isActioned: yup.bool(),
      notes: yup.string().nullable(),
    }),
    onSubmit: (values) => handleSubmit(values),
  })

  useEffect(() => {
    const getAdjustment = async (odsCode: string, adjustmentId: string) => {
      return await platformHttpService.getAsync<AdjustmentSummaryWithImage>(
        PlatformApiPaths.GetAdjustmentById(odsCode, adjustmentId),
        'StockBaseUrl'
      )
    }
    if (
      selectedProductDetails?.odsCode &&
      selectedProductDetails?.adjustmentId
    ) {
      if (selectedProductDetails.hasNotes || selectedProductDetails.hasPhoto) {
        getAdjustment(
          selectedProductDetails.odsCode,
          selectedProductDetails.adjustmentId
        ).then((response) => {
          resetForm({ values: getInitialValues(response?.data?.notes) })
          getPhoto(response?.data?.photoUrl ?? '')
        })
      }
    }
  }, [
    getInitialValues,
    platformHttpService,
    resetForm,
    selectedProductDetails.adjustmentId,
    selectedProductDetails.hasNotes,
    selectedProductDetails.hasPhoto,
    selectedProductDetails.odsCode,
  ])

  const handleFormChange = () => {
    setformHasChanged(true)
  }

  const rowStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }

  return (
    <>
      <FormControl
        component={'form'}
        onSubmit={formik.handleSubmit}
        onChange={handleFormChange}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5">
          {`${selectedProductDetails.productName} [${
            selectedProductDetails.productPackSize === -1
              ? PackSize.Specials
              : selectedProductDetails.productPackSize
          }]`}
        </Typography>
        <Typography sx={{ color: theme.palette.grey[500] }}>
          {translation.LabelAdjustmentDate}
          <Box component="span" sx={{ fontWeight: 600 }}>
            {getLocaleDateTimeMedWithoutWeekDay(
              selectedProductDetails!.adjustmentDateTime!
            ) ?? ''}
          </Box>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingRight: theme.spacing(2),
            }}
          >
            <Box sx={{ ...rowStyle }}>
              <Typography>
                {translation.LabelSupplier}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.supplierDisplayName}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelInvoiceNumber}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.invoiceNumber}
                </Box>
              </Typography>
            </Box>
            <Box sx={rowStyle}>
              <Typography>
                {translation.LabelInvoiceDate}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {getLocaleDateMedWithoutWeekDay(
                    selectedProductDetails!.invoiceDate!
                  ) ?? ''}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelProductCode}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.productCode
                    ? selectedProductDetails.productCode
                    : '-'}
                </Box>
              </Typography>
            </Box>
            <Box sx={{ ...rowStyle, marginTop: theme.spacing(3) }}>
              <Typography>
                {translation.LabelCreditPacks}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {selectedProductDetails.adjustmentNumberOfPacks}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelCreditReason}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {reasons[selectedProductDetails.adjustmentReasonCode!]}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelPricePerUnit}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {toCurrencyString(selectedProductDetails.productUnitPrice)}
                </Box>
              </Typography>
              <Typography>
                {translation.LabelTotalCredit}
                <Box component="span" sx={{ fontWeight: 600 }}>
                  {toCurrencyString(selectedProductDetails.totalCreditValue)}
                </Box>
              </Typography>
            </Box>
          </Box>
          {selectedProductDetails.hasPhoto && (
            <Box sx={{ width: '120px' }}>
              <img src={photo} alt="credit" style={{ width: '100%' }} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(1),
            margin: theme.spacing(2),
            marginX: 0,
            fontWeight: 600,
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '4px',
          }}
        >
          <FormControlLabel
            id="actioned-status-checkbox"
            control={
              <Checkbox
                checked={formik.values.isActioned}
                disabled={formik.values.isReceived || formik.values.isRejected}
                onChange={formik.handleChange}
                name="isActioned"
              />
            }
            label={translation.LabelActionedBasedOnStatus}
          />
          <FormControlLabel
            id="received-status-checkbox"
            control={
              <Checkbox
                name="isReceived"
                checked={formik.values.isReceived}
                disabled={!formik.values.isActioned || formik.values.isRejected}
                onChange={formik.handleChange}
              />
            }
            label={translation.LabelReceived}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          />
          <FormControlLabel
            id="rejected-status-checkbox"
            control={
              <Checkbox
                name="isRejected"
                checked={formik.values.isRejected}
                disabled={!formik.values.isActioned || formik.values.isReceived}
                onChange={formik.handleChange}
              />
            }
            label={translation.LabelRejected}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          />
        </Box>
        <TextField
          data-testid="credits-text-area"
          id="multiline-notes-input"
          label={translation.LabelNotes}
          multiline
          fullWidth
          rows={5}
          value={formik.values.notes}
          name="notes"
          onChange={formik.handleChange}
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <LoadingButton
          data-testid="complete-save-receive-notes-button"
          type="submit"
          disableElevation
          variant="contained"
          color="primary"
          loading={loading}
          disabled={!formHasChanged || !formikIsValid}
        >
          {translation.SaveButton}
        </LoadingButton>
      </FormControl>

      {error && (
        <Box
          sx={{
            marginTop: theme.spacing(1),
          }}
        >
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </Box>
      )}
    </>
  )
}
