import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  useImperativeHandle,
  useState,
} from 'react'
import theme from '../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import Slide from '@mui/material/Slide'

export interface ButtonPopOverHandle {
  close: () => void
}

interface ButtonPopOverProps extends PropsWithChildren {
  buttonContent: JSX.Element
  placement: PopperPlacementType
  variant?: 'text' | 'outlined' | 'contained'
}

const ButtonPopOver: ForwardRefRenderFunction<
  ButtonPopOverHandle,
  ButtonPopOverProps
> = (
  { children, buttonContent, placement, variant = 'contained' },
  forwardedRef
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useImperativeHandle(forwardedRef, () => ({
    close: handleClose,
  }))

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'supplier-distribution-popover' : undefined

  const getMargin = (): SxProps<Theme> | null => {
    if (placement.startsWith('top')) {
      return { marginBottom: theme.spacing(1) }
    } else if (placement.startsWith('bottom')) {
      return { marginTop: theme.spacing(1) }
    }
    return null
  }
  return (
    <>
      <Button
        sx={{ display: 'block' }}
        aria-describedby={id}
        onClick={handleClick}
        variant={variant}
        data-testid="suppliers-popover-button"
      >
        {buttonContent}
      </Button>
      <Popper id={id} open={isOpen} anchorEl={anchorEl} placement={placement}>
        <ClickAwayListener onClickAway={() => handleClose()}>
          <Slide in={isOpen} direction="down">
            <Paper
              sx={{
                backgroundColor: 'white',
                ...getMargin(),
                padding: theme.spacing(2),
              }}
            >
              {children}
            </Paper>
          </Slide>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
export default forwardRef(ButtonPopOver)
