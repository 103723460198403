import { FC, useEffect, useState } from 'react'
import { locale } from '../../../locales'
import Box from '@mui/material/Box'
import { SupplierDistribution } from '../entities/Commercials'

import { Doughnut } from 'react-chartjs-2'
import { toCurrencyString } from '../../../utils/Helpers'
import { ChartData } from 'chart.js'
import { ChartPallete } from '../../../constants'
import Typography from '@mui/material/Typography'

const translation = locale.translation.AnalyticsPage.TabCommercials
const SuppliersChart: FC<{
  allSuppliers: { [key: string]: string }
  supplierDistribution: SupplierDistribution | null
  headerLabel?: string
  fixedWidth?: number
}> = ({ supplierDistribution, allSuppliers, fixedWidth, headerLabel }) => {
  const [valueChartData, setValueChartData] =
    useState<ChartData<'doughnut'> | null>(null)
  const [quantityChartData, setQuantityChartData] =
    useState<ChartData<'doughnut'> | null>(null)

  const canShowGraph =
    supplierDistribution && Object.keys(supplierDistribution).length > 0

  useEffect(() => {
    if (supplierDistribution) {
      const labels: Array<string> = []
      const valueValues: Array<number> = []
      const quantityValues: Array<number> = []
      const supplierIds = Object.keys(supplierDistribution)
      for (const supplierId of supplierIds) {
        labels.push(allSuppliers[supplierId])
        const item = supplierDistribution[supplierId]
        valueValues.push(item.totalValue ?? 0)
        quantityValues.push(item.totalQuantity ?? 0)
      }

      setValueChartData({
        labels,
        datasets: [
          {
            label: translation.TotalSpend,
            data: valueValues,
            backgroundColor: ChartPallete,
            borderWidth: 0,
            datalabels: {
              labels: {
                value: {
                  color: 'white',
                  font: {
                    size: 14,
                    weight: 'bold',
                  },
                  textAlign: 'center',
                },
              },

              formatter: (value: number, context) => {
                const percentage =
                  supplierDistribution![supplierIds[context.dataIndex]]
                    .percentageValue
                if ((percentage ?? 0) < 1) {
                  return ''
                }
                return [`${percentage}%`]
              },
            },
          },
        ],
      })
      setQuantityChartData({
        labels,
        datasets: [
          {
            label: translation.TotalVolume,
            data: quantityValues,
            backgroundColor: ChartPallete,
            borderWidth: 0,
            datalabels: {
              labels: {
                value: {
                  color: 'white',
                  font: {
                    size: 14,
                    weight: 'bold',
                  },
                },
              },
              formatter: (value: number, context) => {
                const percentage =
                  supplierDistribution[supplierIds[context.dataIndex]]
                    .percentageQuantity
                if ((percentage ?? 0) < 1) {
                  return ''
                }
                return `${percentage}%`
              },
            },
          },
        ],
      })
    }
  }, [allSuppliers, supplierDistribution])

  return (
    <Box
      data-testid="suppliers-chart-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {!canShowGraph && (
        <Box sx={{ flexGrow: 1, alignSelf: 'flex-start', textAlign: 'left' }}>
          <Typography>{locale.translation.Common.NoData}</Typography>
        </Box>
      )}
      {canShowGraph && valueChartData && quantityChartData && (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            width: fixedWidth ? `${fixedWidth}px` : null,
          }}
        >
          <Box sx={{ alignSelf: 'center' }}>
            <Typography variant="button">{headerLabel}</Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              width: { xs: '1000px', xxl: '1100px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Typography variant="subtitle1">
                {translation.TotalSpend}
              </Typography>
              <Doughnut
                data={valueChartData}
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          const value: number | null =
                            (context.raw as number) ?? null
                          return `${context.dataset.label}: ${toCurrencyString(
                            value
                          )}`
                        },
                      },
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Typography variant="subtitle1">
                {translation.TotalVolume}
              </Typography>
              <Doughnut data={quantityChartData} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
export default SuppliersChart
