import { atom, selector } from 'recoil'
import { User } from '../types/entities/User'
import { AuthenticationUser } from '../types/authentication/AuthenticationUser'
import { selectedClientState } from './SelectedPharmacyState'
import { ClientTypes } from '../types/entities/ClientPermission'

export interface FeatureFlagsForClient {
  clientId: string
  clientName: string
  clientType: string
  setFeatureFlags: string[]
}

export const authenticatedUserState = atom<AuthenticationUser | null>({
  key: 'authenticatedUserState',
  default: null,
})

export const stockrxUserState = atom<User | null>({
  key: 'stockrxUser',
  default: null,
})

export const userAvailableSuppliersState = atom<{
  [key: string]: { [key: string]: string }
}>({
  key: 'userAvailableSuppliers',
  default: {},
})

export const userPermissionsForSelectedClientState = selector<string[] | null>({
  key: 'userPermissionsForSelectedClientState',
  get: ({ get }) => {
    const user = get(stockrxUserState)
    const selectedClient = get(selectedClientState)
    if (user && selectedClient) {
      return (
        user.clientPermissions.find(
          (p) => p.clientId === selectedClient.clientId
        )?.permissions ?? []
      )
    }
    return null
  },
})

export const featureFlagsForSelectedClientState =
  selector<FeatureFlagsForClient | null>({
    key: 'featureFlagsForSelectedClientState',
    get: ({ get }) => {
      const user = get(stockrxUserState)
      const selectedClient = get(selectedClientState)

      if (user && selectedClient) {
        let featureFlags: string[] =
          user.clientPermissions.find(
            (p) => p.clientId === selectedClient.clientId
          )?.setFeatureFlags ?? []
        switch (selectedClient.clientType) {
          case ClientTypes.PharmacyGroup:
            user.clientPermissions.forEach((p) => {
              if (selectedClient.pharmacyGroupMembers?.includes(p.clientId)) {
                featureFlags = [...featureFlags, ...(p.setFeatureFlags ?? [])]
              }
            })
            break
          case ClientTypes.Company:
            user.clientPermissions.forEach((p) => {
              if (
                selectedClient.clientId === p.parentId &&
                p.clientType === ClientTypes.Pharmacy
              ) {
                featureFlags = [...featureFlags, ...(p.setFeatureFlags ?? [])]
              }
            })
            break
        }
        return {
          clientId: selectedClient.clientId,
          clientName: selectedClient.name,
          setFeatureFlags: featureFlags,
          clientType: selectedClient.clientType,
        }
      }

      return null
    },
  })
