import { FC } from 'react'
import { User } from '../../../types/entities/User'
import { locale } from '../../../locales'
import UserDetails from './UserDetails'
import {
  ClientTypes,
} from '../../../types/entities/ClientPermission'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material/styles'
import theme from '../../../styles/theme'
import EditablePermissionsListSection from './EditablePermissionsListSection'
import { useRecoilValue } from 'recoil'
import { authenticatedUserState } from '../../../state/AuthenticationState'
import Typography from '@mui/material/Typography'
import { PermissionNames } from '../../../constants'

const translation =
  locale.translation.SettingsPage.UserManagementTab.UserManagement
    .UserDetailsContainer

const permissionSectionStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}

const permissionSectionTitleStyle: SxProps<Theme> = {
  fontWeight: 600,
}

const UserDetailsContainer: FC<{
  user: User
  onUserUpdated: (user: User, updatedClientId?: string | null) => void
}> = ({ user, onUserUpdated }) => {
  const currentUser = useRecoilValue(authenticatedUserState)
  const handleUpdatedDetails = (
    sub: string,
    email: string,
    fullName: string
  ) => {
    onUserUpdated({ ...user, userName: email, fullName }, null)
  }

  const handleUpdatedPermissions = (
    clientId: string,
    permissions: string[]
  ) => {
    const clientIndex = user.clientPermissions.findIndex(
      (c) => c.clientId === clientId
    )
    if (clientIndex >= 0) {
      const allowedClientsCopy = [...user.clientPermissions]
      allowedClientsCopy[clientIndex].permissions = [...permissions]
      onUserUpdated(
        { ...user, clientPermissions: allowedClientsCopy },
        clientId
      )
    }
  }

  return (
    <>
      {currentUser && (
        <Box
          sx={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
        >
          <UserDetails
            authId={user.authId ?? ''}
            email={user.userName ?? ''}
            fullName={user.fullName ?? ''}
            onUpdatedDetails={handleUpdatedDetails}
            isUserUnique={() => true}
          />
          <Box component={'section'} sx={permissionSectionStyle}>
            <Typography variant="button" sx={permissionSectionTitleStyle}>
              {translation.CompanyPermissions}
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {user.clientPermissions
                .filter((c) => c.clientType === ClientTypes.Company)
                .map((c) => (
                  <EditablePermissionsListSection
                    disallowManagePermission={
                      currentUser.userId === user.authId &&
                      c.permissions.includes(PermissionNames.Manage)
                    }
                    showManagePermission={true}
                    key={c.clientId}
                    onCancel={() => {}}
                    onEditingChanged={() => {}}
                    onUpdate={() => {}}
                    sectionTitle={c.name}
                    updateDisabled={false}
                    clientPermission={c}
                    onUpdatedPermissions={handleUpdatedPermissions}
                  />
                ))}
            </Box>
          </Box>
          <Box component={'section'} sx={permissionSectionStyle}>
            <Typography variant="button" sx={permissionSectionTitleStyle}>
              {translation.PharmacyGroupPermissions}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {user.clientPermissions
                .filter((c) => c.clientType === ClientTypes.PharmacyGroup)
                .map((c) => (
                  <EditablePermissionsListSection
                    showManagePermission={false}
                    key={c.clientId}
                    onCancel={() => {}}
                    onEditingChanged={() => {}}
                    onUpdate={() => {}}
                    sectionTitle={c.name}
                    updateDisabled={false}
                    clientPermission={c}
                    onUpdatedPermissions={handleUpdatedPermissions}
                  />
                ))}
            </Box>
          </Box>
          <Box component={'section'} sx={permissionSectionStyle}>
            <Typography variant="button" sx={permissionSectionTitleStyle}>
              {translation.PharmacyPermissions}
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {user.clientPermissions
                .filter((c) => c.clientType === ClientTypes.Pharmacy)
                .map((c) => (
                  <EditablePermissionsListSection
                    showManagePermission={false}
                    key={c.clientId}
                    onCancel={() => {}}
                    onEditingChanged={() => {}}
                    onUpdate={() => {}}
                    sectionTitle={c.name}
                    updateDisabled={false}
                    clientPermission={c}
                    onUpdatedPermissions={handleUpdatedPermissions}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default UserDetailsContainer
