import { FC, useCallback, useContext, useEffect, useState } from 'react'
import {
  CommercialsGroupedItem,
  CommercialsItem,
  CommercialsProductDetailsResponse,
} from '../entities/Commercials'
import CommercialsProductInvoiceDetails from './CommercialsProductInvoiceDetails'
import { DateTime } from 'luxon'
import theme from '../../../styles/theme'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import { locale } from '../../../locales'
import DateRangePickerPopover from '../../../components/Forms/DateRangePickers'
import CommercialsProductPriceDetails from './CommercialsProductPriceDetails'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { useRecoilValue } from 'recoil'
import { selectedClientState } from '../../../state/SelectedPharmacyState'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import SuppliersChart from './SuppliersChart'
import {
  getDateOnlyShortMonthString,
  packSizeConverter,
} from '../../../utils/Helpers'
import MultiSelection from '../../../components/Forms/MultiSelection'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { EditDeliveryModes } from '../../../constants'
import Chip from '@mui/material/Chip'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import Button from '@mui/material/Button'
import ConfirmDialog from '../../../components/Interactions/ConfirmDialog'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import MultiDeliveryForm, {
  MultiDeliveryFormState,
} from '../../GoodsIn/components/MultiDeliveryForm'

const translation =
  locale.translation.AnalyticsPage.TabCommercials.CommercialsProductDetailsTabs
const exportTranslation =
  locale.translation.AnalyticsPage.TabCommercials.ExportToCSVModal
const CommercialsProductDetailsTabs: FC<{
  commercialsItem: CommercialsItem
  availableSuppliers: { [key: string]: string }
  odsCodeToPharmacyMappings: { [key: string]: string } | null
  intialDateFrom: DateTime
  initialDateTo: DateTime
  initialSupplierIds: string[]
  initialRollOverConcessions: boolean
  enableSupplierNetPrices: boolean
  onClosedCallback: (wereInvoicesUpdated: boolean) => void
}> = ({
  commercialsItem,
  availableSuppliers,
  odsCodeToPharmacyMappings,
  intialDateFrom,
  initialDateTo,
  initialSupplierIds,
  initialRollOverConcessions,
  enableSupplierNetPrices,
  onClosedCallback,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [dateFrom, setDateFrom] = useState<DateTime>(intialDateFrom)
  const [dateTo, setDateTo] = useState<DateTime>(initialDateTo)
  const [selectedSupplierIds, setSelectedSupplierIds] =
    useState<string[]>(initialSupplierIds)
  const [rollOverConcessions, setRollOverConcessions] = useState<boolean>(
    initialRollOverConcessions
  )
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string>('')
  const [refreshRandom, setRefreshRandom] = useState<number>(0)

  const { platformHttpService, analyticsService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue)
  }
  const [productDetails, setProductDetails] =
    useState<CommercialsProductDetailsResponse | null>(null)
  const [openInvoice, setOpenInvoice] = useState<string | null>(null)
  const [wereInvoicesUpdated, setWereInvoicesUpdated] = useState<boolean>(false)

  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false)
  const [exportErrors, setExporErrors] = useState<string | null>(null)

  const suppliersFilterTitle =
    selectedSupplierIds.length === Object.entries(availableSuppliers).length
      ? locale.translation.MultiSupplierSelection.AllSuppliers
      : locale.translation.MultiSupplierSelection.SpecificSuppliers

  const onEditDeliveryClosed = useCallback(
    (formState: MultiDeliveryFormState) => {
      if (formState.hasModifiedHeaders || formState.hasModifiedProducts) {
        setWereInvoicesUpdated(true)
        setRefreshRandom(Math.random())
      }
    },
    []
  )

  useEffect(() => {
    const getProductDetails = async () => {
      if (selectedClient) {
        const url = commercialsItem.isVmp
          ? PlatformApiPaths.GetGroupedProductsCommercialsInvoiceDetails(
              selectedClient
            )
          : PlatformApiPaths.GetProductCommercialsInvoiceDetails(
              selectedClient,
              commercialsItem.productId
            )
        return await platformHttpService.postAsync<CommercialsProductDetailsResponse>(
          url,
          {
            packSize: commercialsItem.packSize,
            dateFrom: dateFrom.toISODate(),
            dateTo: dateTo.toISODate(),
            supplierIds: selectedSupplierIds,
            rollOverConcession: rollOverConcessions,
            productIds: commercialsItem.isVmp
              ? (commercialsItem as CommercialsGroupedItem).amps!.map(
                  (a) => a.productId
                )
              : undefined,
            useSupplierNetPrices: enableSupplierNetPrices,
          },
          'StockBaseUrl'
        )
      }
    }

    setIsLoading(true)
    setError('')
    getProductDetails().then((response) => {
      analyticsService.trackEvent('analytics_product_details_opened', {
        product_id: commercialsItem.productId,
        product_name: commercialsItem.vmpName,
        pack_size: commercialsItem.packSize,
        clientId: selectedClient?.clientId,
        clientName: selectedClient?.name,
        dateFrom: dateFrom.toISODate(),
        dateTo: dateTo.toISODate(),
        hasError: !response || response.hasErrors,
      })
      if (response && !response.hasErrors) {
        setProductDetails(response.data)
      } else {
        setError(
          response?.hasErrors ? GetErrorMessage(response.statusCode) : null
        )
      }
      setIsLoading(false)
    })
  }, [
    commercialsItem,
    rollOverConcessions,
    dateFrom,
    dateTo,
    platformHttpService,
    analyticsService,
    selectedClient,
    enableSupplierNetPrices,
    selectedSupplierIds,
    refreshRandom,
  ])

  //
  const handleConfirmInvoiceDetailsExport = async () => {
    const getCommercialsExport = async () => {
      const response = await platformHttpService.postAsync<Blob>(
        PlatformApiPaths.GetProductInvoiceDetailsCSV(
          selectedClient!,
          commercialsItem!.productId
        ),
        {
          packSize: commercialsItem.packSize,
          dateFrom: dateFrom.toISODate(),
          dateTo: dateTo.toISODate(),
          supplierIds: selectedSupplierIds,
          rollOverConcession: rollOverConcessions,
          productIds: commercialsItem.isVmp
            ? (commercialsItem as CommercialsGroupedItem).amps!.map(
                (a) => a.productId
              )
            : undefined,
          useSupplierNetPrices: enableSupplierNetPrices,
        },
        'StockBaseUrl',
        undefined,
        false
      )
      return response
    }
    if (selectedClient) {
      setIsDownloading(true)
      const response = await getCommercialsExport()
      if (!response?.hasErrors) {
        const blob = await response?.rawResponse?.blob()
        if (blob) {
          window.open(window.URL.createObjectURL(blob), '_blank')
          setExportModalOpen(false)
          setIsDownloading(false)
        }
      } else {
        setExporErrors(GetErrorMessage(response.statusCode))
        setIsDownloading(false)
      }
    }
  }

  return (
    <ModalContainer
      open={true}
      onClickedClose={() => {
        onClosedCallback(wereInvoicesUpdated)
      }}
      alignSelf={'center'}
      sx={{
        minWidth: '1200px',
      }}
    >
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Box
          sx={{
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: theme.spacing(2),
            }}
          >
            <Typography variant="h5" sx={{ maxWidth: '700px' }}>
              {translation.ProductHeader(
                commercialsItem.vmpName,
                packSizeConverter(commercialsItem.packSize)
              )}
            </Typography>
            {commercialsItem.isVmp && (
              <Chip
                size="small"
                label={translation.GenericGroup}
                color={'info'}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
              height: '52px',
              justifyContent: 'flex-start',
              marginTop: theme.spacing(2),
            }}
          >
            <FormControlLabel
              sx={{ flexGrow: 1 }}
              control={
                <Switch
                  checked={rollOverConcessions}
                  onChange={() => {
                    setRollOverConcessions(!rollOverConcessions)
                  }}
                  data-testid="roll-over-concession-toggle"
                />
              }
              label={translation.PreviousConcessionPriceToggle}
            />
            <MultiSelection
              id={'multi-supplier'}
              onSelectionApplied={(s) => {
                setSelectedSupplierIds(s.sort())
              }}
              options={availableSuppliers}
              selectedOptionKeys={selectedSupplierIds}
              title={suppliersFilterTitle}
              isDirty={
                selectedSupplierIds.length !==
                Object.entries(availableSuppliers).length
              }
            />
            <DateRangePickerPopover
              dateFrom={dateFrom}
              dateTo={dateTo}
              onDatesSelected={(from, to) => {
                if (from && to) {
                  setDateFrom(from)
                  setDateTo(to)
                }
              }}
            />
            {selectedTabIndex === 2 && (
              <Button
                data-testid="export-to-csv-button"
                variant="outlined"
                disabled={
                  isDownloading || !productDetails?.invoiceDetails || isLoading
                }
                onClick={() => {
                  setExportModalOpen(true)
                }}
              >
                {isDownloading ? (
                  <CircularProgress size={20} />
                ) : (
                  <FileDownloadIcon />
                )}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: theme.spacing(2),
            }}
          >
            <Tabs value={selectedTabIndex} onChange={handleTabChange}>
              <Tab
                label={translation.Tabs.PricingDetails}
                data-testid="pricing-details-tab"
              />
              <Tab
                label={translation.Tabs.SupplierDistribution}
                data-testid="pricing-supplier-distribution-tab"
              />
              <Tab
                label={translation.Tabs.InvoiceDetails}
                data-testid="invoice-details-tab"
              />
            </Tabs>
          </Box>
          <>
            {isLoading && <CircularProgress size="24px" />}
            {error && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
              >
                <Alert variant="filled" severity="error">
                  {error}
                </Alert>
              </Box>
            )}
            {!isLoading && !error && selectedTabIndex === 0 && (
              <CommercialsProductPriceDetails
                isAverage={productDetails?.pricingDetails?.isAverage ?? false}
                productPricesPerDateEpoch={
                  productDetails?.pricingDetails?.pricesPerDateEpoch ?? null
                }
                allSuppliers={availableSuppliers}
                isVmp={commercialsItem.isVmp}
              />
            )}
            {!isLoading && !error && selectedTabIndex === 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <SuppliersChart
                  supplierDistribution={
                    productDetails?.supplierDistribution ?? null
                  }
                  allSuppliers={availableSuppliers}
                />
              </Box>
            )}
            {!isLoading && !error && selectedTabIndex === 2 && (
              <CommercialsProductInvoiceDetails
                productData={productDetails?.invoiceDetails ?? []}
                odsToPharmacyNameMappings={odsCodeToPharmacyMappings}
                selectedClient={selectedClient}
                setOpenInvoice={setOpenInvoice}
                isVmp={commercialsItem.isVmp}
              />
            )}
          </>
        </Box>
        {openInvoice && (
          <MultiDeliveryForm
            bookInsToLoad={[
              {
                bookInId: openInvoice.split('|')[0],
                odsCode: openInvoice.split('|')[1],
              },
            ]}
            editDeliveryMode={EditDeliveryModes.Analytics}
            onClosed={(formState) => {
              setOpenInvoice(null)
              onEditDeliveryClosed(formState)
            }}
          />
        )}
        {exportModalOpen && (
          <ConfirmDialog
            title={exportTranslation.Title}
            onCancel={() => {
              setExportModalOpen(false)
              setExporErrors(null)
            }}
            onOkWithLoading={() => handleConfirmInvoiceDetailsExport()}
            isLoading={error ? false : isDownloading}
            canCancel={!isDownloading}
            cancelText={exportTranslation.CancelText}
            okText={exportTranslation.ConfirmText}
            text={exportTranslation.Text(
              getDateOnlyShortMonthString(dateFrom),
              getDateOnlyShortMonthString(dateTo),
              selectedSupplierIds.length ===
                Object.keys(availableSuppliers).length
                ? exportTranslation.AllSuppliersText
                : selectedSupplierIds.length === 1
                ? availableSuppliers[selectedSupplierIds[0]]
                : exportTranslation.MultipleSuppliersText,
              productDetails?.invoiceDetails?.length
            )}
          >
            {exportErrors && (
              <Alert variant="filled" severity="error">
                {exportErrors}
              </Alert>
            )}
          </ConfirmDialog>
        )}
      </Box>
    </ModalContainer>
  )
}

export default CommercialsProductDetailsTabs
