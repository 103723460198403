// React
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

// Router
import router from './routes/router'
import { RouterProvider } from 'react-router-dom'

// Material UI
import { ThemeProvider } from '@mui/material/styles'
import theme from './styles/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { GlobalStyles } from '@mui/styled-engine'
import { configurationSettings } from './configuration/configurationSettings'

// ChartJS
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LineController,
  ScatterController,
  ArcElement,
  Colors,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chartjs-adapter-luxon'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  CategoryScale,
  LineController,
  ScatterController,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Tooltip,
  Legend,
  Colors,
  ChartDataLabels
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
document.title = 'StockRx' + configurationSettings.TitleSuffix

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles
      styles={{
        html: {
          ul: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            li: {
              listStyleType: 'none',
            },
          },
          a: {
            ':link': {
              textDecoration: 'none',
              color: theme.palette.primary.main,
            },
            ':visited': {
              textDecoration: 'none',
              color: theme.palette.primary.main,
            },
            ':hover': {
              textDecoration: 'none',
              color: theme.palette.primary.main,
            },
            ':active': {
              textDecoration: 'none',
              color: theme.palette.primary.main,
            },
          },
          '.alert-srx p': {
            margin: 0,
          },
          input: {
            '::-ms-reveal': {
              display: 'none',
            },
          },
        },
      }}
    />
    <RouterProvider router={router} />
  </ThemeProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
