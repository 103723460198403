import {
  NavigateFunction,
  Location,
  To,
  NavigateOptions,
} from 'react-router-dom'
import { INavigationService } from '../types/authentication/INavigationService'

export class NavigationService implements INavigationService {
  constructor(
    private routerNavigate: NavigateFunction,
    private location: Location
  ) {}
  navigate(to: To, options?: NavigateOptions | undefined) {
    this.routerNavigate(to, options)
  }
}
