import { FC, Fragment, useState } from 'react'

interface ImageProps {
  src: string
  alt: string
  sources?: any[]
  width?: number
  height?: number
  hasSpacer?: boolean
  imgAttributes?: any
  fadeDuration?: number
  isZoomed?: boolean
  onLoaded?: () => void
}

const Image: FC<ImageProps> = ({
  src,
  sources,
  width,
  height,
  hasSpacer,
  imgAttributes,
  isZoomed,
  fadeDuration,
  alt,
  onLoaded,
}) => {
  const createSpacer = width && height && hasSpacer
  const [loaded, setLoaded] = useState(false)

  const handleLoadComplete = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    setLoaded(true)
    onLoaded?.()
  }

  return (
    <div
      style={{
        paddingTop: createSpacer ? `${(height / width) * 100}%` : undefined,
        display: loaded ? 'block' : 'none',
      }}
    >
      {sources && sources.length > 0 ? (
        <picture>
          {sources.map((source, i) => {
            return (
              <Fragment key={i}>
                {source.srcSet && <source {...source} />}
              </Fragment>
            )
          })}
          <img
            {...imgAttributes}
            className={`iiz__img ${imgAttributes.className || ''} ${
              isZoomed ? 'iiz__img--hidden' : ''
            } ${createSpacer ? 'iiz__img--abs' : ''}`}
            style={{
              transition: `opacity 0ms linear ${
                isZoomed ? fadeDuration : 0
              }ms, visibility 0ms linear ${isZoomed ? fadeDuration : 0}ms`,
            }}
            src={src}
            width={width}
            height={height}
            alt={alt}
            onLoad={handleLoadComplete}
          />
        </picture>
      ) : (
        <img
          {...imgAttributes}
          className={`iiz__img ${imgAttributes.className || ''} ${
            isZoomed ? 'iiz__img--hidden' : ''
          } ${createSpacer ? 'iiz__img--abs' : ''}`}
          style={{
            transition: `opacity 0ms linear ${
              isZoomed ? fadeDuration : 0
            }ms, visibility 0ms linear ${isZoomed ? fadeDuration : 0}ms`,
          }}
          src={src}
          width={width}
          height={height}
          alt={alt}
          onLoad={handleLoadComplete}
        />
      )}
    </div>
  )
}

export default Image
