import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { locale } from '../../locales'
import { useRecoilState } from 'recoil'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import { Alert, Button, CircularProgress } from '@mui/material'
import { Done, Error } from '@mui/icons-material'
import PriceFileUploadPopup from './components/PriceFileUploadPopup'
import {
  getLocaleDateMedWithWeekDay,
  getLocaleDateTimeMedWithoutWeekDay,
  isoDateToEpoch,
} from '../../utils/Helpers'
import { ServiceContext } from '../../providers/ServicesProvider'
import { webSocketLastMessageState } from '../../state/WebSocketState'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { ClientTypes } from '../../types/entities/ClientPermission'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import {
  PriceFileUploadsStates,
  RecoilPageIds,
  WebSocketActions,
} from '../../constants'
import {
  PriceFileWebSocketMessage,
  UploadedPriceFile,
} from './price-file-entities'
import PriceFileDetailsPopup from './components/PriceFileDetailsPopup'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import {
  PageReducer,
  PageState,
  createRecoilPageState,
  usePageState,
} from '../PageState'
import { sortArrayByPropertyKey } from '../../utils/SortingUtils'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'

const translation = locale.translation.PriceFilesPage.TabUploads
const columnNames = translation.TableColumnTitles

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState } =
  createRecoilPageState<UploadedPriceFile>(RecoilPageIds.TabPriceFiles)
const pageReducer = new PageReducer<UploadedPriceFile>()

const statusCellNode = (v: unknown, rowData: UploadedPriceFile): ReactNode => {
  const getInfoBox = () => {
    switch (v) {
      case PriceFileUploadsStates.Processed:
        return translation.ItemProcessedSuccessfully
      case PriceFileUploadsStates.Failed:
        return translation.ItemError(rowData.failedReason)
      default:
        return translation.ItemProcessing
    }
  }
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      title={getInfoBox()}
    >
      {v === PriceFileUploadsStates.Processed && (
        <Done
          titleAccess={translation.ItemProcessedSuccessfully}
          color="success"
        />
      )}
      {v === PriceFileUploadsStates.Failed && <Error color="error" />}
      {(v === PriceFileUploadsStates.Processing ||
        v === PriceFileUploadsStates.Uploaded ||
        v === PriceFileUploadsStates.Deleting) && (
        <CircularProgress size={'24px'} />
      )}
    </Box>
  )
}

const getColumnDefinitions = (suppliers: {
  [key: string]: string
}): VirtuosoColumn<UploadedPriceFile>[] => {
  const columnDefinitions = [
    {
      label: columnNames[0],
      dataKey: 'fileOriginalName',
      widthCss: '40%',
    },
    {
      label: columnNames[1],
      dataKey: 'supplierId',
      valueTransformer: (rowData) => {
        return suppliers[rowData.supplierId]
      },
      widthCss: '10%',
    },
    {
      label: columnNames[2],
      dataKey: 'uploadedAt',
      valueTransformer: (rowData) => {
        return getLocaleDateTimeMedWithoutWeekDay(rowData.uploadedAt)
      },
      sortable: true,
      widthCss: '15%',
    },
    {
      label: columnNames[3],
      dataKey: 'priceStartDate',
      valueTransformer: (rowData) =>
        `${getLocaleDateMedWithWeekDay(
          rowData.pricesStartDate
        )} - ${getLocaleDateMedWithWeekDay(rowData.pricesEndDate)}`,
      widthCss: '30%',
    },
    {
      label: columnNames[4],
      dataKey: 'currentState',
      customCellNode: statusCellNode,
      widthCss: '72px',
    },
  ] as VirtuosoColumn<UploadedPriceFile>[]
  return columnDefinitions
}
const filterAndSort = (items: UploadedPriceFile[], state?: unknown) => {
  const sortState = (state as PageState<UploadedPriceFile>)?.sorting
  const filteredItems = [...items]
  if (sortState) {
    sortArrayByPropertyKey(
      filteredItems,
      {
        sortingType: sortState.sortingType,
        sortingPropertyKey: sortState.dataKey,
      },
      {
        uploadedAt: (propertyValue, rowValue) =>
          isoDateToEpoch(propertyValue as string | undefined),
      }
    )
  }
  return filteredItems
}

const TabPriceFiles: FC = () => {
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const { platformHttpService } = useContext(ServiceContext)
  const [selectedPriceFile, setSelectedPriceFile] =
    useState<UploadedPriceFile | null>(null)

  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )

  const { setIsLoading } = useGlobalIsLoading()

  // Handle page state for this page
  const {
    state,
    dispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    virtuosoTableHelpers,
  } = usePageState<UploadedPriceFile>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    (item) => item.uploadId,
    {
      dataKey: 'uploadedAt',
      sortingType: 'DESC',
    },
    '',
    {},
    filterAndSort
  )

  const columnDefinitions = getColumnDefinitions(state.availableSuppliers)
  useEffect(() => {
    const getPriceFileUploads = async () => {
      return await platformHttpService.getAsync<Array<UploadedPriceFile>>(
        PlatformApiPaths.GetAllUploadedPriceFiles(
          state.selectedClient!.clientId
        ),
        'SuppliersPricesBaseUrl'
      )
    }
    if (
      shouldRunLoadingEffect &&
      state.selectedClient!.clientType === ClientTypes.Company
    ) {
      setIsLoading(true)
      dispatch({ type: 'setError', payload: null })
      getPriceFileUploads().then((response) => {
        setIsLoading(false)
        if (response?.data && !response.hasErrors) {
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages: false },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
    }
  }, [
    platformHttpService,
    state.selectedClient,
    setIsLoading,
    dispatch,
    shouldRunLoadingEffect,
  ])

  useEffect(() => {
    if (
      state.selectedClient?.clientId &&
      (webSocketLastMessage?.action === WebSocketActions.PriceFileProcessed ||
        webSocketLastMessage?.action === WebSocketActions.PriceFileDeleted)
    ) {
      const { uploadId, clientId } =
        webSocketLastMessage as PriceFileWebSocketMessage

      if (clientId === state.selectedClient.clientId && uploadId) {
        switch (webSocketLastMessage?.action) {
          case WebSocketActions.PriceFileProcessed:
            const getPriceFileUploadsRequest =
              platformHttpService.getAsync<UploadedPriceFile>(
                PlatformApiPaths.GetUploadedPriceFile(
                  state.selectedClient.clientId,
                  uploadId
                ),
                'SuppliersPricesBaseUrl'
              )
            setIsLoading(false)
            getPriceFileUploadsRequest.then((response) => {
              setWebSocketLastMessage(null)
              if (response.data && !response.hasErrors) {
                const updatedPriceFileUpload = response.data
                const existingItem = state.items.find(
                  (p) => p.uploadId === uploadId
                )
                if (existingItem) {
                  dispatch({
                    type: 'replaceItems',
                    payload: [updatedPriceFileUpload],
                  })
                } else {
                  dispatch({
                    type: 'addItem',
                    payload: updatedPriceFileUpload,
                  })
                }
              } else {
                dispatch({
                  type: 'setError',
                  payload: GetErrorMessage(response.statusCode),
                })
              }
            })
            break
          case WebSocketActions.PriceFileDeleted:
            setWebSocketLastMessage(null)
            dispatch({
              type: 'deleteItem',
              payload: uploadId,
            })
            break
        }
      }
    }
  }, [
    dispatch,
    platformHttpService,
    setIsLoading,
    setWebSocketLastMessage,
    state.items,
    state.selectedClient,
    webSocketLastMessage,
  ])

  const handleCloseDetails = (wasDeleted: boolean) => {
    if (wasDeleted && selectedPriceFile) {
      selectedPriceFile.currentState = PriceFileUploadsStates.Deleting
      dispatch({ type: 'replaceItems', payload: [selectedPriceFile] })
    }
    setSelectedPriceFile(null)
  }

  const handleUploadSuccess = (newPriceFile: UploadedPriceFile) => {
    setShowUploadDialog(false)
    dispatch({ type: 'addItem', payload: newPriceFile })
  }

  return (
    state.selectedClient && (
      <Box
        sx={{
          paddingY: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: theme.spacing(2),
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setShowUploadDialog(true)}
            data-testid="show-price-file-upload-button"
          >
            {translation.UploadButton}
          </Button>
          {showUploadDialog && (
            <PriceFileUploadPopup
              selectedClient={state.selectedClient}
              onClose={() => setShowUploadDialog(false)}
              onProcessedSuccess={handleUploadSuccess}
            />
          )}
        </Box>
        {!state.error && state.filteredItems && (
          <VirtuosoMuiTable
            rows={state.filteredItems}
            columns={columnDefinitions}
            onRowClick={(row) => {
              if (row.currentState !== PriceFileUploadsStates.Processing) {
                setSelectedPriceFile(row)
              }
            }}
            sorting={state.sorting}
            onSortingChanged={(sorting) => {
              virtuosoTableHelpers.handleSortingChanged(sorting, false)
            }}
            initialTableState={virtuosoTableState.virtuosoState}
            onTableStateChanged={virtuosoTableHelpers.handleTableStateChanged}
            calculateRowSx={(row) => {
              return {
                '&:hover': {
                  cursor:
                    row.currentState === PriceFileUploadsStates.Processing
                      ? 'wait'
                      : 'pointer',
                },
              }
            }}
          />
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {selectedPriceFile && (
          <PriceFileDetailsPopup
            selectedPriceFile={selectedPriceFile}
            selectedClient={state.selectedClient}
            onClosed={handleCloseDetails}
            open={true}
          />
        )}
      </Box>
    )
  )
}

export default TabPriceFiles
