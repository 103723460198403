import Box from '@mui/material/Box'
import { FC } from 'react'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import HorizontalRule from '@mui/icons-material/HorizontalRule'
import { SxProps, Theme } from '@mui/material/styles'
import theme from '../../../styles/theme'

interface MarketChangeProps {
  value?: number
  positiveGreen: boolean
  showArrow: boolean
}

const calculateChange = (value?: number) => {
  if (value === 0) {
    return 'none'
  }
  if (value && value > 0) {
    return 'up'
  }
  if (value && value < 0) {
    return 'down'
  }
}

const boxStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
}

const PercentChange: FC<MarketChangeProps> = ({
  value,
  positiveGreen,
  showArrow,
}) => {
  return (
    <>
      {calculateChange(value) === 'up' && (
        <Box
          sx={{
            ...boxStyle,
            color: positiveGreen
              ? theme.palette.secondary.light
              : theme.palette.error.main,
          }}
        >
          {showArrow && <ArrowDropUp />}
          {`${value}%`}
        </Box>
      )}
      {calculateChange(value) === 'down' && (
        <Box
          sx={{
            ...boxStyle,
            color: !positiveGreen
              ? theme.palette.secondary.light
              : theme.palette.error.main,
            fontWeight: 600,
          }}
        >
          {showArrow && <ArrowDropDown />}
          {`${value}%`}
        </Box>
      )}
      {calculateChange(value) === 'none' && (
        <Box sx={boxStyle}>
          <HorizontalRule />
          {`${value}%`}
        </Box>
      )}
      {!calculateChange(value) && <Box>{'-'}</Box>}
    </>
  )
}

export default PercentChange
