import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material/styles'
import { FC, ReactElement } from 'react'
import theme from '../../styles/theme'

const cellStyle: SxProps<Theme> = {
  fontSize: '.95em',
  borderBottom: `1px solid ${theme.palette.divider}`,
  flex: 1,
  padding: theme.spacing(0.5),
}

const KeyValueTable: FC<{
  data: { [key: string]: string }[]
  customChild?: { [key: string]: (value: string) => ReactElement }[]
}> = ({ data, customChild }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
        borderBotton: 'none',
      }}
    >
      {data.map((d, i) => {
        return (
          <Box
            key={i}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={cellStyle}>{d.key}</Box>
            <Box
              sx={{
                ...cellStyle,
                borderLeft: `1px solid ${theme.palette.divider}`,
                fontWeight: 600,
              }}
            >
              {d.value}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default KeyValueTable
