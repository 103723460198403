import { VirtuosoColumnSorting } from '../../components/Data/VirtuosoMuiTable'
import { SupplierUniquePerId } from '../../types/entities/Supplier'
import { CreditsQueryParams, DeliverySearchQueryParams } from './recoil-state'

export const createCreditsQueryString = (
  params: CreditsQueryParams,
  suppliers?: SupplierUniquePerId[]
): string => {
  let result = ''
  if (params.dateFrom) {
    result = `${result}&invoiceDateFrom=${params.dateFrom.toISODate()}`
  }
  if (params.dateTo) {
    result = `${result}&invoiceDateTo=${params.dateTo.toISODate()}`
  }
  return `${result}`
}

export const sortingTypeToDeliverySearchQueryParams = (
  sorting: VirtuosoColumnSorting | null
) => {
  const result = {
    sort:
      sorting?.sortingType === 'ASC'
        ? 'asc'
        : sorting?.sortingType === 'DESC'
        ? 'desc'
        : undefined,
    sortField:
      sorting?.dataKey === 'invoiceDate'
        ? 'invoice_date'
        : sorting?.dataKey === 'creationDate'
        ? 'creation_date'
        : sorting?.dataKey === 'bookInHealthscore'
        ? 'health_score'
        : sorting?.dataKey === 'bookInHealthscoreReviewedDate'
        ? 'reviewed_date'
        : undefined,
  } as DeliverySearchQueryParams
  return result
}
