/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { OTLPExporterError } from '../../types'

export function sendWithFetch(
  body: string,
  url: string,
  headers: HeadersInit,
  onSuccess: () => void,
  onError: (error: OTLPExporterError) => void
) {
  fetch(url, {
    method: 'POST',
    body: body,
    headers: headers,
    keepalive: true,
  })
    .then((response) => {
      onSuccess()
    })
    .catch((error) => {
      onError(new OTLPExporterError(error.message, error.status))
    })
}
