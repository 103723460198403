import { atom } from 'recoil'
import {
  SupplierWithFinancials,
  SupplierForUpload,
  SupplierUniquePerId,
} from '../types/entities/Supplier'
export const suppliersUniquePerIdState = atom<SupplierUniquePerId[] | null>({
  key: 'suppliersUniquePerIdState',
  default: null,
})

export const suppliersIdDisplayNameDictionaryState = atom<{
  [key: string]: string
}>({
  key: 'suppliersIdDisplayNameDictionaryState',
  default: {},
})

export const suppliersForUploadState = atom<SupplierForUpload[] | null>({
  key: 'suppliersForUploadState',
  default: null,
})

export const suppliersWithVatAndMerState = atom<
  SupplierWithFinancials[] | null
>({
  key: 'suppliersWithVatAndMerState',
  default: null,
})

export const merSuppliersState = atom<SupplierWithFinancials[] | null>({
  key: 'merSuppliersState',
  default: null,
})

export const priceFilesSuppliersState = atom<SupplierUniquePerId[] | null>({
  key: 'priceFilesSuppliers',
  default: null,
})

export const selectedClientIdForInternalSuppliersState = atom<string | null>({
  key: 'selectedClientIdForInternalSuppliersState',
  default: null,
})

export const internalSuppliersState = atom<SupplierUniquePerId[]>({
  key: 'internalSuppliersState',
  default: [],
})
