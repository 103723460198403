import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { FC, ReactNode } from 'react'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { toCurrencyString } from '../../utils/Helpers'
import InfoTooltip from '../Interactions/InfoTooltip'

type MuiTableColumnTypes = 'currency' | 'component'

export interface MuiTableColumn {
  label: string
  propertyName: string
  columnType?: MuiTableColumnTypes
  customComponent?: (value: any) => ReactNode
  fontWeight?: number
  minWidth?: string | number
  width?: string | number
  valueTransformer?: (value: any) => string | number | null
  infoTooltip?: string
  calculatedCssProperties?: (value: any) => SxProps<Theme> | null
  hideRightBorder?: boolean
}

export interface MuiTableRow {
  key: string
}

const getRowPropertyValues = (
  row: MuiTableRow,
  columns: MuiTableColumn[]
): any => {
  const propertiesInColumns = columns.map((c) => c.propertyName)
  let result: any = {}
  for (const property in row) {
    if (
      property === 'key' ||
      !propertiesInColumns.find((p) => p === property)
    ) {
      continue
    }
    result = { ...result, [property]: (row as any)[property] }
  }
  return result
}

interface TableMuiProps {
  columns: MuiTableColumn[]
  data: MuiTableRow[]
  paperSx?: SxProps<Theme>
  tableMaxHeight?: string | number
  onClick?: (row: any) => void
  capitalisedHeader?: boolean
  headerHeight?: number | string
  rowCalculatedCssProperties?: (value: any) => SxProps<Theme>
  noRowsMessage?: string
  isLoading: boolean
  rowTooltip?: (row: any) => string | undefined
}

const TableMui: FC<TableMuiProps> = ({
  columns,
  data,
  tableMaxHeight,
  paperSx,
  onClick,
  capitalisedHeader,
  headerHeight,
  rowCalculatedCssProperties,
  rowTooltip,
  noRowsMessage,
  isLoading,
}) => {
  return (
    <Paper
      data-testid="static-table-container"
      elevation={0}
      sx={{
        width: '100%',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.grey[300]}`,
        ...paperSx,
      }}
    >
      <TableContainer sx={{ maxHeight: tableMaxHeight || 'auto' }}>
        <Table
          stickyHeader={true}
          aria-label="table"
          sx={{
            '& .MuiTableRow-root:last-child td': {
              borderBottom: 'none',
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                borderLeft: `1px solid ${theme.palette.grey[300]}`,
                height: headerHeight ?? 'initial',
              }}
            >
              {columns.map((c) => (
                <TableCell
                  sx={{
                    minWidth: c.minWidth,
                    width: c.width,
                    borderRight: `1px solid ${theme.palette.grey[300]}`,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    padding: theme.spacing(1),
                    backgroundColor: theme.palette.grey[50],
                    textTransform:
                      capitalisedHeader ?? false ? 'uppercase' : 'initial',
                    fontWeight: capitalisedHeader ?? false ? 500 : 600,
                    textAlign: 'center',
                  }}
                  key={c.propertyName}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alighContent: 'center',
                    }}
                  >
                    {c.label}
                    {c.infoTooltip && <InfoTooltip text={c.infoTooltip} />}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && data.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{ border: 'none', padding: 0, fontSize: '.85rem' }}
                >
                  <Box sx={{ padding: theme.spacing(2) }}>
                    {noRowsMessage ?? locale.translation.Common.NoData}
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {data.map((row) => (
              <TableRow
                title={rowTooltip?.(row)}
                key={row.key}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  },
                  borderLeft: `1px solid ${theme.palette.grey[300]}`,
                  cursor: onClick ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: onClick
                      ? theme.palette.grey[50]
                      : 'inherit',
                  },
                  ...rowCalculatedCssProperties?.(row),
                }}
                onClick={() => {
                  if (onClick) {
                    onClick(row)
                  }
                }}
              >
                {columns.map((column, index) => {
                  const values = getRowPropertyValues(row, columns)
                  let value = values[column.propertyName]

                  const style: React.CSSProperties = {
                    textAlign: 'center',
                    fontWeight: columns[index].fontWeight,
                    borderRight: columns[index].hideRightBorder
                      ? 'none'
                      : `1px solid ${theme.palette.grey[300]}`,
                    padding: theme.spacing(1),
                    minWidth: column.minWidth,
                    width: column.width,
                    color: 'inherit',
                  }

                  if (
                    column.columnType === 'currency' &&
                    !(
                      columns[index].customComponent ||
                      columns[index].valueTransformer
                    )
                  ) {
                    if (!value && value !== 0) {
                      value = '-'
                    }
                    value = toCurrencyString(value)
                  }

                  return (
                    <TableCell
                      style={style}
                      key={`${row.key}-${column.propertyName}`}
                      align="left"
                      sx={columns[index].calculatedCssProperties?.(row)}
                    >
                      {columns[index].columnType === 'component'
                        ? columns[index].customComponent?.(row)
                        : value || value === 0
                        ? columns[index].valueTransformer
                          ? columns[index].valueTransformer?.(value)
                          : value
                        : '-'}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default TableMui
