import Box from '@mui/material/Box'
import { FC } from 'react'
import theme from '../../styles/theme'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import InfoTooltip from '../Interactions/InfoTooltip'

const SwitchWithInfo: FC<{
  checked: boolean
  onChange: (checked: boolean) => void
  label: string
  testId: string
  infoTooltipText?: string
  updateDisabled?: boolean
}> = ({
  checked,
  onChange,
  label,
  testId,
  infoTooltipText,
  updateDisabled,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing(2),
        alignItems: 'center',
      }}
    >
      <FormControlLabel
        sx={{ marginRight: theme.spacing(0) }}
        control={
          <Switch
            checked={checked}
            onChange={(_, checked) => {
              onChange(checked)
            }}
            data-testid={testId}
          />
        }
        label={label}
        disabled={updateDisabled ? updateDisabled : false}
      />
      {infoTooltipText && <InfoTooltip text={infoTooltipText} />}
    </Box>
  )
}

export default SwitchWithInfo
