import { useContext, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ServiceContext } from '../providers/ServicesProvider'
import {
  authenticatedUserState,
  stockrxUserState,
  userAvailableSuppliersState,
} from '../state/AuthenticationState'
import { User } from '../types/entities/User'
import { PlatformApiPaths } from '../PlatformApiPaths'
import { useGlobalIsLoading } from './useIsLoading'

const getFlattenedClientSpecificSuppliers = (user: User) => {
  const result = {} as { [key: string]: { [key: string]: string } }
  if (user?.clientPermissions) {
    for (let clientPermission of user.clientPermissions) {
      if (clientPermission.parentId) {
        const parent = user.clientPermissions.find(
          (p) => p.clientId === clientPermission.parentId
        )
        if (parent) {
          result[clientPermission.clientId] = parent.internalSupplierIds ?? {}
        }
      } else {
        result[clientPermission.clientId] =
          clientPermission.internalSupplierIds ?? {}
      }
    }
  }

  return result
}

const useStockRxUser = () => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const authenticatedUser = useRecoilValue(authenticatedUserState)
  const setStockRxUser = useSetRecoilState(stockrxUserState)
  const setUserAvailableSuppliers = useSetRecoilState(
    userAvailableSuppliersState
  )
  const { setIsLoading } = useGlobalIsLoading()

  useEffect(() => {
    const getMe = async () => {
      return await httpService.getAsync<User>(
        PlatformApiPaths.GetMe(authenticatedUser?.bustCache ?? false),
        'UsersBaseUri'
      )
    }
    if (authenticatedUser) {
      setIsLoading(true)
      getMe().then((userResponse) => {
        if (userResponse?.data) {
          setUserAvailableSuppliers(
            getFlattenedClientSpecificSuppliers(userResponse.data)
          )
          setStockRxUser(userResponse.data)

          setIsLoading(false)
        }
      })
    } else {
      setStockRxUser(null)
    }
  }, [
    authenticatedUser,
    httpService,
    setStockRxUser,
    setIsLoading,
    setUserAvailableSuppliers,
  ])
}

export default useStockRxUser
