import { useContext, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { stockrxUserState } from '../state/AuthenticationState'
import { ServiceContext } from '../providers/ServicesProvider'

const useIntercomChat = () => {
  const stockRxUser = useRecoilValue(stockrxUserState)
  const { chatService } = useContext(ServiceContext)

  useEffect(() => {
    if (stockRxUser) {
      chatService.startLoggedInSession(
        stockRxUser.authId!,
        stockRxUser.userName!,
        stockRxUser.fullName!
      )
    } else {
      chatService.endLoggedInSession()
    }
  }, [stockRxUser, chatService])
}
export default useIntercomChat
