import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { FC, PropsWithChildren } from 'react'
import CloseModalButton from '../../components/Interactions/CloseModalButton'
import theme from '../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import Zoom from '@mui/material/Zoom'

interface ModalContainerProps extends PropsWithChildren {
  open: boolean
  onClickedClose: () => void
  alignSelf?: 'stretch' | 'center'
  sx?: SxProps<Theme>
  isNestedInModal?: boolean
  centerContent?: boolean
  title?: string
  isLoading?: boolean
  transparentBackdrop?: boolean
  onOpeningAnimationEnd?: () => void
}

const ModalContainer: FC<ModalContainerProps> = (props) => {
  return (
    <Modal
      data-testid="modal-dialog"
      open={props.open}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: props.centerContent ? 'center' : undefined,
        overflowX: 'auto',
      }}
      hideBackdrop={props.transparentBackdrop}
    >
      <Zoom
        in={props.open}
        addEndListener={(node, done) => {
          // use the css transitionend event to mark the finish of a transition
          node.addEventListener(
            'transitionend',
            () => {
              props.onOpeningAnimationEnd?.()
              if (node) {
                node.removeAllListeners?.('transitionend')
              }
              done()
            },
            false
          )
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '&:focus-visible': { outline: 'none' },
            flexGrow: 1,
            alignSelf: props.alignSelf ?? 'center',
            margin: {
              xs: props.isNestedInModal ? theme.spacing(5) : theme.spacing(3),
              xl: props.isNestedInModal ? theme.spacing(8) : theme.spacing(5),
            },
            backgroundColor: 'white',
            borderRadius: '5px',
            padding: theme.spacing(2),
            ...props.sx,
          }}
        >
          <CloseModalButton onClick={props.onClickedClose} />
          {props.children}
        </Box>
      </Zoom>
    </Modal>
  )
}

export default ModalContainer
