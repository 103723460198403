import { useCallback } from 'react'
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil'

export const globalBusyStateCounterState = atom<number>({
  key: 'globalBusyStateCounterState',
  default: 0,
})
export const isLoadingState = selector({
  key: 'isLoadingState',
  get: ({ get }) => {
    return get(globalBusyStateCounterState) > 0
  },
})

export const useGlobalIsLoading = () => {
  const setGlobalBusyStateCounter = useSetRecoilState(
    globalBusyStateCounterState
  )
  const isLoading = useRecoilValue(isLoadingState)

  const setIsLoading = useCallback(
    (isLoading: boolean) => {
      setGlobalBusyStateCounter((prev) => {
        return Math.max(isLoading ? prev + 1 : prev - 1, 0)
      })
    },
    [setGlobalBusyStateCounter]
  )

  return { isLoading, setIsLoading }
}
