import { SnackbarDismissButton } from '../components/Interactions/SnackbarDismissButton'
import { INotificationService } from '../types/INotificationService'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

export class NotificationService implements INotificationService {
  clearAllNotifictions(): void {
    closeSnackbar()
  }

  showNotification(
    message: string,
    notificationType: 'default' | 'success' | 'error' | 'warning' | 'info',
    persistSeconds?: number
  ): void {
    enqueueSnackbar({
      variant: notificationType,
      message: message,
      persist: !persistSeconds,
      autoHideDuration: persistSeconds,
      action: (snackBarId) => <SnackbarDismissButton snackBarId={snackBarId} />,
    })
  }
}
