import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { locale } from '../../locales'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { ServiceContext } from '../../providers/ServicesProvider'
import { FormType, RecoilPageIds, WebSocketActions } from '../../constants'
import theme from '../../styles/theme'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { getLocaleDateTimeMedWithoutWeekDay } from '../../utils/Helpers'
import AdjustmentForm from './components/AdjustmentForm'
import { ProductCurrentStockItem } from './entities/ProductCurrentStock'
import ModalContainer from '../../components/Interactions/ModalContainer'
import { ClientTypes } from '../../types/entities/ClientPermission'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import { webSocketLastMessageState } from '../../state/WebSocketState'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import {
  PageAction,
  PageReducer,
  PageState,
  ReducerHelpers,
  createRecoilPageState,
  usePageState,
} from '../PageState'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import ProductFilterBar, {
  ProductFilterBarState,
  InitialProductFilterBarState,
  productFilterBarFilter,
} from '../../components/Interactions/ProductFilterBar'
import SearchBox from '../../components/Forms/SearchBox'
import { textFiltering } from '../../utils/FilteringUtls'
import SwitchWithInfo from '../../components/Forms/SwitchWithInfo'

const translation = locale.translation.StockTrackingPage.TabLiveStock

// Page-specific action types
type SetProductsFilterBarStateAction = {
  type: 'setProductsFilterBarState'
  payload: ProductFilterBarState
}

type SetRollConcessionsOverAction = {
  type: 'setRollConcessionsOver'
  payload: boolean
}

type SetShowUnitialisedProductsAction = {
  type: 'setShowUnitialisedProducts'
  payload: boolean
}

type TabStockTrackingPageAction =
  | PageAction<ProductCurrentStockItem>
  | SetProductsFilterBarStateAction
  | SetRollConcessionsOverAction
  | SetShowUnitialisedProductsAction

// Page-specific state
interface TabStockTrackingPageState extends PageState<ProductCurrentStockItem> {
  productFilterBarState: ProductFilterBarState
  rollConcessionsOver: boolean
  showUninitialisedProducts: boolean
}

class TabStockTrackinPageReducer extends PageReducer<ProductCurrentStockItem> {
  reducer(
    state: TabStockTrackingPageState,
    action: TabStockTrackingPageAction
  ): TabStockTrackingPageState {
    const updateFilteredItems = () => {
      state = {
        ...state,
        filteredItems: processSortingAndFiltering(state),
      }
    }

    state = {
      ...(super.reducer(
        state,
        action as PageAction<ProductCurrentStockItem>
      ) as TabStockTrackingPageState),
    }

    switch (action.type) {
      case 'setSearchField':
        state = { ...state, searchText: action.payload.text }
        updateFilteredItems()
        break
      case 'setProductsFilterBarState':
        state = {
          ...state,
          productFilterBarState: action.payload,
        }
        updateFilteredItems()
        break
      case 'setRollConcessionsOver':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<ProductCurrentStockItem>
          ) as TabStockTrackingPageState),
          rollConcessionsOver: action.payload,
        }
        break
      case 'setShowUnitialisedProducts':
        state = {
          ...state,
          showUninitialisedProducts: action.payload,
        }
        updateFilteredItems()
        break
    }
    return state
  }
}

const processSortingAndFiltering = (state: TabStockTrackingPageState) => {
  if (state.items) {
    let items = state.items.filter((i) => {
      return textFiltering(state.searchText, i.productName)
    })
    items = productFilterBarFilter(
      state.productFilterBarState,
      items
    ) as ProductCurrentStockItem[]
    if (!state.showUninitialisedProducts) {
      items = items.filter((i) => i.hasBeenReset)
    }

    return items
  }
  return []
}

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState } =
  createRecoilPageState<ProductCurrentStockItem>(RecoilPageIds.TabStockTracking)

const pageReducer = new TabStockTrackinPageReducer()

const columnDefinitions: Array<VirtuosoColumn<ProductCurrentStockItem>> = [
  {
    dataKey: 'hasBeenReset',
    label: '',
    widthCss: '36px',
    customCellNode: (cellValue, rowData) => {
      if (!cellValue) {
        return (
          <InfoTooltip
            mode={'warning'}
            text={translation.WarningTrackingNeverReset}
            useColorWhenNotActive={true}
            tooltipSx={{ paddingX: theme.spacing(1) }}
          />
        )
      }
    },
  },
  {
    dataKey: 'productName',
    label: translation.TableColumnTitles[0],
    widthCss: '30%',
  },
  {
    dataKey: 'packSize',
    label: translation.TableColumnTitles[1],
    valueTransformer: (rowData) => {
      const value = `${rowData.packSize} ${rowData.unitOfMeasure ?? ''}`
      return rowData.subPackDescription
        ? `${value} (${rowData.subPackDescription})`
        : value
    },
  },
  {
    dataKey: 'stockPacks',
    label: translation.TableColumnTitles[2],
    infoTooltip: translation.InfoCurrentStockPacks,
  },
  {
    dataKey: 'splitPacksUnits',
    label: translation.TableColumnTitles[3],
    infoTooltip: translation.InfoSplitPackUnits,
  },
  {
    label: translation.TableColumnTitles[4],
    dataKey: 'updatedTimestamp',
    valueTransformer: (rowData) => {
      return getLocaleDateTimeMedWithoutWeekDay(rowData.updatedTimestamp)
    },
  },
]

type ProductUpdatedWebSocketMessage = {
  action?: string
  odsCode?: string
  packSize?: number
  productId?: string
}

const TabScockTracking: FC = () => {
  const { platformHttpService } = useContext(ServiceContext)

  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )

  const [selectedProduct, setSelectedProduct] =
    useState<ProductCurrentStockItem | null>(null)
  const [adjustmentOpen, setAdjustmentOpen] = useState<boolean>(false)

  const { setIsLoading } = useGlobalIsLoading()

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    virtuosoTableHelpers,
  } = usePageState<ProductCurrentStockItem>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    (item) => `${item.productId}|${item.packSize}`,
    {
      dataKey: 'productName',
      sortingType: 'DESC',
    },
    '',
    {
      productFilterBarState: InitialProductFilterBarState,
      rollConcessionsOver: false,
      showUninitialisedProducts: false,
    },
    (items, state) =>
      processSortingAndFiltering(state as TabStockTrackingPageState)
  )
  // Type conversion to page specific state
  const state = genericPageState as TabStockTrackingPageState
  const dispatch = genericDispatch as React.Dispatch<TabStockTrackingPageAction>

  const isPharmacy = state.selectedClient?.clientType === ClientTypes.Pharmacy

  const handleProductFilterBarStateChange = useCallback(
    (productFilterState: ProductFilterBarState) => {
      dispatch({
        type: 'setProductsFilterBarState',
        payload: productFilterState,
      })
    },
    [dispatch]
  )

  //closes the modals and checks the type of it
  const closeModal = (formType: string) => {
    switch (formType) {
      case FormType.Adjustment:
        setAdjustmentOpen(false)
        break
    }
  }

  const onUpdateProductCallback = useCallback(
    async (formType: string) => {
      closeModal(formType)
      setIsLoading(true)
    },
    [setIsLoading]
  )

  // Load Data from the pharmacy's stock tracking table
  useEffect(() => {
    const getData = async () => {
      return await platformHttpService.getAsync<ProductCurrentStockItem[]>(
        PlatformApiPaths.GetAllTrackedProducts(
          state.selectedClient!.clientId,
          state.rollConcessionsOver
        ),
        'StockBaseUrl'
      )
    }
    if (state.selectedClient && isPharmacy && shouldRunLoadingEffect) {
      setIsLoading(true)
      dispatch({ type: 'setError', payload: null })
      getData().then((response) => {
        setIsLoading(false)
        if (response?.data && !response.hasErrors) {
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages: false },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
        setIsLoading(false)
      })
    }
  }, [
    isPharmacy,
    platformHttpService,
    setIsLoading,
    state.selectedClient,
    dispatch,
    shouldRunLoadingEffect,
    state.rollConcessionsOver,
  ])

  // Listen to websocket messages and update the product's stock
  useEffect(() => {
    if (
      webSocketLastMessage?.action ===
      WebSocketActions.StockTrackingProductUpdated
    ) {
      const { odsCode, packSize, productId } =
        webSocketLastMessage as ProductUpdatedWebSocketMessage
      if (
        state.selectedClient?.clientId &&
        odsCode === state.selectedClient.clientId &&
        productId &&
        packSize
      ) {
        const updateProductStockRequest =
          platformHttpService.getAsync<ProductCurrentStockItem>(
            PlatformApiPaths.GetTrackedProductStock(
              state.selectedClient?.clientId!,
              productId,
              packSize,
              state.rollConcessionsOver
            ),
            'StockBaseUrl'
          )
        setIsLoading(false)
        updateProductStockRequest.then((response) => {
          setWebSocketLastMessage(null)
          if (state.items && !response.hasErrors) {
            if (response.data) {
              const existingProductIndex = state.items.find(
                (p) => p.productId === productId && p.packSize === packSize
              )
              if (existingProductIndex) {
                dispatch({
                  type: 'replaceItems',
                  payload: [response.data],
                })
              } else {
                dispatch({
                  type: 'addItem',
                  payload: response.data,
                })
              }
            } else {
              dispatch({
                type: 'setError',
                payload: GetErrorMessage(response.statusCode),
              })
            }
          }
        })
      }
    }
  }, [
    dispatch,
    platformHttpService,
    setIsLoading,
    setWebSocketLastMessage,
    state.items,
    state.rollConcessionsOver,
    state.selectedClient,
    webSocketLastMessage,
  ])

  return (
    state.selectedClient && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: theme.spacing(2),
        }}
      >
        {isPharmacy && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(1),
              }}
            >
              <SearchBox
                placeholder={translation.SearchPlaceholder}
                searchText={state.searchText}
                onTextUpdated={(text) => {
                  dispatch({
                    type: 'setSearchField',
                    payload: { text, resetPaging: false },
                  })
                }}
                testId={'search-text-field'}
              />
            </Box>
            <ProductFilterBar
              state={state.productFilterBarState}
              onProductFilterBarStateChange={handleProductFilterBarStateChange}
              hideConcessionaryStates={true}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            >
              <SwitchWithInfo
                checked={state.showUninitialisedProducts}
                onChange={() => {
                  dispatch({
                    type: 'setShowUnitialisedProducts',
                    payload: !state.showUninitialisedProducts,
                  })
                }}
                testId="use-trade-price-toggle"
                label={translation.ShowUninitialisedProductsToggle}
              />
            </Box>
            {state.error && (
              <Alert
                variant="filled"
                severity="error"
                sx={{ marginBottom: theme.spacing(1) }}
              >
                {state.error}
              </Alert>
            )}
            {!state.error && (
              <VirtuosoMuiTable
                rows={state.filteredItems ?? []}
                columns={columnDefinitions}
                onRowClick={(rowData) => {
                  setSelectedProduct(rowData as ProductCurrentStockItem)
                  setAdjustmentOpen(true)
                }}
                initialTableState={virtuosoTableState.virtuosoState}
                onTableStateChanged={
                  virtuosoTableHelpers.handleTableStateChanged
                }
              />
            )}
          </>
        )}
        {adjustmentOpen && (
          <ModalContainer
            open={adjustmentOpen}
            onClickedClose={() => {
              setAdjustmentOpen(false)
            }}
            alignSelf="center"
            sx={{
              flexGrow: 0,
              maxWidth: '500px',
            }}
            centerContent={true}
          >
            <AdjustmentForm
              selectedProductDetails={selectedProduct!}
              onSubmitCallback={() =>
                onUpdateProductCallback(FormType.Adjustment)
              }
            />
          </ModalContainer>
        )}
      </Box>
    )
  )
}

export default TabScockTracking
