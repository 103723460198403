import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material/styles'
import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react'

interface AutoSizingBoxProps extends PropsWithChildren {
  sx?: SxProps<Theme>
}

const AutoSizingBox: ForwardRefRenderFunction<
  HTMLDivElement,
  AutoSizingBoxProps
> = ({ sx, children }, forwardedRef) => {
  return (
    <Box
      ref={forwardedRef}
      sx={{
        display: 'block',
        flexGrow: 1,
        height: 0,
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default forwardRef(AutoSizingBox)
