import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { FC, useRef, useState } from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import UserManagement from './UserManagement'
import GroupsManagement from './GroupsManagement'
import PharmacySettings from './PharmacySettings'
import CompanySettings from './CompanySettings'

const translations = locale.translation.SettingsPage
const SettingsPage: FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  let shouldBustUserDetailsCache = useRef<boolean>(false)

  usePageTitle(locale.translation.Navigation.Links.Settings)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedIndex(newValue)
  }

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedIndex} onChange={handleTabChange}>
          <Tab
            label={translations.CompanySettingsTab.Title}
            data-testid="company-settings-tab"
          />
          <Tab
            label={translations.GroupsManagementTab.Title}
            data-testid="groups-management-tab"
          />
          <Tab
            label={translations.PharmacySettingsTab.Title}
            data-testid="pharmacy-settings-tab"
          />
          <Tab
            label={translations.UserManagementTab.Title}
            data-testid="users-tab"
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          paddingY: 0,
        }}
      >
        {selectedIndex === 0 && <CompanySettings />}
        {selectedIndex === 1 && (
          <GroupsManagement
            shouldBustLoadUserCacheRef={shouldBustUserDetailsCache}
          />
        )}
        {selectedIndex === 2 && <PharmacySettings />}
        {selectedIndex === 3 && (
          <UserManagement
            shouldBustLoadUserCacheRef={shouldBustUserDetailsCache}
          />
        )}
      </Box>
    </Box>
  )
}

export default SettingsPage
