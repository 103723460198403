import { atom } from 'recoil'
import { DateTime } from 'luxon'
import { PagedRequestParamsBase } from '../../utils/PagedRequestsHelper'

export const deliveriesPageSelectedTabState = atom<number>({
  key: 'deliveriesPageSelectedTabState',
  default: 0,
})

export interface DeliverySearchQueryParams extends PagedRequestParamsBase {
  recordTypeId: string | null
  invoiceNumber?: string
  dateFrom?: DateTime | null
  dateTo?: DateTime | null
  selectedSupplierIds?: string[]
  sort?: 'asc' | 'desc'
  sortField?:
    | 'invoice_date'
    | 'creation_date'
    | 'health_score'
    | 'reviewed_date'
}

export interface BookInHealthscoreSearchQueryParams
  extends DeliverySearchQueryParams {
  showPerfectBookIns?: boolean
  showReviewedBookIns?: boolean
}

// Credits
export interface CreditsQueryParams {
  dateFrom: DateTime | null
  dateTo: DateTime | null
  selectedSupplierIds: string[] | null
  filterOptions: string[]
}
