import Button from '@mui/material/Button'
import { FC, useContext } from 'react'
import { useResetRecoilState } from 'recoil'
import { locale } from '../../locales'
import { ServiceContext } from '../../providers/ServicesProvider'
import {
  authenticatedUserState,
  stockrxUserState,
} from '../../state/AuthenticationState'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'

const LogoutButton: FC = () => {
  const { authenticationService } = useContext(ServiceContext)
  const resetSelectedPharmacy = useResetRecoilState(selectedClientState)
  const resetAuthenticatedUser = useResetRecoilState(authenticatedUserState)
  const resetStockRxUser = useResetRecoilState(stockrxUserState)
  const { setIsLoading } = useGlobalIsLoading()

  const handleLogout = async () => {
    setIsLoading(true)
    await authenticationService.signOutAsync()
    resetStockRxUser()
    resetSelectedPharmacy()
    resetAuthenticatedUser()
    setIsLoading(false)
  }
  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation={true}
      sx={{ width: '100%' }}
      onClick={() => handleLogout()}
      data-testid="logout-button"
    >
      {locale.translation.Navigation.Logout}
    </Button>
  )
}

export default LogoutButton
