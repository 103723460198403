import { FC, Fragment } from 'react'

interface ZoomImageProps {
  src?: string
  fadeDuration?: number
  top?: number
  left?: number
  isZoomed?: boolean
  onLoad?: (e: any) => void
  onDragStart?: (e: any) => void
  onDragEnd?: (e: any) => void
  onClose?: (e: any) => void
  onFadeOut?: (e: any) => void
}

const ZoomImage: FC<ZoomImageProps> = ({
  src,
  fadeDuration,
  top,
  left,
  isZoomed,
  onLoad,
  onDragStart,
  onDragEnd,
  onClose,
  onFadeOut,
}) => {
  return (
    <Fragment>
      <img
        className={`iiz__zoom-img ${isZoomed ? 'iiz__zoom-img--visible' : ''}`}
        style={{
          top: top,
          left: left,
          transition: `opacity ${fadeDuration}ms linear, visibility ${fadeDuration}ms linear`,
        }}
        src={src}
        onLoad={onLoad}
        onTouchStart={onDragStart}
        onTouchEnd={onDragEnd}
        onMouseDown={onDragStart}
        onMouseUp={onDragEnd}
        onTransitionEnd={onFadeOut}
        draggable="false"
        alt=""
      />

      {onClose && (
        <button
          className={`iiz__btn iiz__close ${
            isZoomed ? 'iiz__close--visible' : ''
          }`}
          style={{
            transition: `opacity ${fadeDuration}ms linear, visibility ${fadeDuration}ms linear`,
          }}
          onClick={onClose}
          aria-label="Zoom Out"
        />
      )}
    </Fragment>
  )
}

ZoomImage.propTypes = {}

export default ZoomImage
