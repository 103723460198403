import { FC, useState } from 'react'
import { locale } from '../../locales'
import { usePageTitle } from '../../hooks/usePageTitle'
import { Box, Tabs } from '@mui/material'
import Tab from '@mui/material/Tab'
import theme from '../../styles/theme'
import TabPriceFiles from './TabPriceFiles'

const translation = locale.translation.PriceFilesPage
const PriceFilesPage: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  usePageTitle(locale.translation.Navigation.Links.PriceFiles)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue)
  }

  return (
    <Box
      sx={{
        paddingX: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTabIndex} onChange={handleTabChange}>
          <Tab label={translation.Tabs.Upload} />
        </Tabs>
      </Box>
      <TabPriceFiles />
    </Box>
  )
}

export default PriceFilesPage
