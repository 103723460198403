import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FC, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

interface MoreMenuItem {
  label: string
  onClicked: () => void
  disabled?: boolean
}

interface MenuItemProps {
  items?: MoreMenuItem[]
  sx?: SxProps<Theme>
}

const MoreMenu: FC<MenuItemProps> = ({ items, sx }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (item: MoreMenuItem) => {
    handleClose()
    item.onClicked()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getMenuItemFromLabel = (moreMenuItem: MoreMenuItem, index: number) => {
    if (moreMenuItem.label === '-') {
      return <Divider key={`divider-${index}`} />
    } else {
      return (
        <MenuItem
          key={moreMenuItem.label}
          onClick={() => handleItemClick(moreMenuItem)}
          disabled={Boolean(moreMenuItem.disabled)}
        >
          {moreMenuItem.label}
        </MenuItem>
      )
    }
  }

  return (
    <Box sx={{ ...sx }}>
      <Button
        onClick={handleClick}
        sx={{ paddingX: 0, minWidth: 0 }}
        data-testid="more-button"
      >
        <MoreHorizIcon />
      </Button>
      {items && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={2}
        >
          {items.map((item, index) => getMenuItemFromLabel(item, index))}
        </Menu>
      )}
    </Box>
  )
}
export default MoreMenu
