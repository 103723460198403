import Box from '@mui/material/Box'
import { FC, useEffect, useState } from 'react'
import theme from '../../../styles/theme'
import TextField from '@mui/material/TextField'
import { SxProps, Theme } from '@mui/material/styles'
import EditableSection from './EditableSection'
import { locale } from '../../../locales'

const translations =
  locale.translation.SettingsPage.GroupsManagementTab.GroupsManagement
    .GroupDetailsContainer

const editFieldTextFieldStyle: SxProps<Theme> = {
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

interface EditGroupNameSectionProps {
  groupName: string
  onGroupNameUpdated: (groupName: string) => void
  onEditingChanged?: (editing: boolean) => void
  onCancel?: () => void
  autoEdit?: boolean
  isEditing?: boolean
  isGroupNameUnique: (groupName: string) => boolean
}

const EditGroupNameSection: FC<EditGroupNameSectionProps> = (props) => {
  const [editingName, setEditingName] = useState<boolean>(false)
  const [groupName, setGroupName] = useState<string>('')
  const [nameError, setNameError] = useState<string | null>(
    props.autoEdit ? translations.ErrorGroupName : null
  )

  useEffect(() => {
    if (props.isEditing != null) {
      setEditingName(props.isEditing)
    }
  }, [props.isEditing])

  useEffect(() => {
    setGroupName(props.groupName)
  }, [props.groupName])

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value
    setGroupName(newName)
    if (newName.length < 4) {
      setNameError(translations.ErrorGroupName)
    } else if (props.isGroupNameUnique(newName)) {
      setNameError(null)
    } else {
      setNameError(translations.ErrorGroupNameNotUnique)
    }
  }

  return (
    <EditableSection
      sectionTitle={translations.GroupName}
      updateDisabled={Boolean(nameError)}
      onUpdate={() => {
        props.onGroupNameUpdated(groupName)
      }}
      onCancel={() => {
        setGroupName(props.groupName)
        setNameError(null)
        props.onCancel?.()
      }}
      onEditingChanged={(e) => {
        setEditingName(e)
        props.onEditingChanged?.(e)
      }}
      autoEdit={Boolean(props.autoEdit)}
      isEditing={props.isEditing}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          paddingY: theme.spacing(1),
        }}
      >
        <TextField
          sx={{ ...editFieldTextFieldStyle }}
          label={translations.GroupName}
          value={groupName}
          onChange={handleGroupNameChange}
          disabled={!editingName && !Boolean(props.autoEdit)}
          error={Boolean(nameError)}
          helperText={nameError}
          autoComplete="off"
          variant="outlined"
        />
      </Box>
    </EditableSection>
  )
}
export default EditGroupNameSection
