import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import { FC } from 'react'
import * as yup from 'yup'
import { locale } from '../../locales'
import theme from '../../styles/theme'

const forgotPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .required(
      locale.translation.ForgotPasswordForm.Fields.Email.Validation.Required
    ),
})

export const ForgotPasswordFragment: FC<{
  cardStyle: SxProps<Theme>
  handleSubmitEmail: (email: string) => void
  errorEmail: string | null
}> = ({ cardStyle, handleSubmitEmail: handleSubmit, errorEmail: error }) => {
  const forgotPasswordEmailFormikConfig = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values.email)
    },
  })

  return (
    <form
      onSubmit={forgotPasswordEmailFormikConfig.handleSubmit}
      data-testid="forgot-password-form"
    >
      <Card variant="outlined" sx={cardStyle}>
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {locale.translation.ForgotPasswordForm.Title}
            </Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              label={locale.translation.ForgotPasswordForm.Fields.Email.Label}
              placeholder={
                locale.translation.ForgotPasswordForm.Fields.Email.Placeholder
              }
              value={forgotPasswordEmailFormikConfig.values.email}
              onChange={forgotPasswordEmailFormikConfig.handleChange}
              error={
                forgotPasswordEmailFormikConfig.touched.email &&
                Boolean(forgotPasswordEmailFormikConfig.errors.email)
              }
              helperText={
                forgotPasswordEmailFormikConfig.touched.email &&
                forgotPasswordEmailFormikConfig.errors.email
              }
            />
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disableElevation={true}
          >
            {locale.translation.ForgotPasswordForm.Buttons.Submit}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
