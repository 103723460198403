import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FC } from 'react'
import { NumericFormat } from 'react-number-format'
import { locale } from '../../locales'

type CurrencyInputProps = TextFieldProps & {
  value: number | null
  onValueChangedCallback: (value: number | null) => void
}

const CurrencyInput: FC<CurrencyInputProps> = (props) => {
  return (
    <NumericFormat
      data-testid="currency-input"
      customInput={TextField}
      label={props.label}
      thousandSeparator={locale.thousandSeparator}
      decimalSeparator={locale.decimalSeparator}
      decimalScale={2}
      fixedDecimalScale
      prefix={locale.currencySymbol}
      allowNegative={false}
      onValueChange={(v) => {
        const value = parseFloat(v.value)
        props.onValueChangedCallback(isNaN(value) ? null : value)
      }}
      autoComplete="off"
      value={props.value}
      disabled={props.disabled}
      size={props.size}
      fullWidth={props.fullWidth}
      InputProps={{ sx: { '& input': { textAlign: 'right' } } }}
      error={props.error}
      helperText={props.helperText}
      sx={props.sx}
      onBlur={props.onBlur}
    />
  )
}

export default CurrencyInput
