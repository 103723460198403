import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import Chip from '@mui/material/Chip'
import theme from '../../styles/theme'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import InputBase from '@mui/material/InputBase'
import FormHelperText from '@mui/material/FormHelperText'

export interface InputSectionWithTagsHandle {
  clearValue: () => void
  getTags: () => string[]
}

interface InputSectionWithTagsProps {
  contentKey: string
  initialTags: string[]
  label: string
  placeholder?: string
  validationSchema: yup.AnySchema
  errorMessage: string
  isEditing: boolean
  onInvalidTagStateChange: (hasInvalid: boolean) => void
}

interface FormValues {
  tags: string[]
}

const InputSectionWithTags: ForwardRefRenderFunction<
  InputSectionWithTagsHandle,
  InputSectionWithTagsProps
> = (
  {
    contentKey,
    initialTags,
    label,
    placeholder = '',
    validationSchema,
    errorMessage,
    isEditing = false,
    onInvalidTagStateChange,
  },
  forwardedRef
) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const [foregroundColor, setForegroundColor] = useState<string>(
    theme.palette.grey[400]
  )

  useImperativeHandle(forwardedRef, () => ({
    clearValue: () => {
      setInputValue('')
      formik.resetForm()
    },
    getTags: () => formik.values.tags,
  }))

  const formik = useFormik<FormValues>({
    initialValues: {
      tags: initialTags,
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: yup.object({
      tags: yup.array().of(validationSchema),
    }),
  })

  const tagIsValid = useCallback(
    (tag: string) => {
      const tagIndex = formik.values.tags.indexOf(tag)
      return !Boolean(formik.errors?.tags?.[tagIndex])
    },
    [formik.errors.tags, formik.values.tags]
  )

  const hasInvalidTags = useCallback(
    () => formik.values.tags?.some((tag) => !tagIsValid(tag)),
    [formik.values.tags, tagIsValid]
  )

  useEffect(() => {
    setInputValue('')
  }, [contentKey])

  useEffect(() => {
    onInvalidTagStateChange(inputValue.length > 0 || hasInvalidTags())
  }, [inputValue, onInvalidTagStateChange, hasInvalidTags])

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    let newTags: string[] | null = null
    if (['Enter', 'Tab', ' '].includes(event.key)) {
      event.preventDefault()
      const value = inputValue.trim()
      if (value && !(formik.values.tags?.includes(value) ?? false)) {
        newTags = [...(formik.values.tags ?? []), value]
        setInputValue('')
      }
    } else if (event.key === 'Backspace' && inputValue.length === 0) {
      newTags = formik.values.tags.slice(0, -1)
    }
    if (newTags) {
      await formik.setFieldValue('tags', newTags)
    }
  }

  const handleDelete = async (tagToDelete: string) => {
    await formik.setFieldValue(
      'tags',
      formik.values.tags.filter((tag) => tag !== tagToDelete)
    )
  }

  useEffect(() => {
    const getForegroundColor = () => {
      if (formik.errors.tags) {
        return `${theme.palette.error.main}!important`
      } else if (isInputFocused) {
        return theme.palette.primary.main
      } else {
        return theme.palette.grey[400]
      }
    }
    setForegroundColor(getForegroundColor())
  }, [formik.errors.tags, isInputFocused])

  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingY: theme.spacing(1),
      }}
    >
      <FormControl
        color="primary"
        sx={{
          borderWidth: isInputFocused ? '2px' : '1px',
          borderStyle: 'solid',
          borderRadius: '4px',
          borderColor: foregroundColor,
          padding: theme.spacing(1),
          backgroundColor: theme.palette.common.white,
        }}
        onClick={() => {
          inputRef.current?.focus()
        }}
      >
        <InputLabel
          shrink={Boolean((formik.values.tags?.length ?? 0) > 0) || undefined}
          sx={{
            backgroundColor: theme.palette.common.white,
            paddingX: theme.spacing(0.5),
            color: foregroundColor,
          }}
          disabled={!isEditing}
        >
          {label}
        </InputLabel>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
          }}
        >
          {formik.values.tags?.map((tag, index) => (
            <Chip
              key={index}
              color={tagIsValid(tag) ? 'info' : 'error'}
              label={tag}
              onDelete={() => handleDelete(tag)}
              disabled={!isEditing}
              sx={{ alignSelf: 'center' }}
            />
          ))}
          <InputBase
            inputRef={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            disabled={!isEditing}
            placeholder={placeholder}
            sx={{ paddingY: theme.spacing(0.5), flex: '1 1 auto' }}
          />
        </Box>
      </FormControl>
      {hasInvalidTags() && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  )
}

export default forwardRef(InputSectionWithTags)
