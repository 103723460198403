import { FC, useState } from 'react'
import { MonthEndReconciliationDocument } from '../entities/mer-entities'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import { SxProps, Theme } from '@mui/material/styles'
import { MonthEndReconciliationDocumentTypes } from '../../../constants'
import {
  getLocaleDateMedWithoutWeekDay,
  toCurrencyString,
} from '../../../utils/Helpers'
import theme from '../../../styles/theme'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { SupplierWithFinancials } from '../../../types/entities/Supplier'

const translation =
  locale.translation.MerPage.MonthEndReconciliationAnalysisForm

const rowStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

const MonthEndReconciliationExcludePopup: FC<{
  odsCode: string
  merId: string
  merDocument: MonthEndReconciliationDocument
  merSupplier: SupplierWithFinancials
  isExcludedInitialState: boolean
  onClose: (
    shouldReProcess: boolean,
    documentLineNumber: number | null,
    isExcluded: boolean | null,
    note: string | null
  ) => void
}> = ({
  odsCode,
  merId,
  merDocument,
  merSupplier,
  isExcludedInitialState,
  onClose,
}) => {
  const translationTable = MonthEndReconciliationDocumentTypes.Invoice
    ? translation.InvoiceAnalysisTable
    : translation.CreditAnalysisTable

  const [excluded, setExcluded] = useState<boolean>(isExcludedInitialState)
  const [note, setNote] = useState<string | null>(merDocument.optionsNote)

  return (
    <ModalContainer
      open={Boolean(merDocument)}
      onClickedClose={() => onClose(false, null, null, null)}
      centerContent={true}
      alignSelf={'center'}
      sx={{ width: '500px', flexGrow: 0 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
          {translation.EditDocumentExclusion}
        </Typography>
        <Box sx={{ ...rowStyle }}>
          <Typography>
            {`${translationTable.StatementInvoiceNumber}: `}
            <Box component="span" sx={{ fontWeight: 600 }}>
              {merDocument.documentId}
            </Box>
          </Typography>
          <Typography>
            {`${translationTable.StatementInvoiceAmount}: `}
            <Box component="span" sx={{ fontWeight: 600 }}>
              {toCurrencyString(
                merSupplier?.requiresVatForMer
                  ? merDocument.documentGrossAmount
                  : merDocument.documentNetAmount
              )}
            </Box>
          </Typography>
          <Typography>
            {`${translationTable.StatementInvoiceDate}: `}
            <Box component="span" sx={{ fontWeight: 600 }}>
              {getLocaleDateMedWithoutWeekDay(merDocument.documentDate) ?? ''}
            </Box>
          </Typography>
          <Typography>
            {`${translationTable.MatchedBookedInInvoiceNumber}: `}
            <Box component="span" sx={{ fontWeight: 600 }}>
              {merDocument.systemDocumentId ?? '-'}
            </Box>
          </Typography>
          <Typography>
            {`${translationTable.MatchedBookedInInvoiceAmount}: `}
            <Box component="span" sx={{ fontWeight: 600 }}>
              {toCurrencyString(merDocument.systemDocumentTotalPrice) ?? '-'}
            </Box>
          </Typography>
          <FormControlLabel
            id="exclude-checkbox"
            sx={{ marginTop: theme.spacing(2) }}
            control={
              <Checkbox
                checked={excluded}
                onChange={() => setExcluded(!excluded)}
                name="excludedCheckbox"
              />
            }
            label={translation.ExcludeThisDocument}
          />
          <TextField
            data-testid="exlusion-notes-text"
            label={translation.LabelNotes}
            multiline
            fullWidth
            rows={5}
            value={note ?? ''}
            name="notes"
            onChange={(e) => setNote(e.target.value.substring(0, 250))}
          />
          <LoadingButton
            data-testid="apply-exclusion-button"
            disableElevation
            variant="contained"
            color="primary"
            disabled={excluded && (!Boolean(note) || note?.length === 0)}
            sx={{ marginTop: theme.spacing(4) }}
            onClick={() =>
              onClose(true, merDocument.documentLineNumber, excluded, note)
            }
          >
            {translation.ApplyButton}
          </LoadingButton>
        </Box>
      </Box>
    </ModalContainer>
  )
}

export default MonthEndReconciliationExcludePopup
