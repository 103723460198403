import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import theme from '../../../styles/theme'
import Button from '@mui/material/Button'
import { locale } from '../../../locales'
import useDebounce from '../../../hooks/useDebounce'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { UnlistedProductId } from '../../../constants'
import { Product, ProductPackSize } from '../entities/Product'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import Paper from '@mui/material/Paper'
import ButtonBase from '@mui/material/ButtonBase'
import { uniqBy } from 'lodash'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import CloseModalButton from '../../../components/Interactions/CloseModalButton'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import { packSizeConverter } from '../../../utils/Helpers'
import ClearIcon from '@mui/icons-material/Clear'
import { Virtuoso } from 'react-virtuoso'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { SelectProductModalProduct } from '../entities/Product'

const translation =
  locale.translation.GoodsInPage.MultiDeliveryForm.MultiDeliveryFormProduct
    .SelectProductModal

interface PackSizeInfo {
  packSize: number
  unitOfMeasure: string | null
  subPackDescription: string | null
}

interface State {
  pageIndex: number
  filterText: string
  ocrText: string
  pagesEnded: boolean
  productList: Product[]
  packSizeList: PackSizeInfo[]
  selectedProduct: Product | null
  isLoading: boolean
  initialProduct: Product | null
  intialPackSize: number | null
  selectedPackSize: number | null
  showUnlistedProduct: boolean
  isPackSizeOptional: boolean
}

interface UpdateFilterTextAction {
  type: 'updateFilterText'
  payload: string
}

interface SetNextPageAction {
  type: 'setNextPage'
  payload: number | null
}

interface AppendProductListAction {
  type: 'appendProductList'
  payload: Product[]
}

interface SetSelectedProductAction {
  type: 'setSelectedProduct'
  payload: Product
}

interface SetIsLoadingAction {
  type: 'setIsLoading'
  payload: boolean
}

interface SetAvailablePackSizesAction {
  type: 'setAvailablePackSizes'
  payload: PackSizeInfo[]
}

interface SetSelectedPackSizeAction {
  type: 'setSelectedPackSize'
  payload: number | null
}

interface SetUnlistedProductAction {
  type: 'setUnlistedProduct'
  payload: null
}

type Action =
  | UpdateFilterTextAction
  | SetNextPageAction
  | AppendProductListAction
  | SetSelectedProductAction
  | SetIsLoadingAction
  | SetAvailablePackSizesAction
  | SetSelectedPackSizeAction
  | SetUnlistedProductAction

const noProductsPlaceholder = 'NO_PRODUCTS_PLACEHOLDER'

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateFilterText':
      const filterText = action.payload as string
      return {
        ...state,
        pageIndex: 0,
        filterText: filterText,
        productList: [],
      }
    case 'appendProductList':
      const loadedProducts = action.payload as Product[]
      const pagesEnded = loadedProducts.length === 0

      let nextProducts = [...state.productList, ...loadedProducts]
      if (pagesEnded) {
        if (state.showUnlistedProduct) {
          nextProducts = [
            ...nextProducts,
            {
              productUuid: UnlistedProductId,
              productName: UnlistedProductId,
            },
          ]
        } else if (nextProducts.length === 0) {
          nextProducts = [
            {
              productUuid: noProductsPlaceholder,
              productName: noProductsPlaceholder,
            },
          ]
        }
      }

      if (state.initialProduct) {
        nextProducts = [state.initialProduct, ...nextProducts]
      }
      nextProducts = uniqBy(nextProducts, (p) => p.productUuid)

      let selectedProduct = state.selectedProduct
      if (
        state.selectedProduct?.productUuid &&
        state.initialProduct &&
        !nextProducts.find(
          (p) => p.productUuid === state.selectedProduct?.productUuid
        )
      ) {
        selectedProduct = state.initialProduct
      }

      return {
        ...state,
        selectedProduct: selectedProduct,
        productList: nextProducts,
        pagesEnded: loadedProducts.length === 0,
      }
    case 'setSelectedProduct':
      return {
        ...state,
        selectedProduct: action.payload as Product,
        selectedPackSize: null,
      }
    case 'setIsLoading':
      return { ...state, isLoading: action.payload as boolean }
    case 'setNextPage':
      return { ...state, pageIndex: state.pageIndex + 1 }
    case 'setAvailablePackSizes':
      const packSizes = action.payload as PackSizeInfo[]
      let selectedPacksize = packSizes.length === 1 ? packSizes[0] : null
      if (
        state.selectedProduct?.productUuid &&
        state.initialProduct?.productUuid
      ) {
        if (
          state.selectedProduct.productUuid === state.initialProduct.productUuid
        ) {
          selectedPacksize =
            packSizes.find((ps) => ps.packSize === state.intialPackSize) ?? null
        }
      }

      return {
        ...state,
        packSizeList: packSizes,
        selectedPackSize: selectedPacksize?.packSize ?? null,
      }
    case 'setSelectedPackSize':
      return { ...state, selectedPackSize: action.payload as number }
    case 'setUnlistedProduct':
      return {
        ...state,
        packSizeList: [
          {
            packSize: 1,
            unitOfMeasure: null,
            subPackDescription: null,
          },
        ],
        selectedPackSize: 1,
        selectedProduct: {
          productName: translation.UnlistedProductName,
          productUuid: UnlistedProductId,
        },
      }
    default:
      return state
  }
}

export const processPackSizes = (
  packSizes: ProductPackSize[]
): PackSizeInfo[] => {
  return packSizes
    .map((r) => {
      return {
        packSize: r.packSize,
        unitOfMeasure: r.unitOfMeasure ?? null,
        subPackDescription: r.subPackDescription ?? null,
      }
    })
    .sort((a, b) => {
      if (b.packSize < 0) {
        return -1
      } else if (a.packSize < 0) {
        return 1
      }
      return 0
    })
}

const createSearchQuery = (
  productName: string,
  pageIndex: number,
  ocrProductName: string | null,
  dataSource?: string
) => {
  const ocrProductNameToUse = productName ? '' : ocrProductName
  const searchQuery = [
    `productName=${encodeURIComponent(productName)}`,
    `&pageIndex=${pageIndex}`,
    `&rawOcrProductName=${encodeURIComponent(ocrProductNameToUse ?? '')}`,
    Boolean(dataSource) ? `&dataSource=${encodeURIComponent(dataSource!)}` : '',
  ]
  return searchQuery.join('')
}

interface SelectProductModalClosedCallback {
  productId: string
  packSize: number
  productName: string
  unitOfMeasure: string | null
  subPackDescription: string | null
}

interface SelectProductModalProps {
  product: SelectProductModalProduct
  onClosedCallback: (
    selectedProduct: SelectProductModalClosedCallback | null
  ) => void
  ignoreSpecials: boolean
  showUnlistedProduct: boolean
  dataSource?: string
  packSizeOptional?: boolean
}

const SelectProductModal: FC<SelectProductModalProps> = ({
  product,
  onClosedCallback,
  ignoreSpecials = false,
  showUnlistedProduct = true,
  dataSource,
  packSizeOptional = false,
}) => {
  const serviceContext = useContext(ServiceContext)
  const httpService = serviceContext.platformHttpService
  const [open, setOpen] = useState<boolean>(true)
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false)
  const [isLoadingPacks, setIsLoadingPacks] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [state, dispatch] = useReducer(reducer, {
    pageIndex: 0,
    filterText: '',
    ocrText: product.productRawOcrName ?? '',
    productList: [],
    selectedProduct:
      product?.productId && product.productId !== UnlistedProductId
        ? {
            productUuid: product.productId,
            productName: product.productName,
          }
        : null,
    isLoading: false,
    pagesEnded: false,
    initialProduct:
      product?.productId && product.productId !== UnlistedProductId
        ? {
            productUuid: product.productId,
            productName: product.productName,
          }
        : null,
    intialPackSize: product?.packSize,
    packSizeList: [],
    selectedPackSize: product.packSize,
    showUnlistedProduct,
    isPackSizeOptional: packSizeOptional,
  })
  const [searchQuery, setSearchQuery] = useState<string>(
    createSearchQuery('', 0, product.productRawOcrName)
  )
  const [searchFilterText, setSearchFilterText] = useState('')
  const debouncedSearchFilterText = useDebounce(searchFilterText, 500)

  const onClosed = useCallback(() => {
    setOpen(false)
    onClosedCallback(null)
  }, [onClosedCallback])

  const onSelected = useCallback(() => {
    if (
      state.selectedProduct?.productUuid &&
      state.selectedProduct?.productName &&
      (state.isPackSizeOptional ? true : state.selectedPackSize)
    ) {
      setOpen(false)
      const selectedPackSizeInfo = state.packSizeList.find(
        (p) => p.packSize === state.selectedPackSize
      )
      onClosedCallback({
        packSize: state.selectedPackSize ?? 0,
        productId: state.selectedProduct?.productUuid,
        productName: state.selectedProduct?.productName,
        unitOfMeasure: selectedPackSizeInfo?.unitOfMeasure ?? null,
        subPackDescription: selectedPackSizeInfo?.subPackDescription ?? null,
      })
    }
  }, [
    onClosedCallback,
    state.selectedPackSize,
    state.selectedProduct,
    state.packSizeList,
    state.isPackSizeOptional,
  ])

  useEffect(() => {
    dispatch({
      type: 'updateFilterText',
      payload: debouncedSearchFilterText ?? '',
    })
  }, [debouncedSearchFilterText])

  useEffect(() => {
    setSearchQuery(
      createSearchQuery(
        state.filterText,
        state.pageIndex,
        state.ocrText,
        dataSource
      )
    )
  }, [state.filterText, state.pageIndex, state.ocrText, dataSource])

  useEffect(() => {
    const productsSearch = async (query: string) => {
      return await httpService.getAsync<Product[]>(
        PlatformApiPaths.ProductSearch(query),
        'ProductsBaseUrl'
      )
    }
    setIsLoadingProducts(true)
    productsSearch(searchQuery).then((response) => {
      setError(null)
      setIsLoadingProducts(false)
      dispatch({ type: 'setIsLoading', payload: false })
      if (!response.hasErrors) {
        dispatch({ type: 'appendProductList', payload: response.data ?? [] })
      } else {
        setError(GetErrorMessage(response.statusCode))
      }
    })
  }, [searchQuery, httpService])

  useEffect(() => {
    const getPackSizes = async (productUuid: string) => {
      return await httpService.getAsync<ProductPackSize[]>(
        PlatformApiPaths.GetProductPacksById(productUuid),
        'ProductsBaseUrl'
      )
    }

    if (state.selectedProduct?.productName) {
      setError(null)
      setIsLoadingPacks(true)
      getPackSizes(state.selectedProduct?.productUuid).then((response) => {
        setIsLoadingPacks(false)
        if (!response.hasErrors) {
          if (response?.data) {
            let packSizesList = !ignoreSpecials
              ? processPackSizes(response.data)
              : processPackSizes(response.data).filter((p) => p.packSize > 0)
            if (state.isPackSizeOptional) {
              packSizesList = [
                {
                  packSize: 0,
                  unitOfMeasure: null,
                  subPackDescription: null,
                },
                ...packSizesList,
              ]
            }
            dispatch({
              type: 'setAvailablePackSizes',
              payload: packSizesList,
            })
            if (state.isPackSizeOptional) {
              dispatch({ type: 'setSelectedPackSize', payload: 0 })
            }
          }
        } else {
          setError(GetErrorMessage(response.statusCode))
        }
      })
    }
  }, [
    state.selectedProduct,
    httpService,
    ignoreSpecials,
    state.isPackSizeOptional,
  ])

  const isValidSelection = () => {
    return state.selectedProduct?.productName && state.isPackSizeOptional
      ? true
      : state.selectedPackSize
  }

  const isTextInSearchField = searchFilterText.length > 0

  const handleClearText = () => {
    setSearchFilterText('')
  }

  const getSelectButtonText = () => {
    if (isValidSelection()) {
      if (state.selectedProduct?.productUuid) {
        if (state.selectedProduct.productUuid !== UnlistedProductId) {
          return `${state.selectedProduct.productName} ${
            state.selectedPackSize
              ? translation.SelectButtonPackSize(
                  packSizeConverter(state.selectedPackSize)
                )
              : ''
          }`
        } else {
          return state.selectedProduct.productName
        }
      }
    } else {
      return translation.PleaseSelectAProductAndAPackSize(
        state.isPackSizeOptional
      )
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '5px',
          padding: theme.spacing(2),
          justifyContent: 'center',
          width: '640px',
          height: '640px',
          gap: theme.spacing(1),
          '&:focus-visible': { outline: 'none' },
        }}
      >
        <CloseModalButton onClick={onClosed} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: theme.spacing(1),
          }}
        >
          {error && (
            <Alert
              variant="filled"
              sx={{ alignItems: 'center' }}
              severity="error"
            >
              {error}
            </Alert>
          )}
          <TextField
            data-testid="products-popup-search-field"
            fullWidth
            autoComplete="off"
            value={searchFilterText}
            onChange={(e) => setSearchFilterText(e.target.value ?? '')}
            placeholder={translation.SearchProductPlaceholder}
            InputProps={{
              endAdornment: isTextInSearchField && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearText}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Box
            sx={{
              marginTop: theme.spacing(2),
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '1em' }}>
              {translation.SelectAProduct}
            </Typography>
            {isLoadingProducts && <CircularProgress size="24px" />}
          </Box>
          <Paper
            data-testid="products-popup-products-list"
            component="ul"
            variant="outlined"
            sx={{
              flexGrow: 1,
              marginBottom: theme.spacing(1),
            }}
          >
            <Virtuoso
              data={state.productList}
              itemContent={(index) => {
                const product = state.productList[index]
                const isNoProductsPlaceholder =
                  product.productUuid === noProductsPlaceholder
                return (
                  <>
                    {product.productUuid !== UnlistedProductId &&
                      !isNoProductsPlaceholder && (
                        <Box sx={{ paddingX: theme.spacing(1) }}>
                          <ButtonBase
                            disableRipple
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            onClick={() =>
                              dispatch({
                                type: 'setSelectedProduct',
                                payload: product,
                              })
                            }
                          >
                            <Box
                              component="label"
                              sx={{ cursor: 'pointer', textAlign: 'left' }}
                            >
                              <Typography sx={{ cursor: 'pointer' }}>
                                {product.productName}
                              </Typography>
                            </Box>
                            <Radio
                              sx={{ cursor: 'inherit' }}
                              checked={
                                state.selectedProduct?.productUuid ===
                                product.productUuid
                              }
                            />
                          </ButtonBase>
                        </Box>
                      )}
                    {product.productUuid === UnlistedProductId && (
                      <Box sx={{ padding: theme.spacing(1) }}>
                        <Button
                          data-testid="products-popup-unlisted-product-button"
                          disableElevation
                          variant="contained"
                          fullWidth
                          color="warning"
                          sx={{ color: 'white' }}
                          onClick={() =>
                            dispatch({
                              type: 'setUnlistedProduct',
                              payload: null,
                            })
                          }
                        >
                          {translation.NotListed}
                        </Button>
                      </Box>
                    )}
                    {!showUnlistedProduct &&
                      state.productList.length === 1 &&
                      state.productList[0].productUuid ===
                        noProductsPlaceholder && (
                        <Box sx={{ padding: theme.spacing(1) }}>
                          {translation.ProductNotFound}
                        </Box>
                      )}
                  </>
                )
              }}
              endReached={() => {
                if (!state.pagesEnded) {
                  dispatch({ type: 'setNextPage', payload: null })
                }
              }}
            />
          </Paper>
        </Box>
        {state.selectedProduct?.productName &&
          state.selectedProduct.productUuid !== UnlistedProductId && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(1),
                alignItems: 'center',
                marginBottom: theme.spacing(1),
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '1em' }}>
                {translation.SelectAPacksize(state.selectedProduct.productName)}
              </Typography>
              <Select
                data-testid="products-popup-packsize-select"
                size="small"
                value={state.selectedPackSize ?? ''}
                onChange={(e) =>
                  dispatch({
                    type: 'setSelectedPackSize',
                    payload: Number(e.target.value),
                  })
                }
              >
                {state.packSizeList.map((ps) => (
                  <MenuItem key={ps.packSize} value={ps.packSize}>
                    {packSizeConverter(
                      ps.packSize,
                      ps.unitOfMeasure,
                      ps.subPackDescription
                    )}
                  </MenuItem>
                ))}
              </Select>
              {isLoadingPacks && <CircularProgress size="24px" />}
            </Box>
          )}
        <Box
          component={'footer'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: theme.spacing(1),
          }}
        >
          <Button
            data-testid="products-popup-select-product-button"
            fullWidth
            disableElevation
            variant="contained"
            disabled={!isValidSelection()}
            onClick={onSelected}
          >
            {getSelectButtonText()}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
export default SelectProductModal
