import { IChatService } from "../types/IChatService";

export class IntercomChatService implements IChatService {
    private w: any = window

    startLoggedInSession(userId: string, email: string, name: string): void {
        this.w.Intercom('boot', {
            hide_default_launcher: true,
            app_id: 'vqjurie9',
            email,
            user_id: userId,
            name
        })
    }

    endLoggedInSession(): void {
        this.w.Intercom('shutdown')
    }

    openChat(): void {
        this.w.Intercom('show')
    }
}