import { useEffect, useRef } from 'react'

export function useStateUpdatedCallback<T>(
  state: T,
  onStateUpdated: (state: T) => void
) {
  const firstRender = useRef(true)
  useEffect(() => {
    if (!firstRender.current) {
      onStateUpdated(state)
    }
  }, [state, onStateUpdated])

  // It is important that this useEffect stays last!
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    }
  }, [])
}
