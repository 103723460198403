import { FC, useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  authenticatedUserState,
  featureFlagsForSelectedClientState,
  stockrxUserState,
  userPermissionsForSelectedClientState,
} from '../state/AuthenticationState'

import { NavigationPaths } from '../constants'
import { ServiceContext } from '../providers/ServicesProvider'
import { AuthenticationResult } from '../types/authentication/AuthenticationResult'
import useStockRxUser from '../hooks/useStockRxUser'
import Navigation, {
  navigationLinkMaps,
} from '../components/Navigation/Navigation'
import Box from '@mui/material/Box'
import useIntercomChat from '../hooks/useIntercomChat'
import { useGlobalIsLoading } from '../hooks/useIsLoading'
import { ClientTypes } from '../types/entities/ClientPermission'
import { Alert } from '@mui/material'
import { locale } from '../locales'
import theme from '../styles/theme'

const LoggedInRoute: FC = () => {
  const { authenticationService, navigationService } =
    useContext(ServiceContext)

  const setAuthenticatedUser = useSetRecoilState(authenticatedUserState)
  const authenticatedUser = useRecoilValue(authenticatedUserState)
  const stockRxUser = useRecoilValue(stockrxUserState)
  const clientFeatureFlags = useRecoilValue(featureFlagsForSelectedClientState)
  const userPermissionsForSelectedClient = useRecoilValue(
    userPermissionsForSelectedClientState
  )

  const { setIsLoading } = useGlobalIsLoading()

  useStockRxUser()
  useIntercomChat()

  const location = useLocation()

  /* Hit the Authentication Service to get the current user
    And set the Recoil state accordingly
  */
  useEffect(() => {
    const getCurrentUser = async () => {
      return await authenticationService.getCurrentUserAsync()
    }
    if (!authenticatedUser) {
      setIsLoading(true)
      getCurrentUser().then((authenticationResult: AuthenticationResult) => {
        setIsLoading(false)
        setAuthenticatedUser(authenticationResult?.user ?? null)
        if (!authenticationResult?.user) {
          navigationService.navigate(NavigationPaths.Login)
        }
      })
    }
  }, [
    authenticationService,
    navigationService,
    authenticatedUser,
    setAuthenticatedUser,
    setIsLoading,
    location,
  ])

  const isFeatureDisabledForClient = (featureFlag?: string) => {
    return (
      featureFlag && !clientFeatureFlags?.setFeatureFlags.includes(featureFlag)
    )
  }

  const isPermissionDisabledForClient = (permission?: string) => {
    return permission && !userPermissionsForSelectedClient?.includes(permission)
  }

  const renderFeatureOnlyAvailableToClientType = (clientType: string) => {
    const text =
      clientType === ClientTypes.Pharmacy
        ? locale.translation.Common.FeatureAvailableToPharmaciesOnly
        : ClientTypes.Company
        ? locale.translation.Common.FeatureAvailableToOrganisationsOnly
        : ''
    return (
      <Box sx={{ padding: theme.spacing(1) }}>
        <Alert severity="info" variant="filled">
          {text}
        </Alert>
      </Box>
    )
  }

  const renderOutletOrUnavailable = () => {
    const navigationLinkMap = navigationLinkMaps[location.pathname]
    if (navigationLinkMap) {
      if (
        navigationLinkMap.availableToClientType &&
        clientFeatureFlags?.clientType !==
          navigationLinkMap.availableToClientType
      ) {
        return renderFeatureOnlyAvailableToClientType(
          navigationLinkMap.availableToClientType
        )
      } else {
        const featureDisabledForClient = isFeatureDisabledForClient(
          navigationLinkMap.featureFlag
        )
        const permissionDisabledForClient = isPermissionDisabledForClient(
          navigationLinkMap.permission
        )
        if (featureDisabledForClient || permissionDisabledForClient) {
          return (
            <Box sx={{ padding: theme.spacing(1) }}>
              <Alert severity="error" variant="filled">
                {featureDisabledForClient
                  ? locale.translation.Common.FeatureNotEnabled(
                      clientFeatureFlags?.clientName
                    )
                  : locale.translation.Common.NoPermissions}
              </Alert>
            </Box>
          )
        }
        return <Outlet />
      }
    }
    return <Outlet />
  }

  return (
    <>
      {authenticatedUser && stockRxUser && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            minWidth: '1280px',
          }}
        >
          <Navigation />
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {renderOutletOrUnavailable()}
          </Box>
        </Box>
      )}
    </>
  )
}

export default LoggedInRoute
