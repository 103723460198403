import { FC, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import theme from '../../../styles/theme'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import TextField from '@mui/material/TextField/TextField'
import InputSectionWithTags, {
  InputSectionWithTagsHandle,
} from '../../../components/Forms/InputSectionWithTags'
import EditableSection from './EditableSection'
import * as yup from 'yup'
import { useFormik } from 'formik'
import SwitchWithInfo from '../../../components/Forms/SwitchWithInfo'

const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer

const PharmacyDetailsContainer: FC<{
  authId: string
  pharmacyName: string
  odsCode: string
  emails: string[]
  phoneNumber: string
  autoEdit?: boolean
  isBarcodeScanningEnabled: boolean
  isBarcodeScanningMandatory: boolean
  isScanOneEnterQuantityEnabled: boolean
  onUpdatedPharmacyDetails: (
    odsCode: string,
    emails: string[],
    phoneNumber: string,
    isBarcodeScanningMandatory: boolean,
    isScanOneEnterQuantityEnabled: boolean
  ) => void
}> = (props) => {
  const [emails, setEmails] = useState<string[]>(props.emails)
  const [isEditingComplianceEmails, setIsEditingComplianceEmails] =
    useState(false)
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false)
  const emailsInputSectionWithTagsHandle =
    useRef<InputSectionWithTagsHandle>(null)
  const [
    isEditingBarcodeScanningSettings,
    setIsEditingBarcodeScanningSettings,
  ] = useState(false)
  const [isBarcodeScanningMandatory, setIsBarcodeScanningMandatory] =
    useState<boolean>(props.isBarcodeScanningMandatory)
  const [isScanOneEnterQuantityEnabled, setIsScanOneEnterQuantityEnabled] =
    useState<boolean>(props.isScanOneEnterQuantityEnabled)
  const formik = useFormik({
    initialValues: {
      phoneNumber: props.phoneNumber || '',
    },
    enableReinitialize: true,
    onSubmit: () => {
      props.onUpdatedPharmacyDetails(
        props.odsCode,
        emails,
        formik.values.phoneNumber,
        isBarcodeScanningMandatory,
        isScanOneEnterQuantityEnabled
      )
    },
  })

  useEffect(() => {
    setIsEditingComplianceEmails(false)
    setEmails(props.emails)
  }, [props.emails, props.odsCode])

  useEffect(() => {
    setIsEditingBarcodeScanningSettings(false)
    setIsBarcodeScanningMandatory(props.isBarcodeScanningMandatory)
    setIsScanOneEnterQuantityEnabled(props.isScanOneEnterQuantityEnabled)
  }, [
    props.isBarcodeScanningMandatory,
    props.isScanOneEnterQuantityEnabled,
    props.odsCode,
  ])

  const handleInvalidTagState = (hasInvalid: boolean) => {
    setHasInvalidEmails(hasInvalid)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Typography
          sx={{
            paddingY: theme.spacing(1),
            margin: 0,
            color: theme.palette.grey[700],
          }}
          variant="button"
        >
          {translations.PharmacyDetails}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <TextField
            label={translations.PharmacyNameLabel}
            value={props.pharmacyName}
            variant="outlined"
            disabled
            fullWidth
          />
          <TextField
            label={translations.OdsCodeLabel}
            value={props.odsCode}
            variant="outlined"
            disabled
            fullWidth
          />
        </Box>
        <EditableSection
          sectionTitle={translations.DailyComplianceReportSectionTitel}
          onUpdate={() => {
            const emailTags =
              emailsInputSectionWithTagsHandle.current?.getTags() ?? []
            props.onUpdatedPharmacyDetails(
              props.odsCode,
              emailTags,
              formik.values.phoneNumber,
              props.isBarcodeScanningMandatory,
              props.isScanOneEnterQuantityEnabled
            )
            setEmails(emailTags)
          }}
          updateDisabled={!isEditingComplianceEmails || hasInvalidEmails}
          onCancel={() => {
            emailsInputSectionWithTagsHandle.current?.clearValue()
            setIsEditingComplianceEmails(false)
            setHasInvalidEmails(false)
            formik.resetForm()
            setEmails(props.emails)
          }}
          onEditingChanged={setIsEditingComplianceEmails}
          titleSx={{ fontWeight: 600 }}
          autoEdit={Boolean(props.autoEdit)}
          isEditing={isEditingComplianceEmails}
        >
          <InputSectionWithTags
            ref={emailsInputSectionWithTagsHandle}
            contentKey={props.odsCode}
            initialTags={emails}
            label={translations.RecipientsEmailsLabel}
            validationSchema={yup
              .string()
              .email(translations.ErrorEmailRequired)}
            errorMessage={translations.ErrorEmailRequired}
            isEditing={isEditingComplianceEmails}
            onInvalidTagStateChange={handleInvalidTagState}
          />
          <TextField
            label={translations.PhoneNumberLabel}
            value={formik.values.phoneNumber}
            variant="outlined"
            name="phoneNumber"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputProps={{ inputMode: 'numeric' }}
            disabled={!isEditingComplianceEmails}
            error={Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber
            )}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
          />
        </EditableSection>
        {props.isBarcodeScanningEnabled && (
          <EditableSection
            sectionTitle={translations.BarcodeScanningSectionTitle}
            onUpdate={() =>
              props.onUpdatedPharmacyDetails(
                props.odsCode,
                emails,
                formik.values.phoneNumber,
                isBarcodeScanningMandatory,
                isScanOneEnterQuantityEnabled
              )
            }
            updateDisabled={!isEditingBarcodeScanningSettings}
            onCancel={() => {
              setIsEditingBarcodeScanningSettings(false)
              setIsBarcodeScanningMandatory(props.isBarcodeScanningMandatory)
            }}
            onEditingChanged={setIsEditingBarcodeScanningSettings}
            titleSx={{ fontWeight: 600 }}
            autoEdit={Boolean(props.autoEdit)}
            isEditing={isEditingBarcodeScanningSettings}
          >
            <SwitchWithInfo
              checked={isBarcodeScanningMandatory}
              onChange={() => {
                setIsBarcodeScanningMandatory(!isBarcodeScanningMandatory)
              }}
              testId="mandatory-barcode-scanning-toggle"
              label={translations.MandatoryBarcodeScanningToggleLabel}
              infoTooltipText={translations.BarcodeScanningInfo}
              updateDisabled={!isEditingBarcodeScanningSettings}
            />
            <SwitchWithInfo
              checked={isScanOneEnterQuantityEnabled}
              onChange={() => {
                setIsScanOneEnterQuantityEnabled(!isScanOneEnterQuantityEnabled)
              }}
              testId="allow-scan-one-enter-quantity-toggle"
              label={translations.AllowScanOneEnterQuantityToggleLabel}
              infoTooltipText={translations.ScanOnEnterQuantityInfo}
              updateDisabled={!isEditingBarcodeScanningSettings}
            />
          </EditableSection>
        )}
      </Box>
    </Box>
  )
}

export default PharmacyDetailsContainer
