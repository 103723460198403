import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { ChangeEvent, FC, useState } from 'react'

const RevealPasswordInput: FC<{
  label: string
  placeholder: string
  value: string
  error?: boolean
  turnOffAutoComplete?: boolean
  onChanged: (password: string) => void
}> = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const [changedCount, setChangedCount] = useState(0)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const [password, setPassword] = useState<string>(props.value)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handlePasswordChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    let pw = ''
    if (!(changedCount === 1 && (password?.length ?? 0) > 1)) {
      pw = e.target.value
    }
    setChangedCount(changedCount + 1)
    setPassword(pw)
    props.onChanged(pw)
  }

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">
        {props.label}
      </InputLabel>
      <OutlinedInput
        fullWidth
        id="password"
        name="password"
        label={props.label}
        placeholder={props.placeholder}
        value={password}
        onChange={handlePasswordChanged}
        error={props.error}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <>
            {changedCount > 1 && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
        autoComplete={props.turnOffAutoComplete ? 'off' : undefined}
        inputProps={
          props.turnOffAutoComplete
            ? {
                autoComplete: 'one-time-code',
                form: {
                  autoComplete: 'off',
                },
              }
            : undefined
        }
      />
    </FormControl>
  )
}
export default RevealPasswordInput
