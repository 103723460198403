import { HttpResponse } from '../types/http/HttpResponse'

export class HttpServiceBase {
  async performRequestAsync<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    body?: any | undefined,
    headers?: HeadersInit,
    mapJsonToT: boolean = true
  ): Promise<HttpResponse<T>> {
    let response: Response | null = null
    try {
      response = await fetch(url, {
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const responseText = await this.tryGetErrorBodyText(response)
        if (responseText) {
          throw new Error(responseText)
        }
        throw new Error('non-success-status-code')
      }
      const result: HttpResponse<T> = {
        errors: [],
        hasErrors: false,
        data: null,
        rawResponse: response,
        statusCode: response.status,
        statusText: response.statusText,
      }
      if (mapJsonToT) {
        try {
          result.data = await response.json()
        } catch {
          // silently fail if can't parse json
        }
      }
      return result
    } catch (error: any) {
      console.error(error)
      return {
        errors: [error],
        data: null,
        rawResponse: response,
        hasErrors: true,
        statusCode: response?.status,
        statusText: response?.statusText,
      }
    }
  }

  async tryGetErrorBodyText(response: Response): Promise<string | null> {
    try {
      return await response.text()
    } catch {
      return null
    }
  }
}
