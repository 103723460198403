import { Breakpoints, createTheme, Theme } from '@mui/material/styles'
import { red, grey } from '@mui/material/colors'
import type {} from '@mui/x-date-pickers/themeAugmentation'

interface ICustomThemeOptions {
  navigation: {
    width?: string
  }
}

export interface CustomBreakPoints extends Breakpoints {
  values: {
    xs: number
    sm: number
    md: number
    lg: number
    lgx: number
    xl: number
    xxl: number
  }
}

export interface CustomTheme extends Theme {
  custom: ICustomThemeOptions
  breakpoints: CustomBreakPoints
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontSize: 12,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1280,
      md: 1440,
      lg: 1680,
      lgx: 1720,
      xl: 1920,
      xxl: 2160,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: 'default',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {},
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        content: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '0.7rem',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        classes: {
          message: 'alert-srx',
        },
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        filled: {
          cursor: 'default',
          display: 'flex',
          alignItems: 'center',
          fontSize: '.8rem',
          flexDirection: 'row',
          padding: '0 8px',
        },
        outlined: {
          cursor: 'default',
          display: 'flex',
          alignItems: 'center',
          fontSize: '.8rem',
          flexDirection: 'row',
          padding: '0 8px',
        },
        filledWarning: {
          color: 'white',
        },
        outlinedWarning: {
          color: '#E7A707',
        },
        action: {
          alignSelf: 'flex-start',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorWarning: {
          color: 'white',
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          zIndex: 10001,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'inherit',
          padding: '8px',
          wordWrap: 'break-word',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.85rem',
        },
      },
    },
    MuiTab: {
      root: {
        '&.Mui-disabled': {
          color: grey[500],
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4e7c72',
      light: '#D4FAF2',
    },
    secondary: {
      main: '#000000',
      light: '#26B528',
    },
    info: {
      main: '#127DB0',
    },
    warning: {
      main: '#E7A707',
    },
    error: {
      main: red.A400,
    },
  },
  custom: {
    navigation: {
      width: '280px',
    },
  },
} as unknown as CustomTheme)

export default theme as CustomTheme
