import { locale } from '../locales'

const translation = locale.translation.Common.Errors
export const GetErrorMessage = (statusCode?: number, message?: string) => {
  if (message) {
    return message
  }
  if (statusCode === 403 || statusCode === 401) {
    return translation.AuthorisationError
  }
  return locale.translation.Common.Errors.ErrorExecutingRequest(statusCode)
}
