import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { FC, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { NavigationPaths, PermissionNames } from '../../constants'
import { locale } from '../../locales'
import { stockrxUserState } from '../../state/AuthenticationState'
import theme from '../../styles/theme'
import { User } from '../../types/entities/User'
import LogoutButton from './LogoutButton'
import { ClientTypes } from '../../types/entities/ClientPermission'

const getLoggedInUserInitials = (
  loggedInUserFullname: string | null | undefined
) => {
  if (loggedInUserFullname && loggedInUserFullname.trim().length > 0) {
    const split = loggedInUserFullname.split(' ')
    if (split.length > 1) {
      return `${split[0][0]}${split[1][0]}`
    } else {
      return split[0][0]
    }
  }
  return 'U'
}

const hasAdminRights = (user: User | null) => {
  return Boolean(
    user?.clientPermissions
      .filter((c) => c.clientType === ClientTypes.Company)
      .flatMap((p) => p.permissions)
      .find((p) => p === PermissionNames.Manage)
  )
}

const UserPopover: FC = () => {
  const loggedInUser = useRecoilValue(stockrxUserState)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Fab
        data-testid="user-popover-button"
        size="small"
        color="primary"
        sx={{ boxShadow: 0 }}
        onClick={handleClick}
      >
        {getLoggedInUserInitials(loggedInUser?.fullName)}
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: theme.spacing(1) }}
        elevation={1}
      >
        <Box
          sx={{ padding: theme.spacing(1), maxWidth: '300px' }}
          data-testid="user-popover"
        >
          <Typography sx={{ p: 2 }}>
            {locale.translation.UserPopover.Hello(loggedInUser?.fullName)}
          </Typography>
          {hasAdminRights(loggedInUser) && (
            <Button
              data-testid="settings-button"
              component={RouterLink}
              to={NavigationPaths.PharmacySettings}
              variant="text"
              color="primary"
              disableElevation={true}
              sx={{ width: '100%' }}
              onClick={() => handleClose()}
            >
              {locale.translation.UserPopover.AdminPharmacySettings}
            </Button>
          )}
          <LogoutButton />
        </Box>
      </Popover>
    </>
  )
}

export default UserPopover
