import { Amplify, Auth } from 'aws-amplify'
import { configurationSettings } from '../configuration/configurationSettings'
import { AuthenticationResult } from '../types/authentication/AuthenticationResult'
import { AuthenticationResultAction } from '../types/authentication/AuthenticationResultAction'
import { IAuthenticationService } from '../types/authentication/IAuthenticationService'

interface CognitoActionToUserResultActionMap {
  cognitoAction: string
  authrizationResultAction: AuthenticationResultAction
}

export class CognitoAuthService implements IAuthenticationService {
  private cognitoToAuthActionMap: Array<CognitoActionToUserResultActionMap> = [
    {
      cognitoAction: 'NEW_PASSWORD_REQUIRED',
      authrizationResultAction:
        AuthenticationResultAction.NEW_PASSWORD_REQUIRED,
    },
  ]

  constructor() {
    Amplify.configure({
      Auth: { ...configurationSettings.Cognito },
    })
  }

  async getCurrentUserAsync() {
    try {
      const cognitoResult = await Auth.currentAuthenticatedUser()
      return this.cognitoResultToAuthorizationResult(cognitoResult)
    } catch (error) {
      return this.cognitoResultToAuthorizationResult(null, error)
    }
  }

  async refreshTokenAsync(): Promise<AuthenticationResult> {
    return new Promise(async (resolve) => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const refreshToken = cognitoUser
          .getSignInUserSession()
          .getRefreshToken()

        cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
          const authenticationResult = this.cognitoResultToAuthorizationResult(
            {
              username: cognitoUser.username,
              signInUserSession: session,
            },
            err
          )
          resolve(authenticationResult)
        })
      } catch (e) {
        console.log('Unable to refresh Token', e)
      }
    })
  }

  async signInAsync(email: string, password: string) {
    try {
      const cognitoResult = await Auth.signIn(email, password)
      return this.cognitoResultToAuthorizationResult(cognitoResult)
    } catch (error) {
      console.error(error)
      return this.cognitoResultToAuthorizationResult(null, error)
    }
  }

  async signOutAsync() {
    try {
      await Auth.signOut()
    } catch (error) {
      console.error(error)
    }
  }

  async forgotPasswordAsync(email: string): Promise<AuthenticationResult> {
    const result: AuthenticationResult = {
      action: AuthenticationResultAction.NO_ACTION,
      error: null,
      user: null,
    }
    try {
      await Auth.forgotPassword(email)
      result.action = AuthenticationResultAction.CHANGE_PASSWORD_WITH_CODE
    } catch (error) {
      console.error(error)
      result.error = this.getErrorMessage(error)
    }
    return result
  }

  async forgotPasswordSubmitAsync(
    email: string,
    code: string,
    newPassword: string
  ): Promise<AuthenticationResult> {
    const result: AuthenticationResult = {
      action: AuthenticationResultAction.NO_ACTION,
      error: null,
      user: null,
    }
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
    } catch (error) {
      console.error(error)
      result.error = this.getErrorMessage(error)
    }
    return result
  }

  async completeNewPasswordAsync(
    newPassword: string,
    pendingAuthentication: AuthenticationResult
  ) {
    try {
      const cognitoResult = await Auth.completeNewPassword(
        pendingAuthentication.data,
        newPassword
      )
      return this.cognitoResultToAuthorizationResult(cognitoResult)
    } catch (error) {
      console.error(error)
      return this.cognitoResultToAuthorizationResult(null, error)
    }
  }

  private cognitoResultToAuthorizationResult(
    cognitoResult?: any,
    error?: any
  ): AuthenticationResult {
    const result: AuthenticationResult = {
      action: AuthenticationResultAction.NO_ACTION,
      error: null,
      user: null,
      data: cognitoResult,
    }
    if (cognitoResult) {
      if (cognitoResult.challengeName) {
        result.action =
          this.cognitoToAuthActionMap.find(
            (a) => a.cognitoAction === cognitoResult.challengeName
          )?.authrizationResultAction ??
          AuthenticationResultAction.UNKNOWN_ACTION
      } else if (cognitoResult.signInUserSession) {
        result.user = {
          userId: cognitoResult.username,
          accessToken: cognitoResult.signInUserSession.accessToken.jwtToken,
          idToken: cognitoResult.signInUserSession.idToken.jwtToken,
          refreshToken: cognitoResult.signInUserSession.refreshToken.token,
        }
      }
    } else if (error) {
      result.error = this.getErrorMessage(error)
    }
    return result
  }

  private getErrorMessage(error: any) {
    const splitOnColumn = `${error}`.split(':')
    return splitOnColumn.length > 1 ? splitOnColumn.slice(1).join(':') : error
  }
}
