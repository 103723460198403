import { FC, useState } from 'react'
import theme from '../../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { locale } from '../../../locales'
import { useFormik } from 'formik'
import * as yup from 'yup'
import EditableSection from './EditableSection'
import { NewUserAuthId } from '../../../constants'

const editFieldTextFieldStyle: SxProps<Theme> = {
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

const translation =
  locale.translation.SettingsPage.UserManagementTab.UserManagement.UserDetails

const UserDetails: FC<{
  authId: string
  email: string
  fullName: string
  onUpdatedDetails: (authId: string, email: string, fullName: string) => void
  onCancel?: () => void
  autoEdit?: boolean
  isUserUnique: (userName: string) => boolean
}> = ({
  authId,
  email,
  fullName,
  onUpdatedDetails,
  autoEdit,
  onCancel,
  isUserUnique,
}) => {
  const [editing, setEditing] = useState<boolean>(Boolean(autoEdit))

  const { setValues: setFormikValues, ...formik } = useFormik({
    initialValues: {
      email: email,
      fullName: fullName,
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: yup.object({
      email: yup
        .string()
        .email(translation.ErrorEmailRequired)
        .required(translation.ErrorEmailRequired)
        .test('unique', translation.ErrorEmailNotUnique, (value) => {
          return isUserUnique(value || '')
        }),
      fullName: yup
        .string()
        .min(4, translation.ErrorFullNameRequired)
        .required(translation.ErrorFullNameRequired),
    }),
    validateOnMount: true,
  })

  return (
    <Box>
      <EditableSection
        onCancel={() => {
          if (onCancel) {
            onCancel()
          } else {
            formik.resetForm()
          }
        }}
        onUpdate={() => {
          onUpdatedDetails(authId, formik.values.email, formik.values.fullName)
        }}
        sectionTitle={translation.UserDetails}
        updateDisabled={!formik.isValid}
        onEditingChanged={(editing) => setEditing(editing)}
        titleSx={{ fontWeight: 600 }}
        autoEdit={Boolean(autoEdit)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            paddingY: theme.spacing(1),
          }}
        >
          <TextField
            sx={{ ...editFieldTextFieldStyle }}
            autoComplete="off"
            label={translation.Email}
            variant="outlined"
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue('email', e.target.value)
            }}
            disabled={!editing || authId !== NewUserAuthId}
            error={Boolean(formik.errors.email)}
            helperText={formik.errors.email}
            type="email"
          />
          <TextField
            sx={{ ...editFieldTextFieldStyle }}
            autoComplete="off"
            label={translation.FullName}
            variant="outlined"
            value={formik.values.fullName}
            onChange={(e) => {
              formik.setFieldValue('fullName', e.target.value)
            }}
            disabled={!editing}
            error={Boolean(formik.errors.fullName)}
            helperText={formik.errors.fullName}
          />
        </Box>
      </EditableSection>
    </Box>
  )
}

export default UserDetails
