import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import RevealPasswordInput from './RevealPasswordInput'

const newPasswordValidationSchema = yup.object({
  newPassword: yup
    .string()
    .required(
      locale.translation.ChangePasswordForm.Fields.NewPassword.Validation
        .Required
    )
    .min(8, (m) =>
      locale.translation.ChangePasswordForm.Fields.NewPassword.Validation.Minimum(
        m.min
      )
    ),
  confirmNewPassword: yup
    .string()
    .required(
      locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword.Validation
        .Required
    )
    .min(8, (m) =>
      locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword.Validation.Minimum(
        m.min
      )
    )
    .oneOf(
      [yup.ref('newPassword')],
      locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword.Validation
        .PasswordsDoNotMatch
    ),
})

export const ChangePasswordFragment: React.FC<{
  cardStyle: SxProps<Theme>
  handleSubmit: (newPassword: string, code: string) => void
  newPasswordError: string
  isForgot: boolean
}> = ({ cardStyle, handleSubmit, newPasswordError, isForgot }) => {
  const changePasswordFormikConfig = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
      code: '',
    },
    validationSchema: newPasswordValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values.newPassword, values.code)
    },
  })

  return (
    <form
      onSubmit={changePasswordFormikConfig.handleSubmit}
      data-testid="change-password-form"
    >
      <Card variant="outlined" sx={cardStyle}>
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {isForgot
                ? locale.translation.ForgotPasswordForm.Title
                : locale.translation.ChangePasswordForm.Title}
            </Typography>
            {isForgot && (
              <>
                <Typography variant="subtitle1">
                  {locale.translation.ForgotPasswordForm.CodeDescription}
                </Typography>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  autoComplete="off"
                  inputProps={{
                    autoComplete: 'one-time-code',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  label={
                    locale.translation.ForgotPasswordForm.Fields.Code.Label
                  }
                  placeholder={
                    locale.translation.ForgotPasswordForm.Fields.Code
                      .Placeholder
                  }
                  value={changePasswordFormikConfig.values.code}
                  onChange={changePasswordFormikConfig.handleChange}
                />
              </>
            )}
            <RevealPasswordInput
              label={
                locale.translation.ChangePasswordForm.Fields.NewPassword.Label
              }
              placeholder={
                locale.translation.ChangePasswordForm.Fields.NewPassword
                  .Placeholder
              }
              error={Boolean(changePasswordFormikConfig.errors.newPassword)}
              value={changePasswordFormikConfig.values.newPassword}
              onChanged={async (v) =>
                await changePasswordFormikConfig.setFieldValue('newPassword', v)
              }
            />
            <TextField
              fullWidth
              id="confirmNewPassword"
              name="confirmNewPassword"
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              label={
                locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword
                  .Label
              }
              placeholder={
                locale.translation.ChangePasswordForm.Fields.ConfirmNewPassword
                  .Placeholder
              }
              type="password"
              value={changePasswordFormikConfig.values.confirmNewPassword}
              onChange={changePasswordFormikConfig.handleChange}
              disabled={Boolean(changePasswordFormikConfig.errors.newPassword)}
              error={Boolean(
                changePasswordFormikConfig.errors.confirmNewPassword
              )}
              helperText={changePasswordFormikConfig.errors.confirmNewPassword}
            />
            {newPasswordError && (
              <Alert variant="filled" severity="error">
                {newPasswordError}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" fullWidth type="submit">
            {locale.translation.ChangePasswordForm.Buttons.Submit}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
