import { FC } from 'react'
import { ClientSelection } from '../../types/entities/ClientPermission'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import SearchBox from '../Forms/SearchBox'
import theme from '../../styles/theme'
import MultiSelection from '../Forms/MultiSelection'
import { locale } from '../../locales'
import DateRangePickerPopover from '../Forms/DateRangePickers'
import { SxProps, Theme } from '@mui/material/styles'

export interface FilterBarState {
  availableInternalSuppliers: {
    [key: string]: { [key: string]: string }
  } | null
  availableSystemSuppliers: { [key: string]: string } | null
  availableSuppliers: { [key: string]: string }
  selectedSupplierIds: string[]

  selectedClient: ClientSelection | null

  searchText: string
  dates: (DateTime | null)[]
}

type SetAvailableInternalSuppliersAction = {
  type: 'setAvailableInternalSuppliers'
  payload: { [key: string]: { [key: string]: string } }
}

type SetAvailableSystemSuppliersAction = {
  type: 'setAvailableSystemSuppliers'
  payload: { [key: string]: string }
}

type SetAppSelectedClientAction = {
  type: 'setSelectedClient'
  payload: ClientSelection
}

type SetSearchFieldAction = {
  type: 'setSearchField'
  payload: { text: string; resetPaging: boolean }
}

type SetDatesAction = {
  type: 'setDates'
  payload: (DateTime | null)[]
}

type SetSelectedSupplierIdsAction = {
  type: 'setSelectedSupplierIds'
  payload: string[]
}

export type FilterBarAction =
  | SetAvailableInternalSuppliersAction
  | SetAvailableSystemSuppliersAction
  | SetAppSelectedClientAction
  | SetSearchFieldAction
  | SetDatesAction
  | SetSelectedSupplierIdsAction

const FilterBar: FC<{
  state: FilterBarState
  dispatch: (value: FilterBarAction) => void
  searchPlaceholder: string
  datePickerPresetKey?: string
  searchFieldTestId?: string
  searchResetsPaging?: boolean
  allowNullDates?: boolean
  sx?: SxProps<Theme>
  additionalSearchElement?: JSX.Element
}> = ({
  state,
  dispatch,
  searchPlaceholder,
  datePickerPresetKey,
  searchFieldTestId,
  searchResetsPaging = true,
  allowNullDates = true,
  sx,
  additionalSearchElement,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: theme.spacing(1),
        }}
      >
        <SearchBox
          placeholder={searchPlaceholder}
          searchText={state.searchText}
          onTextUpdated={(text) => {
            dispatch({
              type: 'setSearchField',
              payload: { text, resetPaging: searchResetsPaging },
            })
          }}
          testId={searchFieldTestId}
        />
        {additionalSearchElement}
        <MultiSelection
          id={'multi-supplier'}
          onSelectionApplied={(s) => {
            dispatch({
              type: 'setSelectedSupplierIds',
              payload: s,
            })
          }}
          options={state.availableSuppliers}
          selectedOptionKeys={state.selectedSupplierIds}
          title={
            state.selectedSupplierIds.length > 0 &&
            state.selectedSupplierIds.length ===
              Object.entries(state.availableSuppliers).length
              ? locale.translation.MultiSupplierSelection.AllSuppliers
              : locale.translation.MultiSupplierSelection.SpecificSuppliers
          }
          isDirty={
            state.selectedSupplierIds.length > 0 &&
            state.selectedSupplierIds.length !==
              Object.entries(state.availableSuppliers).length
          }
        />
        <DateRangePickerPopover
          dateFrom={state.dates[0]}
          dateTo={state.dates[1]}
          localStorageKey={datePickerPresetKey}
          onDatesSelected={(from, to) => {
            dispatch({ type: 'setDates', payload: [from, to] })
          }}
          allowNullDates={allowNullDates}
        />
      </Box>
    </Box>
  )
}

export default FilterBar
