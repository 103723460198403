import { FC } from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import CloseIcon from '@mui/icons-material/Close'
import theme from '../../styles/theme'

const CloseModalButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <ButtonBase
      sx={{
        padding: theme.spacing(1),
        backgroundColor: 'white',
        borderRadius: '50%',
        alignSelf: 'flex-end',
        '&:hover': { color: theme.palette.primary.main },
        marginRight: '-24px',
        marginTop: '-24px',
        marginBottom: '12px',
      }}
      onClick={() => onClick()}
      data-testid="close-modal-button"
    >
      <CloseIcon sx={{ fontSize: '1.5em' }} />
    </ButtonBase>
  )
}
export default CloseModalButton
