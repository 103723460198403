import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import RevealPasswordInput from './RevealPasswordInput'

const loginValidationSchema = yup.object({
  email: yup
    .string()
    .required(locale.translation.LogInForm.Fields.Email.Validation.Required),
  password: yup
    .string()
    .required(locale.translation.LogInForm.Fields.Password.Validation.Required),
})

export const LogInFragment: React.FC<{
  cardStyle: SxProps<Theme>
  handleSubmit: (username: string, password: string) => void
  handleForgotPassword: () => void
  loginError: string | null
}> = ({ cardStyle, handleSubmit, handleForgotPassword, loginError }) => {
  const loginFormikConfig = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values.email, values.password)
    },
  })

  return (
    <form onSubmit={loginFormikConfig.handleSubmit} data-testid="login-form">
      <Card variant="outlined" sx={cardStyle}>
        <CardContent sx={{ padding: theme.spacing(1) }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {locale.translation.LogInForm.Title}
            </Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              label={locale.translation.LogInForm.Fields.Email.Label}
              placeholder={
                locale.translation.LogInForm.Fields.Email.Placeholder
              }
              value={loginFormikConfig.values.email}
              onChange={loginFormikConfig.handleChange}
              error={
                loginFormikConfig.touched.email &&
                Boolean(loginFormikConfig.errors.email)
              }
              helperText={
                loginFormikConfig.touched.email &&
                loginFormikConfig.errors.email
              }
            />
            <RevealPasswordInput
              error={
                (loginFormikConfig.touched.password ?? false) &&
                Boolean(loginFormikConfig.errors.password)
              }
              label={locale.translation.LogInForm.Fields.Password.Label}
              placeholder={
                locale.translation.LogInForm.Fields.Password.Placeholder
              }
              value={loginFormikConfig.values.password}
              onChanged={async (v) =>
                await loginFormikConfig.setFieldValue('password', v)
              }
            />
            {loginError && (
              <Alert variant="filled" severity="error">
                {loginError}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disableElevation={true}
          >
            {locale.translation.LogInForm.Buttons.Submit}
          </Button>
          <Button
            variant="text"
            sx={{ textTransform: 'none' }}
            onClick={() => handleForgotPassword()}
          >
            {locale.translation.LogInForm.Buttons.ForgotPassword}
          </Button>
        </CardActions>
      </Card>
    </form>
  )
}
