export type SortingType = 'NONE' | 'ASC' | 'DESC'

export interface SortArrayByPropertyKeyArgs {
  sortingType: SortingType
  sortingPropertyKey?: string | number
}

export const sortArrayByPropertyKey = (
  items: Array<any>,
  sortArgs: SortArrayByPropertyKeyArgs,
  sortingTransforms?: {
    [key: string]: (
      propertyValue: unknown,
      rowValue: unknown
    ) => string | number | Date
  }
): void => {
  if (sortArgs.sortingType !== 'NONE' && sortArgs.sortingType) {
    const isAscending = sortArgs.sortingType === 'ASC'

    items = items.sort((current, previous) => {
      if (sortArgs.sortingPropertyKey) {
        let currentValue = current[sortArgs.sortingPropertyKey]
        let previousValue = previous[sortArgs.sortingPropertyKey]

        const sortingTransform =
          sortingTransforms?.[sortArgs.sortingPropertyKey]

        if (sortingTransform) {
          currentValue = sortingTransform(currentValue, current)
          previousValue = sortingTransform(previousValue, previous)
        }

        if (currentValue == null && previousValue != null) {
          return 1
        } else if (currentValue != null && previousValue == null) {
          return -1
        } else if (currentValue == null && previousValue == null) {
          return 1
        } else {
          let isCurrentBigger = false
          if (
            typeof currentValue === 'string' &&
            typeof previousValue === 'string'
          ) {
            isCurrentBigger =
              (currentValue as string).toLocaleLowerCase() >
              (previousValue as string).toLocaleLowerCase()
          } else if (
            typeof currentValue === 'number' &&
            typeof previousValue === 'number'
          ) {
            isCurrentBigger = currentValue > previousValue
          } else if (
            currentValue instanceof Date &&
            previousValue instanceof Date
          ) {
            isCurrentBigger = Number(currentValue) > Number(previousValue)
          }
          return isAscending
            ? isCurrentBigger
              ? 1
              : -1
            : isCurrentBigger
            ? -1
            : 1
        }
      }
      return isAscending ? -1 : 1
    })
  }
}
