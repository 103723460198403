export const NavigationPaths = {
  Root: '/',
  Login: '/auth/login',
  Analytics: '/analytics',
  GoodsIn: '/goods-in',
  Mer: '/month-end-reconciliation',
  StockTracking: '/stock-tracking',
  // Forecasts: '/forecasts',
  Uploads: '/uploads',
  PriceFiles: '/price-files',
  PharmacySettings: '/pharmacy-settings',
}

export const DefaultNavigationPath = NavigationPaths.Analytics

export const MimeTypes = {
  PDF: 'application/pdf',
  ZIP: 'application/zip',
  XZIP: 'application/x-zip-compressed',
}

export const PermissionNames = {
  Manage: 'Manage',
  Stock: 'Stock',
  Commercials: 'Commercials',
  MonthEndReconciliation: 'MER',
  BookInHealthscore: 'BHS',
}

export const AdjustmentTypeCodes = {
  Credit: 'CREDIT',
  Disposal: 'DISPOSAL',
  InterBranchTransfer: 'INTER_BRANCH_TRANSFER',
}

export const AdjustmentReasonCodes = {
  DamagedProduct: 'DAMAGED_PRODUCT',
  MissingProduct: 'MISSING_PRODUCT',
  IncorrectProduct: 'INCORRECT_PRODUCT',
  OrderedInError: 'ORDERING_ERROR',
  PicingError: 'PRICING_ERROR',
  ShortDated: 'SHORT_DATED',
  ExpiredProduct: 'EXPIRED_PRODUCT',
  InterBranchTransfer: 'INTER_BRANCH_TRANSFER',
  SendingStockOut: 'SENDING_STOCK_OUT',
  StockComingIn: 'STOCK_COMING_IN',
}

export const AdjustmentReasonCredit = {
  type: {
    code: AdjustmentTypeCodes.Credit,
  },
  reasons: [
    { code: AdjustmentReasonCodes.DamagedProduct },
    { code: AdjustmentReasonCodes.MissingProduct },
    { code: AdjustmentReasonCodes.IncorrectProduct },
    { code: AdjustmentReasonCodes.OrderedInError },
    { code: AdjustmentReasonCodes.PicingError },
    { code: AdjustmentReasonCodes.ShortDated },
  ],
}

export const AdjustmentReasonDisposal = {
  type: {
    code: AdjustmentTypeCodes.Disposal,
  },
  reasons: [
    { code: AdjustmentReasonCodes.ExpiredProduct },
    { code: AdjustmentReasonCodes.DamagedProduct },
  ],
}

export const AdjustmentReasonInterBranchTransfer = {
  type: {
    code: AdjustmentTypeCodes.InterBranchTransfer,
  },
  reasons: [
    { code: AdjustmentReasonCodes.StockComingIn },
    { code: AdjustmentReasonCodes.SendingStockOut },
  ],
}

export const AdjustmentReasons = [
  AdjustmentReasonCredit,
  AdjustmentReasonDisposal,
  AdjustmentReasonInterBranchTransfer,
]

export const UnlistedProductId = 'UNLISTED_PRODUCT'
export const NewUserAuthId = 'NEW-USER'
export const NewGroupId = 'NEW-GROUP'

export const FeatureFlags = {
  HasForecasts: 'hasForecasts',
  HasStockTracking: 'hasStockTracking',
  HasPriceFiles: 'hasPriceFiles',
  HasMandatoryScanning: 'mandatoryScanning',
  ScanOneEnterQuantity: 'scanOneEnterQuantity',
}

export const PharmacyFeatureFlags = {
  // Features
  EnableForecasting: 'hasForecasts',
  EnableBarcodeScanning: 'hasBarcodeScanning',
  EnableCredits: 'hasCreditsForAction',
  EnableMonthEndReconciliation: 'hasMonthEndReconciliation',
  EnableBookInHealthScore: 'hasBookInHealthScore',
  EnableAnalytics: 'hasAnalytics',
  EnableStockTracking: 'hasStockTracking',

  // Behavioural
  EnableAutomaticBackgroundConfirmation: 'hasAutoConfirm',
  DisableEmailInvoices: 'hasDisabledEmailInvoices',
  EnableMandatoryScanning: 'mandatoryScanning',
  EnableScanOneEnterQuantity: 'scanOneEnterQuantity',
}

export const OrganistionFeatureFlags = {
  EnablePriceFiles: 'hasPriceFiles',
}

export const AltPriceTypes = {
  Net: 'NET',
}
export const PackSize = {
  Specials: 'Specials',
}
export const ChartPallete = [
  '#9E1020',
  '#D74836',
  '#00D1A4',
  '#2B566B',
  '#10709E',
  '#9E1696',
  '#D73E82',
  '#D79236',
  '#2F6B64',
  '#169E8E',
  '#109E44',
  '#36D79E',
  '#D14600',
  '#6B522B',
  '#9E6710',
]
export const BookInStatuses = {
  New: 'NEW',
  InvoicePagesEdited: 'INVOICE_PAGES_EDITED',
  OcrProcessing: 'OCR_PROCESSING',
  OcrErrors: 'OCR_ERRORS',
  OcrSuccessfull: 'OCR_SUCCESSFULL',
  UserUpdated: 'USER_UPDATED',
  InvoicePageMismatches: 'INVOICE_PAGE_MISMATCHES',
  ReEdit: 'RE-EDIT',
  Completed: 'COMPLETED',
}

export const AdjustmentStatuses = {
  InProgress: 'IN_PROGRESS',
  Completed: 'COMPLETED',
  Actioned: 'ACTIONED',
  Received: 'RECEIVED',
  Rejected: 'REJECTED',
}

export const ProductDataSources = {
  Dmd: 'DM+D',
  Cd: 'C+D',
  Manual: 'MANL',
}

export const ErrorMatchingCreditForAction = {
  enrichLimitError: 'NOTES_ENRICH_LIMIT_OF_CHARACTERS',
  adjustmentError: 'ERROR_ADJUSTMENT',
}

export const FormType = {
  Adjustment: 'Adjustment',
}

export const MonthEndReconciliationDocumentTypes = {
  Invoice: 'INVOICE',
  Credit: 'CREDIT',
}

export const WebSocketActions = {
  StockTrackingProductUpdated: 'StockTracking_ProductUpdated',
  BookInHealthscoreBookInUpdated: 'BookInHealthscore_BookInUpdated',
  PriceFileProcessed: 'PriceFile_Processed',
  PriceFileDeleted: 'PriceFile_Deleted',
  AsyncOcrProgress: 'AsyncOcr_Progress',
  MerOcrCompleted: 'MerOcr_Completed',
}

export const EditDeliveryModes = {
  PendingDeliveries: 'PENDING_DELIVERIES',
  DeliveryHistory: 'DELIVERY_HISTORY',
  Analytics: 'ANALYTICS',
  HealthScore: 'HEALTHSCORE',
  MonthEndReconciliation: 'MONTH_END_RECONCILIATION',
}

export const VatEditingModes = {
  NoVat: 'NO_VAT',
  Amount: 'AMOUNT',
  Rate: 'RATE',
  Code: 'CODE',
  AmountNullable: 'AMOUNT_NULLABLE',
  RateNullable: 'RATE_NULLABLE',
  CodeNullable: 'CODE_NULLABLE',
}

export const PriceFileUploadsStates = {
  Uploaded: 'UPLOADED',
  Processing: 'PROCESSING',
  Processed: 'PROCESSED',
  Failed: 'FAILED',
  Deleting: 'DELETING',
}

export const DatePresetsLocalStorageKeys = {
  PENDING_DELIVERIES_DATE_RANGE_PRESET_KEY:
    'pending-deliveries-date-range-preset',
  DELIVERY_HISTORY_DATE_RANGE_PRESET_KEY: 'delivery-history-date-range-preset',
  CREDITS_DATE_RANGE_PRESET_KEY: 'credits-date-range-preset',
  HEALTHSCORE_DATE_RANGE_PRESET_KEY: 'healthscore-date-range-preset',
  COMMERCIALS_DATE_RANGE_PRESET_KEY: 'commercials-date-range-preset',
}

export const RecoilPageIds = {
  TabCommercials: 'analytics-commercials',
  TabBookInHealthscore: 'invoice-healthscore',
  TabCreditsForAction: 'credits-for-action',
  TabDeliveryHistory: 'delivery-history',
  TabPendingDeliveries: 'pending-deliveries',
  TabMer: 'month-end-reconciliation',
  TabStockTracking: 'stock-tracking',
  TabPriceFiles: 'price-files',
}

export const CreationMediums = {
  MobileApp: 'MobileApp',
  WebApp: 'WebApp',
  Email: 'Email',
  InternalSupplierBatch: 'InternalSupplierBatch',
}
