import { createContext, PropsWithChildren } from 'react'
import IPlatformHttpService from '../types/http/IPlatformHttpService'
import { IAuthenticationService } from '../types/authentication/IAuthenticationService'
import { IBaseHttpService } from '../types/http/IBaseHttpService'
import { INavigationService } from '../types/authentication/INavigationService'
import { INotificationService } from '../types/INotificationService'
import { IChatService } from '../types/IChatService'
import { IAnalyticsService } from '../types/IAnalyticsService'

export interface IServiceContext {
  authenticationService: IAuthenticationService
  platformHttpService: IPlatformHttpService
  baseHttpService: IBaseHttpService
  navigationService: INavigationService
  notificationService: INotificationService
  chatService: IChatService
  analyticsService: IAnalyticsService
}

export interface ServiceContextProps extends PropsWithChildren {
  authenticationService: IAuthenticationService
  platformHttpService: IPlatformHttpService
  baseHttpService: IBaseHttpService
  navigationService: INavigationService
  notificationService: INotificationService
  chatService: IChatService
  analyticsService: IAnalyticsService
}

export const ServiceContext = createContext<IServiceContext>(
  {} as IServiceContext
)

export const ServiceProvider: React.FC<ServiceContextProps> = ({
  authenticationService,
  platformHttpService: httpService,
  baseHttpService,
  navigationService,
  notificationService,
  chatService,
  analyticsService,
  children,
}) => {
  return (
    <ServiceContext.Provider
      value={{
        authenticationService,
        platformHttpService: httpService,
        baseHttpService,
        navigationService,
        notificationService,
        chatService,
        analyticsService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  )
}
