import { IConfigurationSettings } from './IConfigurationSettings'

export const configurationSettings: IConfigurationSettings = {
  TitleSuffix: '',
  Cognito: {
    userPoolId: 'eu-west-2_eAUiDOkbF',
    region: 'eu-west-2',
    userPoolWebClientId: '2t7kk0mblk7dcfv7vkhvt9s36b',
  },
  PlatformApi: {
    BaseUri: 'https://platformapi.stockrx.uk',
  },
  WebSocket: {
    Url: 'wss://cfjhhkqjsg.execute-api.eu-west-2.amazonaws.com/production',
  },
  Otlp: {
    CollectorUrl: 'https://otlp.stockrx.uk/v1/traces',
  },
  StockRxSiteUrl: 'https://www.stockrx.uk',
  ShowChat: true
}
