import { useContext, useEffect, useState } from 'react'

import { AuthenticationResult } from '../../types/authentication/AuthenticationResult'
import { LogInFragment } from './LoginFragment'
import { ChangePasswordFragment } from './ChangePasswordFragment'
import { ServiceContext } from '../../providers/ServicesProvider'
import { DefaultNavigationPath } from '../../constants'
import { AuthenticationResultAction } from '../../types/authentication/AuthenticationResultAction'
import { ForgotPasswordFragment } from './ForgotPasswordFragment'
import theme from '../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'

enum LoginStates {
  SHOW_LOGIN,
  SHOW_CHANGE_PASSWORD,
  SHOW_CHANGE_PASSWORD_WITH_CODE,
  FORGOT_PASSWORD,
  LOGGED_IN,
}

const cardStyle: SxProps<Theme> = (theme) => {
  return {
    width: { xs: '480px' },
    padding: theme.spacing(2),
    border: 0,
  }
}

const LogInForm = () => {
  const { authenticationService, navigationService } =
    useContext(ServiceContext)

  const { setIsLoading } = useGlobalIsLoading()

  const [loginState, setLoginState] = useState(LoginStates.SHOW_LOGIN)
  const [loginError, setLoginError] = useState<string>('')

  const [pendingAuthResult, setPendingAuthResult] =
    useState<null | AuthenticationResult>(null)
  const [changePasswordError, setChangePasswordError] = useState('')

  const [forgotPasswordEmailToSubmit, setForgotPasswordEmailToSubmit] =
    useState('')
  const [forgotPasswordErrorEmail, setForgotPasswordErrorEmail] = useState('')

  // Triggered when the login state changes
  useEffect(() => {
    if (loginState === LoginStates.LOGGED_IN) {
      navigationService.navigate({
        pathname: DefaultNavigationPath,
      })
    }
  }, [loginState, navigationService])

  // Triggered by Log in or Change Change Password,
  // after a successfull call to the authentication service
  const handleAuthenticationResult = (
    authenticationResult: AuthenticationResult
  ) => {
    if (
      authenticationResult.action ===
      AuthenticationResultAction.NEW_PASSWORD_REQUIRED
    ) {
      setPendingAuthResult(authenticationResult)
      setLoginState(LoginStates.SHOW_CHANGE_PASSWORD)
    } else if (authenticationResult.user) {
      setLoginState(LoginStates.LOGGED_IN)
    }
  }

  // Triggered from LoginFragment
  const handleLogin = async (username: string, password: string) => {
    setIsLoading(true)
    const loginResult = await authenticationService.signInAsync(
      username,
      password
    )
    setIsLoading(false)
    if (loginResult.error) {
      setLoginError(loginResult.error)
    } else {
      handleAuthenticationResult(loginResult)
    }
  }

  // Triggered from NewPasswordFragment
  const handleChangePassword = async (newPassword: string, code: string) => {
    setIsLoading(true)
    if (pendingAuthResult) {
      const newPasswordResult =
        await authenticationService.completeNewPasswordAsync(
          newPassword,
          pendingAuthResult
        )
      if (newPasswordResult.error) {
        setChangePasswordError(newPasswordResult.error)
      } else {
        handleAuthenticationResult(newPasswordResult)
      }
    } else if (code) {
      const changePasswordResult =
        await authenticationService.forgotPasswordSubmitAsync(
          forgotPasswordEmailToSubmit,
          code,
          newPassword
        )
      if (changePasswordResult.error) {
        setChangePasswordError(changePasswordResult.error)
      } else {
        setLoginState(LoginStates.SHOW_LOGIN)
      }
    }
    setIsLoading(false)
  }

  // Triggered from ForgotPasswordFragment
  const handleForgotPasswordEmail = async (email: string) => {
    const response = await authenticationService.forgotPasswordAsync(email)
    if (response.error) {
      setForgotPasswordErrorEmail(response.error)
    } else {
      setForgotPasswordEmailToSubmit(email)
      setLoginState(LoginStates.SHOW_CHANGE_PASSWORD_WITH_CODE)
    }
  }

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      {loginState === LoginStates.SHOW_LOGIN && (
        <LogInFragment
          handleSubmit={handleLogin}
          loginError={loginError}
          cardStyle={cardStyle}
          handleForgotPassword={() =>
            setLoginState(LoginStates.FORGOT_PASSWORD)
          }
        />
      )}
      {(loginState === LoginStates.SHOW_CHANGE_PASSWORD ||
        loginState === LoginStates.SHOW_CHANGE_PASSWORD_WITH_CODE) && (
        <ChangePasswordFragment
          isForgot={loginState === LoginStates.SHOW_CHANGE_PASSWORD_WITH_CODE}
          cardStyle={cardStyle}
          handleSubmit={handleChangePassword}
          newPasswordError={changePasswordError}
        />
      )}
      {loginState === LoginStates.FORGOT_PASSWORD && (
        <ForgotPasswordFragment
          cardStyle={cardStyle}
          handleSubmitEmail={handleForgotPasswordEmail}
          errorEmail={forgotPasswordErrorEmail}
        />
      )}
    </Box>
  )
}

export default LogInForm
