import { FC } from 'react'
import LoginForm from './LogInForm'
import Box from '@mui/material/Box'

const LoginPage: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignSelf: 'center',
        justifyContent: 'flex-start',
        marginTop: '120px',
      }}
    >
      <LoginForm />
    </Box>
  )
}

export default LoginPage
