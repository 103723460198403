import {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useContext,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react'
import '../../../third-party/react-inner-image-zoom/InnerImageZoom/styles.css'
import InnerImageZoom from '../../../third-party/react-inner-image-zoom'
import { InnerImageZoomHandle } from '../../../third-party/react-inner-image-zoom/InnerImageZoom/InnerImageZoom'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import theme from '../../../styles/theme'
import { hexToRgba } from '../../../utils/Helpers'
import LinearProgress from '@mui/material/LinearProgress'
import { useStateUpdatedCallback } from '../../../hooks/useStateUpdatedCallback'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import Alert from '@mui/material/Alert'
import useIntersectionObserver from '../../../hooks/useIntersectionObserver'
import usePresignedBase64Image from '../../../hooks/usePresignedBase64Image'

const RotationButton: FC<{
  direction: 'left' | 'right'
  onClickedRotate: (degrees: number) => void
}> = ({ onClickedRotate, direction }) => {
  return (
    <ButtonBase
      sx={{
        width: '32px',
        height: '32px',
        padding: theme.spacing(0.5),
        borderRadius: '5px',
        color: theme.palette.primary.main,
        '&:hover': {
          background: hexToRgba(theme.palette.grey[400], 0.5),
        },
      }}
      onClick={() => {
        onClickedRotate(direction === 'left' ? 90 : -90)
      }}
    >
      {direction === 'right' && <RotateRightIcon sx={{ fontSize: '24px' }} />}
      {direction === 'left' && <RotateLeftIcon sx={{ fontSize: '24px' }} />}
    </ButtonBase>
  )
}

export interface InvoiceImageHandle {
  zoomInOut: (zoomIn: boolean) => void
}

export interface InvoicePageImageState {
  fileKey: string
  alt: string
  pageNumber: number
  bookInId: string | null
  odsCode: string | null
  base64?: string | null
  isLoading?: boolean
  isZoomedIn?: boolean
  isRotating?: boolean
}

type StartLoadingAction = { type: 'START_LOADING' }
type SetLoadingAction = { type: 'SET_LOADING'; payload: boolean }
type SetBase64Action = { type: 'SET_BASE64'; payload: string }
type SetIsZoomedInAction = { type: 'SET_IS_ZOOMED_IN'; payload: boolean }
type SetIsRotatingAction = { type: 'SET_IS_ROTATING'; payload: boolean }
type ResetLoadedImageAction = { type: 'RESET_LOADED_IMAGE'; payload: string }
const reducer = (
  state: InvoicePageImageState,
  action:
    | StartLoadingAction
    | SetLoadingAction
    | SetBase64Action
    | SetIsZoomedInAction
    | SetIsRotatingAction
    | ResetLoadedImageAction
) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true }
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'SET_BASE64':
      return {
        ...state,
        base64: action.payload,
        isLoading: false,
        isRotating: false,
      }
    case 'SET_IS_ZOOMED_IN':
      return state.isZoomedIn !== action.payload
        ? { ...state, isZoomedIn: action.payload }
        : state
    case 'SET_IS_ROTATING':
      return { ...state, isRotating: action.payload }
    case 'RESET_LOADED_IMAGE':
      return { ...state, base64: null, isLoading: true, src: action.payload }
  }
}

const InvoiceImage: ForwardRefRenderFunction<
  InvoiceImageHandle,
  {
    initialState: InvoicePageImageState
    onStateUpdated: (state: InvoicePageImageState) => void
    onImageLoaded: (
      bookInId: string,
      pageNumber: number,
      base64: string
    ) => void
  }
> = ({ onStateUpdated, onImageLoaded, ...props }, forwardedRef) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const innerImageHandleRef = useRef<InnerImageZoomHandle>(null)
  const [state, dispatch] = useReducer(reducer, props.initialState)
  useStateUpdatedCallback(state, onStateUpdated)
  const { platformHttpService } = useContext(ServiceContext)
  const [error, setError] = useState<string | null>(null)

  useIntersectionObserver(containerRef, () => {
    if (!state.base64) {
      dispatch({ type: 'START_LOADING' })
    }
  })

  const handleImageDownloaded = useCallback(
    (base64Image: string) => {
      dispatch({ type: 'SET_BASE64', payload: base64Image })
      onImageLoaded(state.bookInId!, state.pageNumber, base64Image)
    },
    [onImageLoaded, state.bookInId, state.pageNumber]
  )

  const handleDownloadError = useCallback(() => {
    setError(GetErrorMessage())
    dispatch({ type: 'SET_LOADING', payload: false })
  }, [])

  usePresignedBase64Image(
    PlatformApiPaths.ReadPresignedUrl(state.odsCode!, state.fileKey),
    state.isLoading ?? false,
    handleImageDownloaded,
    handleDownloadError
  )

  useImperativeHandle(forwardedRef, () => ({
    zoomInOut: (zoomIn: boolean) => {
      if (zoomIn !== state.isZoomedIn && innerImageHandleRef.current) {
        if (zoomIn) {
          // TODO: set zoom?
          // innerImageHandleRef.current.requestZoomOut()
        } else {
          innerImageHandleRef.current.requestZoomOut()
        }
      }
    },
  }))

  const handleZoomInOut = useCallback((isZoomed: boolean) => {
    dispatch({ type: 'SET_IS_ZOOMED_IN', payload: isZoomed })
  }, [])

  const handleRotate = useCallback(
    async (degrees: number) => {
      dispatch({ type: 'SET_IS_ROTATING', payload: true })
      const response = await platformHttpService.postAsync<{
        imageUrl: string | null
      }>(
        PlatformApiPaths.RotateImage(state.odsCode!, state.fileKey!, degrees),
        null,
        'StockBaseUrl'
      )
      if (!response.hasErrors) {
        dispatch({
          type: 'RESET_LOADED_IMAGE',
          payload: response.data!.imageUrl!,
        })
      } else {
        setError(GetErrorMessage(response.statusCode))
        dispatch({ type: 'SET_IS_ROTATING', payload: false })
      }
    },
    [platformHttpService, state.fileKey, state.odsCode]
  )

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        minHeight: '300px',
      }}
    >
      {(state.isLoading || state.isRotating) && <LinearProgress />}
      {error && (
        <Alert
          variant="filled"
          severity="error"
          sx={{
            flexGrow: 1,
          }}
        >
          {error}
        </Alert>
      )}
      {!error && state.base64 && (
        <InnerImageZoom
          ref={innerImageHandleRef}
          src={state.base64}
          moveType="drag"
          zoomScale={1}
          alt={state.alt}
          afterZoomIn={() => handleZoomInOut(true)}
          afterZoomOut={() => handleZoomInOut(false)}
          hideCloseButton
          hideHint
        />
      )}
      {!error && state.base64 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(1),
            position: 'absolute',
            right: theme.spacing(1),
            width: '72px',
            height: '32px',
            top: theme.spacing(1),
            borderRadius: '5px',
            background: hexToRgba(theme.palette.grey[200], 0.7),
          }}
        >
          <RotationButton direction="left" onClickedRotate={handleRotate} />
          <RotationButton direction="right" onClickedRotate={handleRotate} />
        </Box>
      )}
    </Box>
  )
}
export default forwardRef(InvoiceImage)
