import Box from '@mui/material/Box'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import theme from '../../styles/theme'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useRecoilValue } from 'recoil'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import { ClientTypes } from '../../types/entities/ClientPermission'
import { locale } from '../../locales'
import { Client, ClientCompanyWithDetails } from './entities/Client'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import Markdown from 'marked-react'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import InputSectionWithTags, {
  InputSectionWithTagsHandle,
} from '../../components/Forms/InputSectionWithTags'
import * as yup from 'yup'
import EditableSection from './components/EditableSection'
import Alert from '@mui/material/Alert'

const translations = locale.translation.SettingsPage.CompanySettingsTab

const CompanySettings: FC = () => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const [companyDetails, setCompanyDetails] = useState<Client | null>(null)
  const { isLoading, setIsLoading } = useGlobalIsLoading()
  const [error, setError] = useState<string | null>(null)
  const canAccess =
    selectedClient?.clientType === ClientTypes.Company &&
    selectedClient?.permissions?.includes('Manage')
  const [editing, setEditing] = useState<boolean>(false)
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false)
  const emailsInputSectionWithTagsHandle =
    useRef<InputSectionWithTagsHandle>(null)
  const [originalEmails, setoriginalEmails] = useState<string[]>([])

  const onUpdatedCompanyDetails = async (emails: string[]) => {
    if (selectedClient && companyDetails) {
      setError(null)
      setIsLoading(true)
      const response =
        await platformHttpService.putAsync<ClientCompanyWithDetails>(
          PlatformApiPaths.GetCompanyWithDetails(selectedClient),
          {
            complianceEmailRecipients: emails,
          },
          'ClientsBaseUri'
        )
      setIsLoading(false)
      if (!response?.hasErrors) {
      } else {
        setError(GetErrorMessage(response.statusCode))
      }
    }
  }

  useEffect(() => {
    if (canAccess) {
      setError(null)
      emailsInputSectionWithTagsHandle.current?.clearValue()
      setEditing(false)
      if (selectedClient) {
        const getAllCompanyClients = async () => {
          return await platformHttpService.getAsync<Client>(
            PlatformApiPaths.GetAllCompanyClients(selectedClient),
            'ClientsBaseUri'
          )
        }
        setIsLoading(true)
        getAllCompanyClients().then((response) => {
          setIsLoading(false)
          if (!response?.hasErrors) {
            setCompanyDetails(response.data)
            setoriginalEmails(response.data?.complianceEmailRecipients || [])
          } else {
            setError(GetErrorMessage(response.statusCode))
          }
        })
      }
    }
  }, [canAccess, platformHttpService, selectedClient, setIsLoading])

  const handleInvalidTagState = (hasInvalid: boolean) => {
    setHasInvalidEmails(hasInvalid)
  }

  return (
    <>
      {!isLoading && selectedClient && !canAccess && (
        <Alert
          sx={{ marginTop: theme.spacing(2) }}
          severity="error"
          variant="filled"
        >
          <Markdown value={translations.NoAccess} />
        </Alert>
      )}
      {canAccess && error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      {canAccess && selectedClient && companyDetails && (
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            gap: theme.spacing(2),
            padding: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <EditableSection
              onCancel={() => {
                emailsInputSectionWithTagsHandle.current?.clearValue()
                setEditing(false)
                companyDetails.complianceEmailRecipients = originalEmails
              }}
              onUpdate={() => {
                const emails =
                  emailsInputSectionWithTagsHandle.current?.getTags() ?? []
                onUpdatedCompanyDetails(
                  emails
                )
                setCompanyDetails({
                  ...companyDetails,
                  complianceEmailRecipients: emails,
                })
              }}
              sectionTitle={translations.WeeklyComplianceReportSectionTitel}
              updateDisabled={!editing || hasInvalidEmails}
              onEditingChanged={(editing) => setEditing(editing)}
              titleSx={{ fontWeight: 600 }}
              isEditing={editing}
            >
              <InputSectionWithTags
                ref={emailsInputSectionWithTagsHandle}
                contentKey={''}
                initialTags={companyDetails.complianceEmailRecipients}
                label={translations.RecipientsEmailsLabel}
                validationSchema={yup
                  .string()
                  .email(translations.ErrorEmailRequired)}
                errorMessage={translations.ErrorEmailRequired}
                isEditing={editing}
                onInvalidTagStateChange={handleInvalidTagState}
              />
            </EditableSection>
          </Box>
        </Box>
      )}
    </>
  )
}

export default CompanySettings
