import { useFormik } from 'formik'
import React, { FC, useContext, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as yup from 'yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AdjustmentReasons, AdjustmentReasonCodes } from '../../../constants'
import { locale } from '../../../locales'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { selectedClientState } from '../../../state/SelectedPharmacyState'
import { ProductCurrentStockItem } from '../entities/ProductCurrentStock'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import InputStockForm from './InputStockForm'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import LoadingButton from '@mui/lab/LoadingButton'
import theme from '../../../styles/theme'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import { ProductStockUpdateRequest } from '../entities/ProductStockUpdateMessage'
import Markdown from 'marked-react'

interface AdjustmentMenuProps {
  selectedProductDetails: ProductCurrentStockItem
  onSubmitCallback: () => void
}

interface FormValues {
  adjustedPacks: number
  adjustedUnits: number
  adjustmentReason: string
}

const translation =
  locale.translation.StockTrackingPage.TabLiveStock.AdjustmentForm
const reasonsTranslations = {
  ...locale.translation.DisposalReasons,
  ...locale.translation.InterBranchTransfer,
}
const reasons = [
  ...AdjustmentReasons[1].reasons,
  ...AdjustmentReasons[2].reasons,
].map((r) => {
  return {
    code: r.code,
    description: reasonsTranslations[r.code],
  }
})

const AdjustmentForm: FC<AdjustmentMenuProps> = ({
  selectedProductDetails,
  onSubmitCallback,
}) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const [expanded, setExpanded] = useState<string>('adjust-panel')
  const [textFieldVisible, setTextFieldVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [warning, setWarning] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleChangeAccordionSection =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded && expanded !== panel) {
        setExpanded(panel)
      } else if (!isExpanded) {
        setExpanded(panel === 'reset-panel' ? 'adjust-panel' : 'reset-panel')
      }
    }

  const handleAdjustFieldBlur = () => {
    setWarning('')
    var currentStockUnits = formik?.values?.adjustedUnits ?? 0
    let currentStockPacks = formik?.values?.adjustedPacks ?? 0

    const { currentInsertedUnits, totalNumberOfUnits } =
      CurrentAndTotalNumberOfUnits()
    if (currentInsertedUnits > totalNumberOfUnits) {
      return
    }

    //Perform Calculations
    if (currentStockUnits >= selectedProductDetails.packSize) {
      const calculatedUnits =
        currentStockUnits % selectedProductDetails.packSize
      const calculatedPacks = Math.floor(
        currentStockUnits / selectedProductDetails.packSize
      )

      currentStockPacks = currentStockPacks + calculatedPacks

      //Update
      formik.setValues({
        ...formik.values,
        adjustedPacks: currentStockPacks,
        adjustedUnits: calculatedUnits,
      })

      setWarning(
        translation.Notifications.NotificationAlreadyCalculatedSplit(
          currentStockUnits,
          calculatedPacks,
          calculatedUnits
        )
      )
    }
  }

  //current units number inserted and total number from db
  function CurrentAndTotalNumberOfUnits() {
    const currentInsertedUnits =
      formik?.values?.adjustedPacks * selectedProductDetails.packSize +
      formik?.values?.adjustedUnits
    const totalNumberOfUnits =
      selectedProductDetails.stockPacks * selectedProductDetails.packSize +
      selectedProductDetails.currentStockUnits
    return { currentInsertedUnits, totalNumberOfUnits }
  }

  // When the Adjustment Reason is selected, show the TextField
  const handleSelectionChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    setFormikFieldValue('adjustmentReason', value)
    setTextFieldVisible(Boolean(value))
    validateFormikField('adjustmentReason')
  }

  // On Submit send requests needed for creating an Adjustemnt
  // It will be only DISPOSALS type Adjustemnts
  const handleSubmit = async (formValues: FormValues) => {
    if (!selectedProductDetails) {
      return
    }
    setLoading(true)
    //Case - user inserts packs and units more than the total  units from db
    const { currentInsertedUnits, totalNumberOfUnits } =
      CurrentAndTotalNumberOfUnits()
    if (formValues.adjustmentReason !== AdjustmentReasonCodes.StockComingIn) {
      if (currentInsertedUnits > totalNumberOfUnits) {
        setFormikFieldError(
          'adjustedPacks',
          translation.Validation
            .AdjustedPacksOrUnitsValidationBiggerThanTotalNumber
        )
        setFormikFieldError(
          'adjustedUnits',
          translation.Validation
            .AdjustedPacksOrUnitsValidationBiggerThanTotalNumber
        )

        setError(
          translation.Validation.AdjustedPacksOrUnitsValidationBiggerThanTotalNumberAlert(
            totalNumberOfUnits
          )
        )
        setLoading(false)
        return
      }
    }

    const body: ProductStockUpdateRequest = {
      productId: selectedProductDetails.productId,
      packSize: selectedProductDetails.packSize,
      actionType: 'ADJUSTMENT',
      adjustmentReasonCode: formValues.adjustmentReason,
      packsQuantity: formValues.adjustedPacks,
      unitsQuantity: formValues.adjustedUnits,
    }
    const response = await platformHttpService.postAsync(
      PlatformApiPaths.AdjustProductStock(selectedClient?.clientId!),
      body,
      'StockBaseUrl'
    )
    if (!response.hasErrors) {
      onSubmitCallback()
      setLoading(false)
    } else {
      setError(GetErrorMessage(response.statusCode))
      setLoading(false)
    }
  }

  // Set up formik
  const {
    setFieldValue: setFormikFieldValue,
    validateField: validateFormikField,
    setFieldError: setFormikFieldError,
    isValid: formikIsValid,
    ...formik
  } = useFormik({
    validateOnMount: true,
    initialValues: {
      adjustedPacks: 0,
      adjustedUnits: 0,
      adjustmentReason: '',
    },
    validationSchema: yup.object({
      adjustmentReason: yup.string().required(''),
      adjustedPacks: yup
        .number()
        .integer(translation.Validation.AdjustedPacksOrUnitsValidationInteger)
        .required(translation.Validation.AdjustedPacksOrUnitsValidationRequired)
        .min(0, translation.Validation.AdjustedPacksOrUnitsValidationMin)
        .when('adjustmentReason', ([adjustmentReason], schema) => {
          return adjustmentReason === AdjustmentReasonCodes.StockComingIn
            ? schema
            : schema.max(
                selectedProductDetails!.stockPacks!,
                translation.Validation.AdjustedPacksValidationMax
              )
        }),
      adjustedUnits: yup
        .number()
        .integer(translation.Validation.AdjustedPacksOrUnitsValidationInteger)
        .required(translation.Validation.AdjustedPacksOrUnitsValidationRequired)
        .min(0, translation.Validation.AdjustedPacksOrUnitsValidationMin)
        .when('adjustedPacks', ([adjustedPacks], schema) => {
          return adjustedPacks === 0 ? schema.not([0], '') : schema
        })
        .when('adjustmentReason', ([adjustmentReason], schema) => {
          return adjustmentReason === AdjustmentReasonCodes.StockComingIn
            ? schema
            : schema.lessThan(
                selectedProductDetails!.packSize!,
                translation.Validation.AdjustedUnitsValidationMax
              )
        }),
    }),
    onSubmit: (values) => handleSubmit(values),
  })
  return (
    <Box sx={{ paddingBottom: theme.spacing(2) }}>
      <Typography variant="h6" sx={{ fontSize: '1.2em' }} align="center">
        {translation.ProductTitle(
          selectedProductDetails!.productName!,
          selectedProductDetails!.packSize!,
          selectedProductDetails!.unitOfMeasure,
          selectedProductDetails!.subPackDescription
        )}
      </Typography>
      <Typography
        variant="h5"
        sx={{ paddingBottom: theme.spacing(2), fontSize: '1em' }}
        align="center"
      >
        <Markdown isInline={true}>
          {translation.ProductDescription(
            selectedProductDetails!.stockPacks!,
            selectedProductDetails!.splitPacksUnits!
          )}
        </Markdown>
      </Typography>
      <Accordion
        disableGutters={true}
        elevation={0}
        expanded={
          !selectedProductDetails.hasBeenReset || expanded === 'reset-panel'
        }
        onChange={handleChangeAccordionSection('reset-panel')}
        sx={{
          '&.Mui-expanded': {
            marginBottom: theme.spacing(2),
          },
          '&:before': {
            display: 'none',
          },
          marginBottom: theme.spacing(2),
          background:
            expanded === 'reset-panel'
              ? theme.palette.grey[50]
              : theme.palette.common.white,
          borderRadius: theme.spacing(0.5),
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                fontSize={14}
                color={theme.palette.primary.main}
              >
                {translation.AccordionReset}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontSize={12}
                color={theme.palette.common.black}
                alignItems="center"
                justifyContent="flex-start"
              >
                {translation.AccordionDescriptionReset}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingX: theme.spacing(1) }}>
          <InputStockForm
            product={selectedProductDetails}
            onSubmitCallback={onSubmitCallback}
            isReset={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        disableGutters={true}
        expanded={
          selectedProductDetails.hasBeenReset && expanded === 'adjust-panel'
        }
        onChange={handleChangeAccordionSection('adjust-panel')}
        defaultExpanded={true}
        sx={{
          '&.Mui-expanded': {
            marginBottom: theme.spacing(2),
          },
          '&:before': {
            display: 'none',
          },
          marginBottom: theme.spacing(2),
          opacity: !selectedProductDetails.hasBeenReset ? 0.5 : 1,
          background:
            expanded === 'adjust-panel'
              ? theme.palette.grey[50]
              : theme.palette.common.white,
          borderRadius: theme.spacing(0.5),
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                fontSize={14}
                color={theme.palette.primary.main}
              >
                {translation.AccordionAdjust}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontSize={12}
                color={theme.palette.common.black}
                alignItems="center"
                justifyContent="flex-start"
              >
                {translation.AccordionDescription}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingX: theme.spacing(3),
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
              justifyContent: 'center',
              maxHeight: '150x',
              maxWidth: '500px',
              gap: theme.spacing(2),
              '&:focus-visible': { outline: 'none' },
              boxShadow: 'none',
            }}
            component={'form'}
            onSubmit={formik.handleSubmit}
          >
            <FormControl>
              <InputLabel id="select-adjustment-form-label">
                {translation.Fields.SelectAdjustmentReason.Label}
              </InputLabel>
              <Select
                value={formik.values.adjustmentReason}
                onChange={(e) => {
                  handleSelectionChange(e)
                }}
                error={Boolean(formik.errors.adjustmentReason)}
                data-testid="disposal-or-interbranch-reason-select"
                labelId="select-adjustment-form-label"
                label={translation.Fields.SelectAdjustmentReason.Label}
              >
                {reasons.map((cr) => (
                  <MenuItem key={cr.code} value={cr.code}>
                    {cr.description}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2">
                {formik.errors.adjustmentReason}
              </Typography>
            </FormControl>
            {textFieldVisible && (
              <>
                <TextField
                  data-testid="adjust-packs-input-field"
                  id="adjustedPacks"
                  name="adjustedPacks"
                  value={formik.values.adjustedPacks}
                  label={
                    formik.values.adjustmentReason ===
                    AdjustmentReasonCodes.StockComingIn
                      ? translation.Fields.AdjustedPacks.Label(false)
                      : translation.Fields.AdjustedPacks.Label(true)
                  }
                  onChange={formik.handleChange}
                  onBlur={handleAdjustFieldBlur}
                  error={Boolean(formik.errors.adjustedPacks)}
                  helperText={formik.errors.adjustedPacks}
                  type="number"
                  sx={{ textAlign: 'center' }}
                />
                <TextField
                  data-testid="adjust-units-input-field"
                  id="adjustedUnits"
                  name="adjustedUnits"
                  value={formik.values.adjustedUnits}
                  label={
                    formik.values.adjustmentReason ===
                    AdjustmentReasonCodes.StockComingIn
                      ? translation.Fields.AdjustedUnits.Label(false)
                      : translation.Fields.AdjustedUnits.Label(true)
                  }
                  onChange={formik.handleChange}
                  onBlur={handleAdjustFieldBlur}
                  error={Boolean(formik.errors.adjustedUnits)}
                  helperText={formik.errors.adjustedUnits}
                  type="number"
                  sx={{ textAlign: 'center' }}
                />
              </>
            )}
            <LoadingButton
              data-testid="complete-adjusting-stock-button"
              type="submit"
              disableElevation
              variant="contained"
              color="primary"
              loading={loading}
              disabled={!formikIsValid}
              sx={{
                minWidth: '160px',
              }}
            >
              {translation.Buttons.Submit(
                formik.values.adjustedPacks,
                formik.values.adjustedUnits
              )}
            </LoadingButton>
            {warning && (
              <Alert variant="filled" severity="warning">
                {warning}
              </Alert>
            )}
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default AdjustmentForm
