export enum InvoiceType {
  JPG = 'image/jpeg',
  PDF = 'application/pdf',
}

export const invoiceTypeToExtension = (invoiceType: InvoiceType | null): string => {
  switch (invoiceType) {
    case InvoiceType.JPG:
      return 'jpg'
    case InvoiceType.PDF:
      return 'pdf'
  }
  return ''
}
