import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { FC, useCallback } from 'react'
import { Accept, FileRejection, useDropzone } from 'react-dropzone'
import theme from '../../styles/theme'

const getColor = (props: any, isBorder: boolean) => {
  if (props.isDragAccept) {
    return theme.palette.success.main
  }
  if (props.isDragReject) {
    return theme.palette.error.main
  }
  // if (props.isFocused) {
  //   return theme.palette.primary.main
  // }
  return isBorder ? theme.palette.grey[500] : theme.palette.grey[300]
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props, true)};
  border-style: dashed;
  background-color: ${(props) => getColor(props, false)};
  color: #000;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`

interface FileUploadProps {
  dropzoneText: string
  dragRejectText: string
  accept: Accept
  onAcceptedFiles: (files: File[]) => void
  onRejectedFiles?: (rejections: FileRejection[]) => void
  multiple?: boolean
  disabled?: boolean
  isUploading?: boolean
}

const FileUpload: FC<FileUploadProps> = ({
  accept,
  dropzoneText,
  dragRejectText,
  onAcceptedFiles,
  multiple,
  onRejectedFiles,
  disabled,
  isUploading,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onAcceptedFiles(acceptedFiles)
    },
    [onAcceptedFiles]
  )

  const onReject = useCallback(
    (rejections: FileRejection[]) => {
      if (onRejectedFiles) {
        onRejectedFiles(rejections)
      }
    },
    [onRejectedFiles]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDrop,
      onDropRejected: onReject,
      multiple,
      disabled,
    })

  return (
    <Box data-testid="file-upload-container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {!isDragReject && <p style={{ textAlign: 'center' }}>{dropzoneText}</p>}
        {isDragReject && <p>{dragRejectText}</p>}
      </Container>
      {isUploading && <LinearProgress sx={{ marginTop: theme.spacing(1) }} />}
    </Box>
  )
}

export default FileUpload
