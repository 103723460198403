import ButtonBase from '@mui/material/ButtonBase'
import { FC, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'
import theme from '../../styles/theme'
import Popper from '@mui/material/Popper'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const InfoTooltip: FC<{
  text: string | null
  mode?: 'info' | 'warning' | 'error'
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
  tooltipSx?: SxProps<Theme>
  colorOverride?: string
  foregroundColorOverride?: string
  useColorWhenNotActive?: boolean
}> = ({
  text,
  mode = 'info',
  sx,
  iconSx,
  tooltipSx,
  colorOverride,
  foregroundColorOverride,
  useColorWhenNotActive,
}) => {
  const [infoAnchorEl, setInfoAnchorEl] = useState<null | HTMLElement>(null)
  const [infoText, setInfoText] = useState<string | null>(null)
  const infoOpen = Boolean(infoAnchorEl)
  const infoId = infoOpen ? 'infoButton' : undefined

  const handleInfoClick = (
    event: React.MouseEvent<HTMLElement>,
    infoText: string | null
  ) => {
    setInfoAnchorEl(infoAnchorEl ? null : event.currentTarget)
    setInfoText(infoText)
  }

  const backgroundColor =
    colorOverride ??
    (mode === 'info'
      ? theme.palette.info.main
      : mode === 'warning'
      ? theme.palette.warning.main
      : theme.palette.error.main)

  const foregroundColor = foregroundColorOverride ?? theme.palette.common.white

  return (
    <Box sx={sx}>
      <Popper
        id={infoId}
        open={infoOpen}
        anchorEl={infoAnchorEl}
        sx={{
          ...tooltipSx,
          zIndex: 10001,
        }}
      >
        <Box
          sx={{
            borderRadius: '3px',
            backgroundColor: backgroundColor,
            color: foregroundColor,
            padding: theme.spacing(1),
          }}
        >
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {infoText}
          </Typography>
        </Box>
      </Popper>
      <ButtonBase
        disableRipple
        sx={{
          display: 'flex',
          justifyContent: 'center',
          color: useColorWhenNotActive
            ? backgroundColor
            : theme.palette.grey[500],
          '&:hover': { color: backgroundColor },
        }}
        onClick={(e) => handleInfoClick(e, text ?? null)}
        onMouseEnter={(e) => handleInfoClick(e, text ?? null)}
        onMouseLeave={() => setInfoAnchorEl(null)}
      >
        <>
          {mode === 'info' && <InfoIcon sx={iconSx} />}
          {mode === 'warning' && <WarningIcon sx={iconSx} />}
          {mode === 'error' && <ErrorIcon sx={iconSx} />}
        </>
      </ButtonBase>
    </Box>
  )
}
export default InfoTooltip
