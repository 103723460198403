import { DateTime } from 'luxon'
import { getLocaleDateMedWithoutWeekDay } from '../../utils/Helpers'
import { MonthEndReconciliationStatementDate } from './entities/mer-entities'

export const stringFromStatementDate = (
  statementDateObj: MonthEndReconciliationStatementDate | null,
  statementDate: string | null
) => {
  if (!statementDate) {
    return null
  }
  let fullDateIso = statementDate
  if (statementDateObj) {
    if (statementDateObj.day) {
      fullDateIso = DateTime.fromJSDate(
        new Date(
          statementDateObj.year,
          statementDateObj.month - 1,
          statementDateObj.day
        )
      ).toFormat('yyyy-MM-dd')
    } else {
      return `${DateTime.fromISO(statementDate).monthShort} ${
        statementDateObj.year
      }`
    }
  }
  return getLocaleDateMedWithoutWeekDay(fullDateIso)
}
