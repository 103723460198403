import { FC, useCallback, useContext, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { stockrxUserState } from '../../state/AuthenticationState'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import {
  ClientSelection,
  ClientTypes,
} from '../../types/entities/ClientPermission'
import { User } from '../../types/entities/User'
import theme from '../../styles/theme'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import { SxProps, Theme } from '@mui/material/styles'
import { locale } from '../../locales'
import { ServiceContext } from '../../providers/ServicesProvider'
import { orderBy } from 'lodash'

const translation = locale.translation.ClientSelect

const listSubHeaderStyle: SxProps<Theme> = {
  lineHeight: 'inherit',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}

const menuItemStyle: SxProps<Theme> = {
  marginLeft: theme.spacing(1),
}

const getAllowedClients = (
  user: User | null,
  clientType?: string
): ClientSelection[] => {
  const result: ClientSelection[] = []
  const clients =
    (clientType
      ? user?.clientPermissions.filter(
          (u) => u.clientType === clientType && u.permissions.length > 0
        )
      : user?.clientPermissions.filter((u) => u.permissions.length > 0)) ?? []

  for (const client of clients) {
    result.push({
      ...client,
      setFeatureFlags: client.setFeatureFlags ?? [],
      internalSupplierIds: client.internalSupplierIds ?? {},
    })
  }
  return orderBy(result, (r) => r.name)
}

const ClientSelect: FC = () => {
  const user = useRecoilValue(stockrxUserState)
  const selectedClient = useRecoilValue(selectedClientState)
  const setSelectedClient = useSetRecoilState(selectedClientState)
  const [storedClientSelection, setStoredClientSelection] =
    useLocalStorage('selectedClient')
  const { notificationService } = useContext(ServiceContext)

  const handleSelectPharmacy = useCallback(
    (client: ClientSelection | null) => {
      setSelectedClient(client)
      setStoredClientSelection(JSON.stringify(client))
    },
    [setSelectedClient, setStoredClientSelection]
  )

  useEffect(() => {
    const allowedClients = user ? getAllowedClients(user) : []
    if (allowedClients.length > 0 && !selectedClient) {
      let selection: ClientSelection | null = null
      if (storedClientSelection) {
        const storedClientSelectionObject: ClientSelection = JSON.parse(
          storedClientSelection
        )
        selection =
          allowedClients.find(
            (p) => p.clientId === storedClientSelectionObject.clientId
          ) ?? null
      }
      if (!selection) {
        selection = allowedClients[0]
      }
      handleSelectPharmacy(selection)
    }
  }, [user, selectedClient, storedClientSelection, handleSelectPharmacy])

  const handleChange = (event: SelectChangeEvent) => {
    const allowedClients = user ? getAllowedClients(user) : []
    const selectedClient =
      allowedClients.find((p) => p.clientId === event.target.value) ?? null
    notificationService.clearAllNotifictions()
    handleSelectPharmacy(selectedClient)
  }

  return (
    <>
      <FormControl size="small">
        <Box>
          <Select
            value={selectedClient?.clientId ?? ''}
            id="pharmacy-select"
            onChange={handleChange}
            sx={{ minWidth: '200px' }}
            data-testid="pharmacy-select"
          >
            {getAllowedClients(user, ClientTypes.Company).length > 0 && (
              <ListSubheader
                sx={listSubHeaderStyle}
                data-testid="client-type-header-companies"
              >
                {translation.Company}
              </ListSubheader>
            )}
            {getAllowedClients(user, ClientTypes.Company).map(
              (clientSelection) => (
                <MenuItem
                  key={clientSelection.clientId}
                  value={clientSelection.clientId}
                >
                  <Box sx={menuItemStyle} component={'span'}>
                    {clientSelection.name}
                  </Box>
                  {clientSelection.clientType === ClientTypes.Pharmacy && (
                    <Box
                      component={'span'}
                      sx={{ marginLeft: theme.spacing(1) }}
                    >
                      [{clientSelection.clientId}]
                    </Box>
                  )}
                </MenuItem>
              )
            )}
            {getAllowedClients(user, ClientTypes.PharmacyGroup).length > 0 && (
              <ListSubheader
                sx={listSubHeaderStyle}
                data-testid="client-type-header-groups"
              >
                {translation.PharmacyGroup}
              </ListSubheader>
            )}
            {getAllowedClients(user, ClientTypes.PharmacyGroup).map(
              (clientSelection) => (
                <MenuItem
                  key={clientSelection.clientId}
                  value={clientSelection.clientId}
                >
                  <Box sx={menuItemStyle} component={'span'}>
                    {clientSelection.name}
                  </Box>
                  {clientSelection.clientType === ClientTypes.Pharmacy && (
                    <Box
                      component={'span'}
                      sx={{ marginLeft: theme.spacing(1) }}
                    >
                      [{clientSelection.clientId}]
                    </Box>
                  )}
                </MenuItem>
              )
            )}
            <ListSubheader
              sx={listSubHeaderStyle}
              data-testid="client-type-header-pharmacies"
            >
              {translation.Pharmacy}
            </ListSubheader>
            {getAllowedClients(user, ClientTypes.Pharmacy).map(
              (clientSelection) => (
                <MenuItem
                  key={clientSelection.clientId}
                  value={clientSelection.clientId}
                >
                  <Box sx={menuItemStyle} component={'span'}>
                    {clientSelection.name}
                  </Box>
                  {clientSelection.clientType === ClientTypes.Pharmacy && (
                    <Box
                      component={'span'}
                      sx={{ marginLeft: theme.spacing(1) }}
                    >
                      [{clientSelection.clientId}]
                    </Box>
                  )}
                </MenuItem>
              )
            )}
          </Select>
        </Box>
      </FormControl>
    </>
  )
}

export default ClientSelect
