import Box from '@mui/material/Box'
import { FC } from 'react'
import theme from '../../styles/theme'
import { usePageTitle } from '../../hooks/usePageTitle'
import { deliveriesPageSelectedTabState } from './recoil-state'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { locale } from '../../locales'
import TabNewAndPendingDeliveries from './TabNewAndPendingDeliveries'
import TabDeliveryHistory from './TabDeliveryHistory'
import TabCreditsForAction from './TabCreditsForAction'
import TabBookInHealthscore from './TabBookInHealthscore'
import {
  featureFlagsForSelectedClientState,
  userPermissionsForSelectedClientState,
} from '../../state/AuthenticationState'
import { PermissionNames, PharmacyFeatureFlags } from '../../constants'
import { useSuppliersWithFinancials } from '../../hooks/useSuppliers'
import Alert from '@mui/material/Alert'

const translation = locale.translation.GoodsInPage
const GoodsInPage: FC = () => {
  const selectedTabIndex = useRecoilValue(deliveriesPageSelectedTabState)
  const setSelectedTabIndex = useSetRecoilState(deliveriesPageSelectedTabState)

  const clientFeatureFlags = useRecoilValue(featureFlagsForSelectedClientState)
  const clientUserPermissions = useRecoilValue(
    userPermissionsForSelectedClientState
  )

  // call to cache suppliers, as any opened book-in will need it
  useSuppliersWithFinancials()
  usePageTitle(locale.translation.Navigation.Links.GoodsIn)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue)
  }

  const isFeatureDisabled = (featureFlag: string) => {
    return !clientFeatureFlags?.setFeatureFlags.includes(featureFlag)
  }

  const isPermissionDisabled = (permission: string) => {
    return !clientUserPermissions?.includes(permission)
  }

  const renderTabOrNotEnabled = (
    featureFlag: string,
    permission: string,
    tab: JSX.Element
  ) => {
    const disabledFeature = isFeatureDisabled(featureFlag)
    const disabledPermission = isPermissionDisabled(permission)
    if (disabledFeature || disabledPermission) {
      return (
        <Box sx={{ paddingY: theme.spacing(1) }}>
          <Alert severity="error" variant="filled">
            {disabledFeature
              ? locale.translation.Common.FeatureNotEnabled(
                  clientFeatureFlags?.clientName
                )
              : locale.translation.Common.NoPermissions}
          </Alert>
        </Box>
      )
    } else {
      return tab
    }
  }

  return (
    <Box
      sx={{
        paddingX: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTabIndex} onChange={handleTabChange}>
          <Tab
            label={translation.Tabs.Pending}
            data-testid="pending-deliveries-tab"
          />
          <Tab
            label={translation.Tabs.History}
            data-testid="delivery-history-tab"
          />
          <Tab
            label={translation.Tabs.CreditsForAction}
            data-testid="credits-tab"
            disabled={isFeatureDisabled(PharmacyFeatureFlags.EnableCredits)}
          />
          <Tab
            label={translation.Tabs.BookInHealthscore}
            data-testid="bookin-healthscore-tab"
            disabled={isPermissionDisabled(PermissionNames.BookInHealthscore)}
          />
        </Tabs>
      </Box>
      <>
        {selectedTabIndex === 0 && <TabNewAndPendingDeliveries />}
        {selectedTabIndex === 1 && <TabDeliveryHistory />}
        {selectedTabIndex === 2 &&
          renderTabOrNotEnabled(
            PharmacyFeatureFlags.EnableCredits,
            PermissionNames.Stock,
            <TabCreditsForAction />
          )}
        {selectedTabIndex === 3 &&
          renderTabOrNotEnabled(
            PharmacyFeatureFlags.EnableBookInHealthScore,
            PermissionNames.BookInHealthscore,
            <TabBookInHealthscore />
          )}
      </>
    </Box>
  )
}

export default GoodsInPage
