import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { CognitoAuthService } from '../services/CognitoAuthService'
import { PlatformApiHttpService } from '../services/PlatformApiHttpService'
import { RecoilRoot } from 'recoil'
import { ServiceProvider } from '../providers/ServicesProvider'
import { HttpServiceBase } from '../services/HttpServiceBase'
import WebSocketConnector from '../components/WebSockets/WebSocketConnector'
import Header from '../components/Navigation/Header'
import { SnackbarProvider } from 'notistack'
import { NavigationService } from '../services/NavigationService'
import { NotificationService } from '../services/NotificationService'
import { IntercomChatService } from '../services/IntercomChatService'
import Grow from '@mui/material/Grow'
import Container from '@mui/material/Container'
import { initializeOtlp } from '../otlp/otlp-configuration'
import { GoogleAnalyticsService } from '../services/GoogleAnalyticsService'
import "../styles/app.css"

const authenticationService = new CognitoAuthService()
const platformHttpService = new PlatformApiHttpService(authenticationService)
const baseHttpService = new HttpServiceBase()
const notificationService = new NotificationService()
const chatService = new IntercomChatService()
const googleAnalyticsService = new GoogleAnalyticsService()

initializeOtlp(authenticationService)

const RootRoute: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const navigationService = new NavigationService(navigate, location)

  useEffect(() => {
    notificationService.clearAllNotifictions()
  }, [location])

  return (
    <RecoilRoot>
      <SnackbarProvider TransitionComponent={Grow}>
        <ServiceProvider
          baseHttpService={baseHttpService}
          platformHttpService={platformHttpService}
          authenticationService={authenticationService}
          navigationService={navigationService}
          notificationService={notificationService}
          chatService={chatService}
          analyticsService={googleAnalyticsService}
        >
          <WebSocketConnector>
            <Container
              disableGutters={true}
              maxWidth={false}
              sx={{
                bgcolor: 'white',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Header></Header>
              <Outlet />
            </Container>
          </WebSocketConnector>
        </ServiceProvider>
      </SnackbarProvider>
    </RecoilRoot>
  )
}

export default RootRoute
