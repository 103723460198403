import { ClientSelection, ClientTypes } from './types/entities/ClientPermission'

const clientSelectionToPath = (clientSelection: ClientSelection): string => {
  let pathPrefix = ''
  switch (clientSelection.clientType) {
    case ClientTypes.Company:
      pathPrefix = 'companies'
      break
    case ClientTypes.PharmacyGroup:
      pathPrefix = 'pharmacygroups'
      break
    case ClientTypes.Pharmacy:
      pathPrefix = 'pharmacies'
      break
  }
  return `${pathPrefix}/${clientSelection.clientId}`
}

export const PlatformApiPaths = {
  GetMe: (bustCache: boolean) =>
    `users/mehq${bustCache ? '?bustCache=true' : ''}`,
  GetSuppliersPerId: 'suppliers/unique-per-id',
  GetSuppliersForUpload: 'suppliers/with-upload-metadata',
  GetSuppliersWithFinancials: 'suppliers/with-financials',

  GetUserWithPermissions: (
    clientSelection: ClientSelection,
    authId: string,
    bustCache: boolean
  ) =>
    `users/${clientSelectionToPath(clientSelection)}/${authId}${
      bustCache ? '?bustCache=true' : ''
    }`,

  GetPharmacyWithDetails: (clientSelection: ClientSelection) =>
    `clients/${clientSelectionToPath(clientSelection)}/pharmacy-details`,

  CreateUpdateUser: (clientSelection: ClientSelection) =>
    `users/${clientSelectionToPath(clientSelection)}`,

  DeleteUser: (clientSelection: ClientSelection, authId: string) =>
    `users/${clientSelectionToPath(clientSelection)}/${authId}`,

  ForceChangeUserPassword: (clientSelection: ClientSelection) =>
    `users/${clientSelectionToPath(
      clientSelection
    )}/resend-force-change-password`,

  GetAllUsersByCompanyId: (clientSelection: ClientSelection) =>
    `users/${clientSelectionToPath(clientSelection)}`,

  GetAllCompanyClients: (clientSelection: ClientSelection) =>
    `clients/${clientSelectionToPath(clientSelection)}`,

  GetCompanyWithDetails: (clientSelection: ClientSelection) =>
    `clients/${clientSelectionToPath(clientSelection)}/company-details`,

  CreatePharmacyGroup: (clientSelection: ClientSelection) =>
    `clients/${clientSelectionToPath(clientSelection)}/create-group`,

  UpdateDeletePharmacyGroup: (
    clientSelection: ClientSelection,
    groupId: string
  ) => `clients/${clientSelectionToPath(clientSelection)}/groups/${groupId}`,

  // Forecasts
  // GetForecasts: (clientSelection: ClientSelection) =>
  //   `${clientSelectionToPath(clientSelection)}/forecasts`,

  // Dispenses
  GetDispenseUploads: (clientSelection: ClientSelection) =>
    `${clientSelectionToPath(clientSelection)}/dispenses/uploads`,

  PostDispensesRequestUpload: (odsCode: string) =>
    `pharmacies/${odsCode}/dispenses/uploads/request`,

  PostDispensesCompleteUpload: (odsCode: string, uploadId: string) =>
    `pharmacies/${odsCode}/dispenses/uploads/complete/${uploadId}`,

  GetDispenseUploadForPharmacyByUploadId: (odsCode: string, uploadId: string) =>
    `pharmacies/${odsCode}/dispenses/uploads/${uploadId}`,

  // Commercials
  GetAllProductCommercials: (clientSelection: ClientSelection) =>
    `${clientSelectionToPath(
      clientSelection
    )}/stock/computations/products/commercials`,

  GetProductCommercialsInvoiceDetails: (
    clientSelection: ClientSelection,
    productId: string
  ) => {
    return `${clientSelectionToPath(
      clientSelection
    )}/stock/computations/products/${productId}/commercials`
  },

  GetGroupedProductsCommercialsInvoiceDetails: (
    clientSelection: ClientSelection
  ) => {
    return `${clientSelectionToPath(
      clientSelection
    )}/stock/computations/products/commercials/grouped`
  },

  GetProductCommercialsPriceDetails: (
    clientSelection: ClientSelection,
    productId: string
  ) => {
    return `${clientSelectionToPath(
      clientSelection
    )}/stock/computations/products/${productId}/commercials/prices`
  },

  GetProductCommercialsSupplierDetails: (clientSelection: ClientSelection) => {
    return `${clientSelectionToPath(
      clientSelection
    )}/stock/computations/products/commercials/suppliers`
  },

  GetCommercialsCSV: (selectedClient: ClientSelection) =>
    `${clientSelectionToPath(
      selectedClient
    )}/stock/computations/products/commercials/export`,

  GetProductInvoiceDetailsCSV: (
    selectedClient: ClientSelection,
    productId: string
  ) =>
    `${clientSelectionToPath(
      selectedClient
    )}/stock/computations/products/${productId}/commercials/export`,

  // Credits
  GetCredits: (clientSelection: ClientSelection) =>
    `${clientSelectionToPath(
      clientSelection
    )}/stock/management/adjustments/credits`,

  GetAdjustmentById: (odsCode: string, adjustmentId: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments/${adjustmentId}`,

  GetNotesByAdjustmentId: (odsCode: string, adjustmentId: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments/${adjustmentId}/notes`,

  SetAdjustmentActioned: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments/actioned`,

  SetAdjustmentNotes: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments/notes`,

  GetCreditsCSV: (selectedClient: ClientSelection) =>
    `${clientSelectionToPath(
      selectedClient
    )}/stock/management/adjustments/credits/export`,

  // Adjustments (Used for disposals in Stock Tracking)
  BeginAdjustment: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments`,
  UpdateAdjustment: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments`,
  CompleteAdjustment: (odsCode: string, adjustmentId: string) =>
    `pharmacies/${odsCode}/stock/management/adjustments/${adjustmentId}`,

  // BookIns
  GetPendingDeliveries: (
    clientSelection: ClientSelection,
    queryString: string
  ) =>
    `${clientSelectionToPath(
      clientSelection
    )}/stock/management/bookins/pending?${queryString}`,

  GetCompletedDeliveries: (
    clientSelection: ClientSelection,
    queryString: string
  ) =>
    `${clientSelectionToPath(
      clientSelection
    )}/stock/management/bookins/completed?${queryString}`,

  GetCompletedDeliveriesWithBookInHealthscores: (
    clientSelection: ClientSelection,
    queryString: string
  ) =>
    `${clientSelectionToPath(
      clientSelection
    )}/stock/management/bookins/healthscore?${queryString}`,

  GetBookIn: (odsCode: string, bookInId: string, setInvoiceUrls: boolean) =>
    `pharmacies/${odsCode}/stock/management/bookins/${bookInId}?setInvoiceUrls=${setInvoiceUrls}`,

  CreateBookIn: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/bookins`,

  RequestInvoiceUpload: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/invoices`,

  RotateImage: (odsCode: string, imageKey: string, degrees: number) =>
    `pharmacies/${odsCode}/stock/management/bookins/rotate?imageKey=${encodeURIComponent(
      imageKey
    )}&rotation=${degrees}`,

  ProcessInvoices: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/ocr`,

  TriggerAsyncProcessInvoices: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/ocr-async`,

  DeleteBookIn: (odsCode: string, bookInId: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/${bookInId}`,

  ReEditBookIn: (odsCode: string, bookInId: string, mode?: string) => {
    let result = `pharmacies/${odsCode}/stock/management/bookins/${bookInId}/re-edit`
    if (mode) {
      result = `${result}?mode=${encodeURIComponent(mode)}`
    }
    return result
  },

  CreateUpdateDeleteProduct: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/invoices/product`,

  UpdateInvoice: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/invoices`,

  CompleteDeleteDelivery: (odsCode: string, bookInId: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/${bookInId}`,

  ReadPresignedUrl: (odsCode: string, url: string) =>
    `pharmacies/${odsCode}/stock/management/bookins/invoices/url?rawPath=${encodeURIComponent(
      url
    )}`,

  GetInvoiceNumberUnique: (
    odsCode: string,
    bookInId: string,
    supplierId: string,
    invoiceNumber: string
  ) =>
    `pharmacies/${odsCode}/stock/management/bookins/invoices/isuniqueforsupplier?bookInId=${encodeURIComponent(
      bookInId
    )}&supplierId=${encodeURIComponent(
      supplierId
    )}&invoiceNumber=${encodeURIComponent(invoiceNumber)}`,

  GetBookInsForInvoiceNumberAndSupplier: (
    odsCode: string,
    supplierId: string,
    invoiceNumber: string
  ) =>
    `pharmacies/${odsCode}/stock/management/bookins/invoices/with-invoice-number?supplierId=${encodeURIComponent(
      supplierId
    )}&invoiceNumber=${encodeURIComponent(invoiceNumber)}`,

  // Products

  GetProductPacksById: (productId: string) => `products/${productId}/packs`,

  ProductSearch: (queryString: string) => `products/search?${queryString}`,

  ProductPackSizes: (productName: string) =>
    `products/packs?productName=${encodeURIComponent(productName)}`,

  // Stock Tracking
  GetAllTrackedProducts: (odsCode: string, rollConcessionsOver: boolean) =>
    `pharmacies/${odsCode}/stock/tracking?rollconcessionsover=${rollConcessionsOver}`,

  ResetProductStock: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/tracking/reset`,

  AdjustProductStock: (odsCode: string) =>
    `pharmacies/${odsCode}/stock/tracking/adjust`,

  GetTrackedProductStock: (
    odsCode: string,
    productId: string,
    packSize: number,
    rollConcessionsOver: boolean
  ) =>
    `pharmacies/${odsCode}/stock/tracking/${productId}/${packSize}?rollconcessionsover=${rollConcessionsOver}`,

  // Month end reconciliation

  MerHistory: (clientSelection: ClientSelection) =>
    `${clientSelectionToPath(clientSelection)}/mer/history`,

  GetMerStatement: (odsCode: string, merId: string) =>
    `pharmacies/${odsCode}/mer/${merId}`,

  NewMonthEndReconciliation: (odsCode: string) =>
    `pharmacies/${odsCode}/mer/new`,
  RequestUploadMerStatement: (odsCode: string) =>
    `pharmacies/${odsCode}/mer/request-upload`,
  PreprocessMerPdf: (odsCode: string) => `pharmacies/${odsCode}/mer/pdf`,
  OcrProcessMerStatement: (odsCode: string) => `pharmacies/${odsCode}/mer/ocr`,
  ProcessMerStatement: (odsCode: string, merId: string) =>
    `pharmacies/${odsCode}/mer/process/${merId}`,

  UpdateDeleteMer: (odsCode: string, merId: string) =>
    `pharmacies/${odsCode}/mer/${merId}`,
  GetMerHistoryItem: (odsCode: string, merId: string) =>
    `pharmacies/${odsCode}/mer/history/${merId}`,
  UpdateNote: (odsCode: string, merId: string) =>
    `pharmacies/${odsCode}/mer/note/${merId}`,
  GetMerStatementPdfUrl: (odsCode: string, merId: string) =>
    `pharmacies/${odsCode}/mer/statement-file/${merId}`,

  // Price files
  RequestUploadPriceFile: (clientId: string) =>
    `companies/${clientId}/prices/files/request-upload`,
  GetAllUploadedPriceFiles: (clientId: string) =>
    `companies/${clientId}/prices/files`,
  ProcessPriceFile: (clientId: string, uploadId: string) =>
    `companies/${clientId}/prices/files/${uploadId}/process`,
  GetUploadedPriceFile: (clientId: string, uploadId: string) =>
    `companies/${clientId}/prices/files/${uploadId}`,
  DeleteUploadedPriceFile: (clientId: string, uploadId: string) =>
    `companies/${clientId}/prices/files/${uploadId}`,
  UpdateProductMatch: (clientId: string) =>
    `companies/${clientId}/prices/update-product`,
  GetSuppliersPricesWithSourceDetails: (
    clientId: string,
    uploadId: string,
    pageIndex: number,
    pageSize: number
  ) =>
    `companies/${clientId}/prices/${uploadId}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
}
