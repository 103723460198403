export interface ClientPermission {
  parentId?: string
  clientId: string
  clientType: string
  name: string
  permissions: string[]
  setFeatureFlags?: string[]
  internalSupplierIds?: { [key: string]: string }
  pharmacyGroupMembers?: string[]
}

export interface ClientSelection extends ClientPermission {}

export const ClientTypes = {
  Company: 'COMPANY',
  PharmacyGroup: 'PHARMACY_GROUP',
  Pharmacy: 'PHARMACY',
}

