import { useFormik } from 'formik'
import { FC, useContext, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as yup from 'yup'
import { locale } from '../../../locales'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { selectedClientState } from '../../../state/SelectedPharmacyState'
import theme from '../../../styles/theme'
import { Product } from '../entities/Product'
import { ProductStockUpdateRequest } from '../entities/ProductStockUpdateMessage'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

interface InputStockModalProps {
  product: Product
  onSubmitCallback: () => void
  isReset: boolean
}

interface FormValues {
  currentStockPacks: number | undefined
  currentStockUnits: number | undefined
}
const translation =
  locale.translation.StockTrackingPage.TabLiveStock.InputStockForm

const InputStockForm: FC<InputStockModalProps> = ({
  product,
  onSubmitCallback,
  isReset,
}) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const [loading, setLoading] = useState(false)
  const [warning, setWarning] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleInputStockBlur = () => {
    let currentStockUnits = formik?.values?.currentStockUnits ?? 0

    //Perform Calculations
    if (currentStockUnits >= product.packSize) {
      const calculatedUnits = currentStockUnits % product.packSize
      const calculatedPacks = Math.floor(currentStockUnits / product.packSize)

      let totalPacks = formik?.values?.currentStockPacks ?? 0
      totalPacks = totalPacks + calculatedPacks

      //Update
      formik.setValues({
        ...formik.values,
        currentStockPacks: totalPacks,
        currentStockUnits: calculatedUnits,
      })

      setWarning(
        translation.Notifications.NotificationAlreadyCalculatedSplit(
          currentStockUnits,
          calculatedPacks,
          calculatedUnits
        )
      )
    }
  }

  // On submit send the request to the API to create the new product in the
  // stock tracking table, then close the modal
  // If this is a RESET, we will updated the product stock values,
  // instead of adding a new product
  const handleSubmit = async (formValues: FormValues) => {
    setLoading(true)

    const body: ProductStockUpdateRequest = {
      productId: product.productId,
      packSize: product.packSize,
      actionType: 'RESET',
      packsQuantity: formValues.currentStockPacks!,
      unitsQuantity: formValues.currentStockUnits!,
    }
    const response = await platformHttpService.postAsync(
      PlatformApiPaths.ResetProductStock(selectedClient?.clientId!),
      body,
      'StockBaseUrl'
    )
    if (!response.hasErrors) {
      onSubmitCallback()
      setLoading(false)
    } else {
      setError(GetErrorMessage(response.statusCode))
      setLoading(false)
    }
  }

  // Set up formik
  const {
    setFieldValue: setFormikFieldValue,
    validateField: validateFormikField,
    isValid: formikIsValid,
    ...formik
  } = useFormik({
    initialValues: {
      currentStockPacks: 0,
      currentStockUnits: 0,
    },
    validationSchema: yup.object({
      currentStockPacks: yup
        .number()
        .integer(
          translation.Validation.CurrentStockPacksOrUnitsValidationInteger
        )
        .required(translation.Validation.CurrentStockPacksValidationRequired)
        .min(0, translation.Validation.CurrentStockPacksValidationMin),

      currentStockUnits: yup
        .number()
        .integer(
          translation.Validation.CurrentStockPacksOrUnitsValidationInteger
        )
        .required(translation.Validation.CurrentStockUnitsValidationRequired)
        .min(0, translation.Validation.CurrentStockUnitsValidationMin),
    }),
    onSubmit: (values) => handleSubmit(values),
  })

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(2),
        '&:focus-visible': { outline: 'none' },
        boxShadow: 'none',
      }}
      component={'form'}
      onSubmit={formik.handleSubmit}
    >
      {!isReset && product && (
        <Typography
          variant="h6"
          sx={{ fontSize: '1.2em', marginBottom: theme.spacing(1) }}
          align="center"
        >
          {translation.ProductTitle(
            product.productName,
            product.packSize!,
            product.unitOfMeasure,
            product.subPackDescription
          )}
        </Typography>
      )}
      <TextField
        id="currentStockPacks"
        name="currentStockPacks"
        label={translation.Fields.StockPacks.Label}
        type="number"
        data-testid="current-stock-packs-input-field"
        value={formik.values.currentStockPacks}
        error={Boolean(formik.errors.currentStockPacks)}
        helperText={formik.errors.currentStockPacks}
        onChange={formik.handleChange}
        onBlur={handleInputStockBlur}
      ></TextField>
      <TextField
        id="currentStockUnits"
        name="currentStockUnits"
        label={translation.Fields.StockUnits.Label}
        type="number"
        data-testid="current-stock-units-input-field"
        value={formik.values.currentStockUnits}
        error={Boolean(formik.errors.currentStockUnits)}
        helperText={formik.errors.currentStockUnits}
        onChange={formik.handleChange}
        onBlur={handleInputStockBlur}
      ></TextField>

      <LoadingButton
        data-testid="complete-adding-stock-button"
        type="submit"
        disableElevation
        variant="contained"
        color="primary"
        loading={loading}
        disabled={!formikIsValid}
        sx={{
          minWidth: '160px',
        }}
      >
        {translation.Buttons.Submit(
          formik.values.currentStockPacks,
          formik.values.currentStockUnits,
          isReset
        )}
      </LoadingButton>
      {warning && (
        <Alert variant="filled" severity="warning">
          {warning}
        </Alert>
      )}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </Box>
  )
}

export default InputStockForm
