const sentenceToWordsArray = (sentence: string): string[] => {
  if (!sentence) {
    return []
  }
  return sentence
    .toLowerCase()
    .replace(/\s\s+/g, ' ')
    .split(' ')
    .filter((w) => !!w)
}

export const textFiltering = (filterText: string, fullText: string) => {
  const filterTextWords = sentenceToWordsArray(filterText)
  const productNameWords = sentenceToWordsArray(fullText)

  let included: boolean[] = []
  // For every word in the filter
  for (const filterWord of filterTextWords) {
    // Check if it matches at least one word in the product name
    let matches = false
    for (const productWord of productNameWords) {
      matches = productWord.indexOf(filterWord) >= 0
      if (matches) {
        break // matched, so no need to continue
      }
    }
    included.push(matches)
  }

  // if every word has a match, we're a go
  return included.every((i) => i)
}

export const arrayTextFiltering = (filterText: string, fullTexts: string[]) => {
  for (let fullText of fullTexts) {
    if (textFiltering(filterText, fullText)) {
      return true
    }
  }
  return false
}
