import { context, trace, Span, SpanStatusCode } from '@opentelemetry/api'
export const executeInOtlpSpan = async (
  name: string,
  action: (
    span: Span,
    traceId: string,
    spadId: string,
    setStatus: (isError: boolean, message?: string) => void
  ) => Promise<unknown>,
  parentSpan?: Span
): Promise<unknown> => {
  const tracer = trace.getTracer('WFE-tracer')

  // Execute in root span context
  if (!parentSpan) {
    return tracer.startActiveSpan(name, async (span: Span) => {
      try {
        return await executeSpanAction(span, action)
      } finally {
        span.end()
      }
    })
  }

  // Execute in child span context
  const childSpan = getChildSpan(name, parentSpan)
  try {
    return await executeSpanAction(childSpan, action)
  } finally {
    childSpan.end()
  }
}

export const getChildSpan = (name: string, parentSpan: Span) => {
  const tracer = trace.getTracer('WFE-tracer')
  return tracer.startSpan(name, {}, trace.setSpan(context.active(), parentSpan))
}

export const setSpanStatus = (
  span: Span,
  isError: boolean,
  message?: string
) => {
  if (isError) {
    span.setStatus({ code: SpanStatusCode.ERROR, message: message })
  } else {
    span.setStatus({ code: SpanStatusCode.OK })
  }
}

export const setSpanAttributes = (
  span: Span,
  attributes: { [key: string]: any }
) => {
  Object.keys(attributes).forEach((key) => {
    span.setAttribute(key, attributes[key])
  })
}

const executeSpanAction = async (
  span: Span,
  action: (
    span: Span,
    traceId: string,
    spadId: string,
    setStatus: (isError: boolean, message?: string) => void
  ) => Promise<unknown>
) => {
  return await action(
    span,
    span.spanContext().traceId,
    span.spanContext().spanId,
    (isError: boolean, message?: string) => {
      setSpanStatus(span, isError, message)
    }
  )
}
