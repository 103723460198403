import { useEffect, useState } from 'react'

function useIntersectionObserver(
  elRef: React.RefObject<HTMLElement>,
  onAppeared?: () => void
) {
  const [hasAppeared, setHasAppeared] = useState(false)
  useEffect(() => {
    let element: HTMLElement | null
    let observer: IntersectionObserver | null
    if (hasAppeared) {
      return
    }
    observer = new IntersectionObserver((entries) => {
      if (entries.some((e) => e.isIntersecting)) {
        observer?.unobserve(entries[0].target)
        setHasAppeared(true)
        onAppeared?.()
      }
    })
    if (elRef.current) {
      element = elRef.current
      observer.observe(element)
    }
    return () => {
      if (element) {
        observer?.unobserve(element)
      }
    }
  }, [elRef, hasAppeared, onAppeared])
  return { hasAppeared }
}

export default useIntersectionObserver
