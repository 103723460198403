import { useState, useEffect } from 'react'

const getStorageValue = (key: string) => {
  const saved = localStorage.getItem(key)
  if (saved) {
    return saved
  }
  return null
}

export const useLocalStorage = (key: string, defaultValue?: any) => {
  const [value, setValue] = useState<string | null>(getStorageValue(key))

  const setLocalStorage = (value: string | null) => {
    setValue(value)
  }

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, value)
    } else {
      localStorage.removeItem(key)
    }
  }, [key, value])

  return [value ?? defaultValue, setLocalStorage] as const
}
