import { atom } from 'recoil'

export const mobileNavigationOpenState = atom<boolean>({
  key: 'mobileNavigationOpenState',
  default: false,
})

export const pageTitleState = atom<{
  pageTitleText: string
  pageSubTitleText?: string | null
}>({
  key: 'currentPage',
  default: { pageTitleText: '', pageSubTitleText: null },
})
