export interface NewMonthEndReconciliationResponse {
  monthEndReconciliationId: string | null
  status: string | null
}

export interface MonthEndReconciliationUploadResponse {
  monthEndReconciliationId: string | null
  uploadId: string | null
  preSignedUrl: string | null
  headersToUse: { [key: string]: string } | null
  methodToUse: string
}

export interface BatchedPdfs {
  key: string | null
  firstPage: number
  lastPage: number
}

export interface PdfPreprocessorResult {
  pageImages: string[]
  batchedPdfs: BatchedPdfs[]
}

export interface MonthEndReconcilliationMonthTotals {
  date: string | null
  amount: number | null
  vatAmount: number | null
  totalAmountWithVat: number | null
}

export interface SystemMonthEndReconciliationDocument {
  documentLineNumber: number | null
  documentType: string | null
  optionsIsExcluded: boolean
  optionsNote: string | null
  systemDocumentId: string | null
  systemDocumentTotalPrice: number | null
  systemDocumentDate: string | null
  systemId: string | null
}

export interface MonthEndReconciliationDocument
  extends SystemMonthEndReconciliationDocument {
  documentId: string | null
  documentPageNumber: number | null
  documentPageLine: number | null
  documentDate: string | null
  documentNetAmount: number | null
  documentVatAmount: number | null
  documentGrossAmount: number | null

  matchedDocumentFound: boolean
  matchedIsAproximateDocumentMatch: boolean
  matchedIsExactPriceMatch: boolean
}

export interface MonthEndReconciliationPage {
  pageNumber: number
  uploadId: string | null
  ocrSuccessfull: boolean
  documents: MonthEndReconciliationDocument[]
}

export interface MonthEndReconciliationStatementDate {
  day: number | null
  month: number
  year: number
}

export interface MonthEndReconciliationBase {
  creationDate: string | null
  status: string | null
  supplierId: string | null
  supplierDisplayName: string | null
  monthEndReconciliationId: string | null
  statementDate: string | null
  statementDateObj: MonthEndReconciliationStatementDate | null
  documentsStartDate: string | null
  documentsEndDate: string | null
}

export interface MonthEndReconciliation extends MonthEndReconciliationBase {
  statementTotalAmount: number | null
  monthEndReconciliationPages: MonthEndReconciliationPage[]
  systemOnlyDocuments: SystemMonthEndReconciliationDocument[]
  summary: MonthEndReconciliationSummary | null
  note: string | null
  monthTotals: MonthEndReconcilliationMonthTotals[] | null
  uploadId: string | null
}

export interface MonthEndReconciliationSummary
  extends MonthEndReconciliationBase {
  odsCode: string | null
  statementEffectiveTotalAmount: number | null
  calculatedSystemInvoicesCount: number | null
  calculatedSystemInvoicesValue: number | null
  calculatedSystemCreditsCount: number | null
  calculatedSystemCreditsValue: number | null
  calculatedSystemTotalValue: number | null

  calculatedStatementInvoicesCount: number | null
  calculatedStatementInvoicesValue: number | null
  calculatedStatementCreditsCount: number | null
  calculatedStatementCreditValue: number | null
  calculatedStatementTotalValue: number | null

  calculatedStatementInvoicesCountWithExcluded: number | null
  calculatedStatementInvoicesValueWithExcluded: number | null
  calculatedStatementCreditsCountWithExcluded: number | null
  calculatedStatementCreditValueWithExcluded: number | null
  calculatedStatementTotalValueWithExcluded: number | null

  calculatedMatchedInvoicesCount: number | null
  calculatedMatchedInvoicesValue: number | null
  calculatedUnmatchedInvoicesCount: number | null
  calculatedUnmatchedInvoicesValue: number | null
  calculatedMismatchedInvoicesCount: number | null
  calculatedMismatchedInvoicesValueDifference: number | null
  calculatedMatchedCreditsCount: number | null
  calculatedMatchedCreditsValue: number | null
  calculatedUnmatchedCreditsCount: number | null
  calculatedUnmatchedCreditsValue: number | null
  totalDiscrepancy: number | null
}

export interface MonthEndReconciliationFlattened
  extends MonthEndReconciliationBase {
  monthEndReconciliationDocuments: MonthEndReconciliationDocument[]
  summary: MonthEndReconciliationSummary | null
  systemOnlyDocuments: SystemMonthEndReconciliationDocument[]
  monthTotals: MonthEndReconcilliationMonthTotals[] | null
  uploadId: string | null
}

export const ToMonthEndReconciliationFlattened = (
  monthEndReconciliation: MonthEndReconciliation | null
): MonthEndReconciliationFlattened | null => {
  if (!monthEndReconciliation) {
    return null
  }
  const monthEndReconciliationDocuments: MonthEndReconciliationDocument[] = []
  monthEndReconciliation.monthEndReconciliationPages.forEach(
    (monthEndReconciliationPage) => {
      monthEndReconciliationPage.documents.forEach(
        (monthEndReconciliationDocument) => {
          monthEndReconciliationDocuments.push(monthEndReconciliationDocument)
        }
      )
    }
  )
  return {
    ...monthEndReconciliation,
    monthEndReconciliationDocuments,
  }
}
