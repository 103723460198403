import Box from '@mui/material/Box'
import React, {
  FC,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
} from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import theme from '../../styles/theme'
import Checkbox from '@mui/material/Checkbox'
import ButtonBase from '@mui/material/ButtonBase'
import { Virtuoso } from 'react-virtuoso'

interface SelectableListItemData<T> {
  item: T
  selected: boolean
  checked: boolean
}

interface SelectableListItemProps<T> {
  item: SelectableListItemData<T>
  onCheckedChanged: (item: T, checked: boolean) => void
  onClicked: (item: T) => void
  buttonContent: (item: T) => React.ReactNode
  showCheckBox?: boolean
  disableSelectionHighlight?: boolean
  disabled?: boolean
  buttonSx?: SxProps<Theme>
  rootSx?: SxProps<Theme>
}

const rootStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
}

const mainContentStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'flex-start',
  padding: theme.spacing(2),
}

const ListItem: FC<SelectableListItemProps<object>> = ({
  item,
  onCheckedChanged,
  onClicked,
  buttonContent,
  showCheckBox,
  disableSelectionHighlight,
  disabled,
  buttonSx,
  rootSx,
}) => {
  return (
    <Box
      sx={{
        ...rootStyle,
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: item.selected
          ? Boolean(disableSelectionHighlight)
            ? 'transparent'
            : theme.palette.grey[100]
          : 'transparent',
        ...rootSx,
      }}
    >
      <Box sx={{ alignSelf: 'center' }}>
        {showCheckBox && (
          <Checkbox
            checked={item.checked}
            onChange={(e) => onCheckedChanged(item.item, e.target.checked)}
          />
        )}
      </Box>
      <ButtonBase
        sx={{ ...mainContentStyle, ...buttonSx }}
        onClick={() => onClicked(item.item)}
        disabled={Boolean(disabled)}
      >
        {buttonContent(item.item)}
      </ButtonBase>
    </Box>
  )
}

interface VirtualizedSelectableListProps<T> {
  rowHeight: number
  rowCount: number
  height: string | number
  width: string | number
  items: T[]
  buttonContent: (item: T) => React.ReactNode
  onSelectedItemIndexChanged?: (index: number) => void
  onCheckedIndicesChanged?: (indices: number[]) => void
  onClickedItem?: (item: T) => void
  selectedIndex?: number
  showCheckBox?: boolean
  disableSelectionHighlight?: boolean
  disabled?: boolean
  itemRootSx?: SxProps<Theme>
  itemButtonSx?: SxProps<Theme>
}

export interface VirtualizedSelectableListHandle {
  clearSelection: () => void
  selectIndex: (index: number) => void
}

interface VirtualizedSelectableListState {
  listItems: SelectableListItemData<object>[]
  selectedItemIndex: number
  checkedIndices: number[]
}

interface SetSelectedItemAction {
  type: 'setSelectedItemAction'
  payload: object | null
}

interface SetSelectedIndexAction {
  type: 'setSelectedIndexAction'
  payload: number
}

interface SetCheckedItemAction {
  type: 'setCheckedItemAction'
  payload: { item: object; checked: boolean }
}

interface SetListAction {
  type: 'setListAction'
  payload: object[]
}

const reducer = (
  state: VirtualizedSelectableListState,
  action:
    | SetSelectedItemAction
    | SetSelectedIndexAction
    | SetCheckedItemAction
    | SetListAction
): VirtualizedSelectableListState => {
  const listCopy = [...state.listItems]
  const checkedIndicesCopy = [...state.checkedIndices]
  switch (action.type) {
    case 'setSelectedIndexAction':
    case 'setSelectedItemAction':
      const indexOfNewSelectedItem =
        action.type === 'setSelectedItemAction'
          ? action.payload != null
            ? state.listItems.findIndex((o) => o.item === action.payload)
            : -1
          : action.payload

      // Clear previous selection
      if (state.selectedItemIndex >= 0) {
        const previouslySelectedItem = listCopy[state.selectedItemIndex]
        if (previouslySelectedItem) {
          listCopy.splice(state.selectedItemIndex, 1, {
            item: previouslySelectedItem.item,
            selected: false,
            checked: previouslySelectedItem.checked,
          })
        }
      }

      let newSelectedItem: SelectableListItemData<object> | null = null
      if (indexOfNewSelectedItem >= 0) {
        newSelectedItem = listCopy[indexOfNewSelectedItem]
        if (newSelectedItem) {
          listCopy.splice(indexOfNewSelectedItem, 1, {
            item: newSelectedItem.item,
            selected: true,
            checked: newSelectedItem.checked,
          })
        }
      }

      return {
        ...state,
        listItems: listCopy,
        selectedItemIndex: indexOfNewSelectedItem,
      }
    case 'setCheckedItemAction':
      const indexOfItem = state.listItems.findIndex(
        (o) => o.item === action.payload.item
      )
      if (indexOfItem >= 0) {
        const item = listCopy[indexOfItem]
        if (item) {
          listCopy.splice(indexOfItem, 1, {
            checked: action.payload.checked,
            item: item.item,
            selected: item.selected,
          })
        }

        if (action.payload.checked) {
          checkedIndicesCopy.push(indexOfItem)
        } else {
          const indexOfIndex = checkedIndicesCopy.findIndex(
            (i) => i === indexOfItem
          )
          if (indexOfIndex >= 0) {
            checkedIndicesCopy.splice(indexOfIndex, 1)
          }
        }
      }
      return {
        ...state,
        listItems: listCopy,
        checkedIndices: checkedIndicesCopy,
      }
    case 'setListAction':
      const newList = action.payload.map((item, index) => {
        return {
          item: item,
          selected: state.selectedItemIndex === index,
          checked: state.checkedIndices.includes(index),
        }
      })
      return { ...state, listItems: newList }
    default:
      return state
  }
}

const VirtualizedSelectableList: ForwardRefRenderFunction<
  VirtualizedSelectableListHandle,
  VirtualizedSelectableListProps<object>
> = (
  { onSelectedItemIndexChanged, onCheckedIndicesChanged, ...props },
  forwardedRef
) => {
  const [state, dispatch] = useReducer(reducer, {
    selectedItemIndex: -1,
    listItems: [],
    checkedIndices: [],
  })

  const onSelectedItemIndexChangedCallback = useCallback(
    (index: number) => onSelectedItemIndexChanged?.(index),
    [onSelectedItemIndexChanged]
  )

  const onCheckedIndicesChangedCallback = useCallback(
    (indices: number[]) => onCheckedIndicesChanged?.(indices),
    [onCheckedIndicesChanged]
  )

  const handleItemClicked = async (item: object) => {
    props.onClickedItem?.(item)
    if (!Boolean(props.disableSelectionHighlight)) {
      dispatch({
        type: 'setSelectedItemAction',
        payload: item,
      })
    }
  }

  const handleItemChecked = (item: object, checked: boolean) => {
    dispatch({ type: 'setCheckedItemAction', payload: { item, checked } })
  }

  useImperativeHandle(forwardedRef, () => ({
    clearSelection() {
      dispatch({ type: 'setSelectedItemAction', payload: null })
    },
    selectIndex(index: number) {
      dispatch({ type: 'setSelectedIndexAction', payload: index })
    },
  }))

  useEffect(() => {
    onSelectedItemIndexChangedCallback(state.selectedItemIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedItemIndex])

  useEffect(() => {
    onCheckedIndicesChangedCallback(state.checkedIndices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkedIndices])

  useEffect(() => {
    dispatch({ type: 'setListAction', payload: props.items })
  }, [props.items])

  useEffect(() => {
    dispatch({
      type: 'setSelectedIndexAction',
      payload: props.selectedIndex ?? -1,
    })
  }, [props.selectedIndex])

  const renderRow = (index: number, item: any) => {
    return (
      <Box key={item.key} style={item.style}>
        <ListItem
          item={state.listItems![index]}
          onCheckedChanged={handleItemChecked}
          onClicked={handleItemClicked}
          buttonContent={props.buttonContent}
          showCheckBox={props.showCheckBox}
          disableSelectionHighlight={props.disableSelectionHighlight}
          disabled={props.disabled}
          buttonSx={props.itemButtonSx}
          rootSx={props.itemRootSx}
        />
      </Box>
    )
  }

  return (
    <Virtuoso
      data={state.listItems}
      itemContent={renderRow}
      style={{ width: props.width || '400px' }}
    />
  )
}

export default React.forwardRef(VirtualizedSelectableList)
