import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { pageTitleState } from '../state/UIStates'

export const usePageTitle = (title: string, subTitleText?: string) => {
  const setCurrentPageState = useSetRecoilState(pageTitleState)
  const { pageTitleText, pageSubTitleText } = useRecoilValue(pageTitleState)
  useEffect(() => {
    if (title) {
      setCurrentPageState({
        pageTitleText: title ?? '',
        pageSubTitleText: subTitleText,
      })
    }
  }, [title, setCurrentPageState, subTitleText])
  return {
    pageTitleText,
    pageSubTitleText,
  }
}
