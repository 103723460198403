import { FC, useEffect, useReducer } from 'react'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import useDebounce from '../../hooks/useDebounce'

type UpdateTextAction = {
  type: 'setSearchFieldText'
  payload: string
}

interface State {
  invoiceNumber: string
  onTextUpdated?: (text: string) => void
}

const reducer = (state: State, action: UpdateTextAction): State => {
  switch (action.type) {
    case 'setSearchFieldText':
      return { ...state, invoiceNumber: action.payload }
    default:
      return state
  }
}

const SearchBox: FC<{
  searchText: string
  placeholder: string
  onTextUpdated: (text: string) => void
  testId?: string
}> = ({ searchText, placeholder, onTextUpdated, testId }) => {
  const [state, dispatch] = useReducer(reducer, {
    invoiceNumber: searchText,
    onTextUpdated: onTextUpdated,
  })
  const hasText = state.invoiceNumber.length > 0
  const debouncedInvoiceNumber = useDebounce(state.invoiceNumber ?? '', 500)

  useEffect(() => {
    if (debouncedInvoiceNumber !== searchText) {
      state.onTextUpdated?.(debouncedInvoiceNumber)
    }
  }, [debouncedInvoiceNumber, searchText, state])

  return (
    <TextField
      data-testid={testId}
      sx={{ flexGrow: 1 }}
      value={state.invoiceNumber}
      onChange={(e) =>
        dispatch({ type: 'setSearchFieldText', payload: e.target.value })
      }
      placeholder={placeholder}
      InputProps={{
        endAdornment: hasText && (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>
                dispatch({ type: 'setSearchFieldText', payload: '' })
              }
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></TextField>
  )
}

export default SearchBox
