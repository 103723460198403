import { createBrowserRouter } from 'react-router-dom'
import { NavigationPaths } from '../constants'
import LoginPage from '../pages/Auth/LoginPage'
import HomePage from '../pages/Home'
import NotFoundPage from '../pages/NotFoundPage'
import LoggedInRoute from './LoggedInRoute'
import LoggedOutRoute from './LoggedOutRoute'
import RootRoute from './RootRoute'
import SettingsPage from '../pages/Settings/SettingsPage'
import GoodsInPage from '../pages/GoodsIn/GoodsInPage'
import AnalyticsPage from '../pages/Analytics/AnalyticsPage'
import StockTrackingPage from '../pages/StockTracking/StockTrackingPage'
import MonthEndReconciliationPage from '../pages/Mer/MonthEndReconciliationPage'
import PriceFilesPage from '../pages/PriceFiles/PriceFilesPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootRoute />,
    errorElement: <NotFoundPage />,
    children: [
      {
        element: <LoggedInRoute />,
        children: [
          {
            path: '/',
            element: <HomePage />,
          },
          {
            path: NavigationPaths.Analytics,
            element: <AnalyticsPage />,
          },
          {
            path: NavigationPaths.PharmacySettings,
            element: <SettingsPage />,
          },
          {
            path: NavigationPaths.GoodsIn,
            element: <GoodsInPage />,
          },
          {
            path: NavigationPaths.StockTracking,
            element: <StockTrackingPage />,
          },
          {
            path: NavigationPaths.Mer,
            element: <MonthEndReconciliationPage />,
          },
          {
            path: NavigationPaths.PriceFiles,
            element: <PriceFilesPage />,
          },
        ],
      },
      {
        element: <LoggedOutRoute />,
        children: [
          {
            path: NavigationPaths.Login,
            element: <LoginPage />,
          },
        ],
      },
    ],
  },
])

export default router
