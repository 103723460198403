import { FC, PropsWithChildren, useContext, useEffect, useRef } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useSetRecoilState } from 'recoil'
import { configurationSettings } from '../../configuration/configurationSettings'
import {
  webSocketConnectionIdState,
  webSocketLastMessageState,
} from '../../state/WebSocketState'
import { ServiceContext } from '../../providers/ServicesProvider'

const connectionStatus = {
  [ReadyState.CONNECTING]: 'Connecting',
  [ReadyState.OPEN]: 'Open',
  [ReadyState.CLOSING]: 'Closing',
  [ReadyState.CLOSED]: 'Closed',
  [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
}

export interface WebSocketMessage {
  action: string
}

export interface DispensesUploadCompletedWebSocketMessage
  extends WebSocketMessage {
  odsCode: string
  uploadId: string
}

const WebSocketConnector: FC<PropsWithChildren> = ({ children }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const setConnectionId = useSetRecoilState<string | null>(
    webSocketConnectionIdState
  )
  const interval = useRef<any>(null)

  const setLastDataMessage = useSetRecoilState(webSocketLastMessageState)

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    configurationSettings.WebSocket.Url,
    {
      shouldReconnect: (closeEvent) => true,
    }
  )

  useEffect(() => {
    console.info(
      `Websocket connection status is ${connectionStatus[readyState]}`
    )
    const handlePingInterval = () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      interval.current = setInterval(() => {
        if (readyState === ReadyState.OPEN) {
          sendJsonMessage({ action: 'ping' })
        }
      }, 60000)
    }
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({ action: 'getConnectionId' })
      handlePingInterval()
    }
  }, [readyState, sendJsonMessage])

  useEffect(() => {
    if (lastMessage?.data) {
      try {
        const data = JSON.parse(lastMessage.data)
        if (data.connectionId) {
          platformHttpService.setWebSocketConnectionId(data.connectionId)
          setConnectionId(data.connectionId)
        } else {
          setLastDataMessage(data)
        }
      } catch (error: any) {
        console.error('Websocket error', error)
      }
    }
  }, [lastMessage, setConnectionId, setLastDataMessage, platformHttpService])

  return <>{children}</>
}

export default WebSocketConnector
