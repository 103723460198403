import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { FC } from 'react'
import { InvoiceType } from '../entities/InvoiceType'
import theme from '../../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'
import OpenInNew from '@mui/icons-material/OpenInNew'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import QuestionMark from '@mui/icons-material/QuestionMark'

const baseStyle: SxProps<Theme> = {
  width: '80px',
  height: '120px',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '3px',
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const InvoiceImageButton: FC<{
  invoiceType: InvoiceType | null
  fileName: string | null
  onClickedDownload: (fileName: string) => void
}> = ({
  invoiceType,
  fileName,
  onClickedDownload,
}) => {
  return (
    <ButtonBase
      onClick={() => {
        if (fileName) {
          onClickedDownload(fileName)
        }
      }}
      sx={{
        '& .download-button': { display: 'none' },
        '&:hover': {
          '& .download-button': { display: 'block' },
        },
      }}
    >
      <>
        <Box
          sx={{
            position: 'absolute',
            right: '4px',
            bottom: '4px',
            color: theme.palette.success.main,
            backgroundColor: 'transparent',
          }}
        >
          <OpenInNew className="download-button" />
        </Box>
        {invoiceType === InvoiceType.JPG && (
          <Box
            sx={{
              ...baseStyle,
              objectFit: 'contain',
            }}
            component="img"
            // src={invoiceUrl}
            alt="invoice"
          />
        )}
        {invoiceType === InvoiceType.PDF && (
          <Box
            sx={{
              ...baseStyle,
            }}
          >
            <PictureAsPdf fontSize="large" />
          </Box>
        )}
        {!invoiceType && (
          <Box
            sx={{
              ...baseStyle,
            }}
          >
            <QuestionMark fontSize="large" />
          </Box>
        )}
      </>
    </ButtonBase>
  )
}
export default InvoiceImageButton
